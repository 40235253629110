export const getBlockfrostBaseUrl = () => `${getApiUrl()}/api/blockfrost`;
export const getMarketPlaceContractAddress = () => process.env.REACT_APP_MARKETPLACE_SC_ADDRESS!
export const getMarketPlaceCommisionAddress = () => process.env.REACT_APP_MARKETPLACE_COMMISSION_ADDRESS!
//commission pkh testnet: e2a206cf1f33c8bb90722b6fd92f747911485954f0562faae0b16bc0 mainnet: e2a206cf1f33c8bb90722b6fd92f747911485954f0562faae0b16bc0 are same
export const getMarketPlaceContractCbor = () => process.env.REACT_APP_MARKETPLACE_CBOR!
export const getApiUrl = () => process.env.REACT_APP_API_URL!
export const getGameApiUrl = () => process.env.REACT_APP_GAME_API_URL!
export const getApplicationInsightsKey = () => process.env.REACT_APP_APPINSIGHTS_INSTRUMENTATION!
export const isBreedingEnabled = () => process.env.REACT_APP_IS_BREEDING_ENABLED != undefined && process.env.REACT_APP_IS_BREEDING_ENABLED == "true" ? true : false
export const isDenominationEnabled = () => process.env.REACT_APP_IS_DENOMINATION_ENABLED != undefined && process.env.REACT_APP_IS_DENOMINATION_ENABLED == "true" ? true : false
export const isRewardsClaimingEnabled = () => process.env.REACT_APP_IS_REWARDS_CLAIMING_ENABLED != undefined && process.env.REACT_APP_IS_REWARDS_CLAIMING_ENABLED == "true" ? true : false
export const getRewardsPaymentAddress = () => process.env.REACT_APP_REWARDS_PAYMENT_ADDRESS!
//REACT_APP_TOP_UP_ADDRESS == dinocoin-rewards-distribution-addr
export const getTopUpAddress = () => process.env.REACT_APP_TOP_UP_ADDRESS!

export const getDinoCoinName = () => "DinoCoin"
export const getDinoCoinPolicyId = () => process.env.REACT_APP_DINOCOIN_POLICYID!
export const getDinoCoinPolicyIdV2 = () => process.env.REACT_APP_DINOCOIN_POLICYIDV2!
export const getDinoCoinPolicyIdUseV2 = () => process.env.REACT_APP_DINOCOIN_POLICYIDUSEV2 != undefined && process.env.REACT_APP_DINOCOIN_POLICYIDUSEV2 == "true" ? true : false
export const getDinoCoinClaimingAddress = () => process.env.REACT_APP_DINOCOIN_CLAIMING_ADDRESS!
export const getDinoCoinDenominationAddress = () => process.env.REACT_APP_DINOCOIN_DENOMINATION_ADDRESS!
export const getDiscordApplicationId = () => "956885016376397824"

export const getBreedingV2 = () => process.env.REACT_APP_BREEDING_V2 != undefined && process.env.REACT_APP_BREEDING_V2 == "true" ? true : false
export const getBreedingDinoCoinPolicyId = () => getBreedingV2() ? getDinoCoinPolicyIdV2() : process.env.REACT_APP_DINOCOIN_POLICYID!
export const getBreedingDinoCoinName = () => getBreedingV2() ? "DINO" : getDinoCoinName()
export const getBreedingContractCbor = () => process.env.REACT_APP_BREEDING_CBOR_V2!
export const getBreedingMintingFeeAddress = () => process.env.REACT_APP_BREEDING_MINTING_FEE_ADDRESS!
export const getBreedingServiceFeeAddress = () => process.env.REACT_APP_BREEDING_SERVICE_FEE_ADDRESS!
export const getBreedingTime = () => parseInt(process.env.REACT_APP_BREEDING_TIME!)
export const isScholarshipEnabled = () => process.env.REACT_APP_SCHOLARSHIP_ENABLED != undefined && process.env.REACT_APP_SCHOLARSHIP_ENABLED == "true" ? true : false
export const getCardanoNetworkId = () => process.env.REACT_APP_CARDANO_NETWORK_ID != undefined ? Number.parseInt(process.env.REACT_APP_CARDANO_NETWORK_ID) : 1 // if not defined use 1 = mainnet (0 = testnet)

export const isTest = () => {
    return process.env.REACT_APP_ENVIRONMENT == undefined || process.env.REACT_APP_ENVIRONMENT == "test"
}

export const getOidcConfig = () => {
    return {
        authority: process.env.REACT_APP_OIDC_AUTHORITY!,
        client_id: process.env.REACT_APP_OIDC_CLIENT_ID!,
        client_secret: process.env.REACT_APP_OIDC_CLIENT_SECRET!,
        redirect_uri: process.env.REACT_APP_OIDC_REDIRECT_URI!,
        response_type: "code",
        scope: "openid profile game api offline_access",
        post_logout_redirect_uri: process.env.REACT_APP_OIDC_POST_LOGOUT_REDIRECT_URI!,
        popup_redirect_uri: process.env.REACT_APP_OIDC_POPUP_REDIRECT_URI!,
    }
}