import React from 'react'
import { PageTemplate } from '../components/PageTemplate'

export const PrivacyPolicy = () => (
    <PageTemplate title="Privacy policy">
        <div style={{ width: 1100, textAlign: 'left', margin: '0 auto' }}>
            <p>
                At DinoLabs Sp. z o.o., we are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect the information you provide when accessing our website and playing our game. By using our services, you consent to the practices described in this Privacy Policy.
            </p>
            <p>
                Information We Collect:<br/>
                1.1. Non-Personal Information: We may automatically collect non-personal information, including but not limited to your IP address, device information, browser type, and usage statistics, to enhance our services and improve user experience.
            </p>
            <p>
                Use of Information:<br />
                2.1. Non-Personal Information: We may use non-personal information for analytical purposes, market research, and to enhance and personalize your experience on our website and within our game.
            </p>
            <p>
                Disclosure of Information:<br />
                3.1. Service Providers: We may share non-personal information with trusted third-party service providers who assist us in operating our website and delivering our services. These providers are contractually bound to protect your information and can only use it to provide the agreed-upon services.<br />
                3.2. Legal Requirements: We may disclose your information if required to do so by law, court order, or governmental authority.<br />
                3.3. Business Transfers: In the event of a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred to the acquiring entity.<br />
            </p>
            <p>
                Data Security:<br />
                We implement reasonable security measures to protect your information from unauthorized access, loss, alteration, or disclosure. However, no method of transmission or electronic storage is 100% secure. While we strive to protect your information, we cannot guarantee its absolute security.
            </p>
            <p>
                Third-Party Links and Services:<br />
                Our website and game may contain links to third-party websites or services. We are not responsible for the privacy practices or content of these third parties. We encourage you to review the privacy policies of any third-party websites or services before providing any personal information.
            </p>
            <p>
                Children's Privacy:<br />
                Our website and game are not intended for children under the age of 13. We do not knowingly collect personal information from children. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us, and we will take appropriate steps to remove the information from our systems.
            </p>
            <p>
                Changes to the Privacy Policy:<br />
                We reserve the right to update or modify this Privacy Policy at any time. Any changes will be effective upon posting the revised Privacy Policy on our website. We encourage you to periodically review this Privacy Policy for any updates.
            </p>
            <p>
                Contact Us:<br />
                If you have any questions, concerns, or requests regarding this Privacy Policy, please contact us at contact@cryptodino.io.
            </p>
            <p>
                By using CryptoDino's website and game, you acknowledge that you have read and understood this Privacy Policy and agree to the collection, use, and disclosure of your information as described herein.
            </p>
        </div>
    </PageTemplate>
)