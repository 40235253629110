import React from 'react'
import { getOffers } from './marketplaceApi'
import * as marketplace from './marketplace'
import { CancelError } from '../cardano/cardano-adapter'
import { collections } from '../collections'
import { useNotifications } from '../components/NotificationProvider'
import { appInsights } from '../AppInsights'
import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { useCardanoWalletProvider, useWalletAssets, useWalletAssetState } from '../cardano/CardanoWalletProvider'

type AssetState = 'completed' | 'pending'

type Offer = {
    policyId: string,
    number: string,
    askingPrice: string,
    walletAddress: string,
    state: AssetState,
    awaitingTxHash?: string,
}

type OfferDictionary = {
    [offerId: string]: Offer
}

type OfferLookup = {
    [policyId: string]: string[]
}

export type WalletAsset = {
    policyId: string,
    name: string,
    state: AssetState,
    awaitingTxHash?: string,
}

type SaleTokenContextProps = {
    offers: OfferDictionary,
    offersByPolicyId: OfferLookup,
    offer: (policyId: string, assetName: string, askingPrice: string) => Promise<void>,
    buy: (policyId: string, assetName: string) => Promise<void>,
    cancel: (policyId: string, assetName: string) => Promise<void>,
    update: (policyId: string, assetName: string, askingPrice: string) => Promise<void>,
}

const SaleTokenContext = React.createContext<SaleTokenContextProps | undefined>(undefined)

export const assetNumber = (assetName: string) => /\d+/.exec(assetName)?.[0] || ''

const assetId = ({ policyId, assetName }: { policyId: string, assetName: string }) => `${policyId}_${assetNumber(assetName).padStart(5,'0')}`

export const SaleTokenProvider: React.FC = ({ children }) => {

    const { addWarn } = useNotifications()
    const { walletAssets, walletAddress, connected, getCardano, addAsset, removeAsset } = useCardanoWalletProvider()
    const [offers, setOffers] = React.useState<OfferDictionary>({})
    const [offersByPolicyId, setOffersByPolicyId] = React.useState<OfferLookup>({})

    const handleError = React.useCallback((err: any, policyId: string, assetName: string) => {
        if (err instanceof CancelError) {
            return
        } else if (typeof err === 'string') {
            addWarn(err)
            appInsights.trackTrace({ message: err }, { policyId, assetName })
        } else {
            addWarn(err.info || err.message)
            appInsights.trackException({ exception: err }, { policyId, assetName })
        }
    }, [addWarn])

    const loadAllOffers = React.useCallback(async () => {
        const policyIds = Object.entries(collections).map(([, collection]) => collection.policyIds).flat()
        const offers = (await Promise.all(policyIds.map(getOffers))).flat()
        setOffers(Object.fromEntries(offers.map((offer) => [assetId(offer), {
            policyId: offer.policyId,
            number: assetNumber(offer.assetName),
            askingPrice: offer.price,
            walletAddress: offer.sellerAddress,
            state: 'completed'
        }])))
        setOffersByPolicyId(Object.fromEntries(policyIds.map(policyId => [policyId, offers.filter(offer => offer.policyId === policyId).map(offer => assetId(offer))])))
    }, [])

    const offer = React.useCallback(async (policyId: string, assetName: string, askingPrice: string) => {
        appInsights.trackTrace({ message: 'Offer token', severityLevel: SeverityLevel.Information }, { policyId, assetName })
        try {
            const txHash = await marketplace.offer(getCardano(), policyId, assetName, askingPrice)
            const number = assetNumber(assetName)
            const id = assetId({ policyId, assetName })
            removeAsset({ policyId, name: assetName }, txHash).then(() => {
                setOffers({
                    ...offers,
                    [id]: { policyId, number, askingPrice, walletAddress: walletAddress!, state: 'completed' }
                })
            })
            setOffers({
                ...offers,
                [id]: { policyId, number, askingPrice, walletAddress: walletAddress!, state: 'pending', awaitingTxHash: txHash },
            })
            const { [policyId]: policyIdOffers, ...otherOffersKeys } = offersByPolicyId
            setOffersByPolicyId({
                ...otherOffersKeys,
                [policyId]: [...policyIdOffers, id]
            })
        }
        catch (err: any) {
            handleError(err, policyId, assetName)
        }
    }, [offers, offersByPolicyId, walletAddress, handleError, getCardano, removeAsset])

    const buy = React.useCallback(async (policyId: string, assetName: string) => {
        appInsights.trackTrace({ message: 'Buy token', severityLevel: SeverityLevel.Information }, { policyId, assetName })
        try {
            const id = assetId({ policyId, assetName })
            const { [policyId]: policyIdOffers, ...otherOffersKeys } = offersByPolicyId
            const { [id]: offer, ...otherOffers } = offers

            const txHash = await marketplace.buy(getCardano(), policyId, assetName)

            setOffers(otherOffers)
            setOffersByPolicyId({
                ...otherOffersKeys,
                [policyId]: policyIdOffers.filter(key => key !== id)
            })
            addAsset({ policyId, name: assetName }, txHash)
        }
        catch (err: any) {
            handleError(err, policyId, assetName)
        }
    }, [offers, offersByPolicyId, setOffersByPolicyId, handleError, getCardano, addAsset])

    const cancel = React.useCallback(async (policyId: string, assetName: string) => {
        appInsights.trackTrace({ message: 'Cancel offer', severityLevel: SeverityLevel.Information }, { policyId, assetName })
        try {
            const id = assetId({ policyId, assetName })
            const { [policyId]: policyIdOffers, ...otherOffersKeys } = offersByPolicyId
            const { [id]: offer, ...otherOffers } = offers

            const txHash = await marketplace.cancel(getCardano(), policyId, assetName)

            setOffers(otherOffers)
            setOffersByPolicyId({
                ...otherOffersKeys,
                [policyId]: policyIdOffers.filter(key => key !== id)
            })
            addAsset({ policyId, name: assetName }, txHash)
        }
        catch (err: any) {
            handleError(err, policyId, assetName)
        }
    }, [offers, offersByPolicyId, handleError, getCardano, addAsset])

    const update = React.useCallback(async (policyId: string, assetName: string, askingPrice: string) => {
        appInsights.trackTrace({ message: 'Update offer', severityLevel: SeverityLevel.Information }, { policyId, assetName })
        try {
            const id = assetId({ policyId, assetName })
            const { [id]: offer, ...otherOffers } = offers

            await marketplace.update(getCardano(), policyId, assetName, askingPrice)

            setOffers({
                ...otherOffers,
                [id]: { ...offer, askingPrice }
            })
        }
        catch (err: any) {
            handleError(err, policyId, assetName)
        }
    }, [offers, handleError, getCardano])

    React.useEffect(() => {
        loadAllOffers()
    }, [loadAllOffers])

    const context = {
        offers,
        offersByPolicyId,
        offer,
        buy,
        cancel,
        update,
        connected,
        walletAssets,
        walletAddress,
        getCardano,
    }

    return <SaleTokenContext.Provider value={context}>{children}</SaleTokenContext.Provider>
}

export const useSaleTokenContext = (): SaleTokenContextProps => {
    const context = React.useContext<SaleTokenContextProps | undefined>(SaleTokenContext)
    if (!context) {
        throw Error('Cannot access SaleTokenContext out of scope')
    }

    return context
}

export const useOffer = (policyId: string, name: string): Offer | undefined => {
    const { offers } = useSaleTokenContext()
    return React.useMemo(() => {
        return offers[assetId({ policyId, assetName: name })]

    }, [offers, name, policyId])
}

export type OfferItem = {
    number: string,
    price: string,
}

export const useOffers = (policyIds: string[]): OfferItem[] | undefined => {
    const { offers, offersByPolicyId } = useSaleTokenContext()
    return React.useMemo(() => {
        return policyIds.map(policyId => offersByPolicyId[policyId]?.map(key => ({ number: offers[key].number, price: offers[key].askingPrice }))).flat()
    }, [offers, offersByPolicyId, policyIds])
}

type OfferOperations = {
    canOffer: boolean,
    canBuy: boolean,
    canUpdateOrCancel: boolean,
    state?: AssetState,
    isMine: boolean,
}

export const useOfferOperations = (policyId: string, assetName: string): OfferOperations => {
    const offer = useOffer(policyId, assetName)
    const walletAssets = useWalletAssets([policyId])
    const { walletAddress } = useCardanoWalletProvider()
    const walletAssetState = useWalletAssetState(policyId, assetName)
    const walletAsset = React.useMemo(() => walletAssets.find(asset => asset.name === assetName), [walletAssets, assetName])
    return React.useMemo(() => ({
        canOffer: Boolean(walletAsset && walletAssetState === 'completed'),
        canBuy: Boolean(offer && offer.state === 'completed' && walletAddress && offer.walletAddress !== walletAddress),
        canUpdateOrCancel: Boolean(offer && offer.state === 'completed' && offer.walletAddress === walletAddress),
        state: (offer && offer.state) || (walletAssetState),
        isMine: Boolean((offer && offer.walletAddress === walletAddress) || walletAsset),
    }), [offer, walletAsset, walletAddress, walletAssetState])
}