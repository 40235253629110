import React from 'react'
import { ReactComponent as TuneIcon } from './Tune.svg'
import { ModalDialog } from '../components/ModalDialog'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { OverlayContainer } from '@react-aria/overlays'
import { useButton } from '@react-aria/button'
import { CollectionFeatures, getFeatures } from '../Api'
import './GalleryFilter.scss'
import '../components/Button.scss'
import BuyingSavior from './BuyingSavior.png';
import BuyingDino from './BuyingDino.png';
import { CollectionKeys } from '../collections'
import { format } from '../components/camelCase'
import { collections } from '../collections'

export const GalleryFilter = ({ collection, collectionId, applyFilters }: { collection: CollectionKeys, collectionId: string, applyFilters: (filters: any) => void }) => {

    const state = useOverlayTriggerState({})
    const openButtonRef = React.useRef(null)
    const closeButtonRef = React.useRef(null)
    const featureOrder = React.useCallback(([a], [b]) => {
        const { features } = collections[collection]
        return features.indexOf(a) - features.indexOf(b)
    }, [collection])

    const { buttonProps: openButotnProps } = useButton({
        onPress: () => state.open()
    }, openButtonRef)
    const { buttonProps: closeButtonProps } = useButton({
        onPress: () => state.close()
    }, closeButtonRef)

    const [features, setFeatures] = React.useState<CollectionFeatures>()
    const [filters, setFilters] = React.useState<any>({})

    React.useEffect(() => {
        getFeatures(collectionId).then(setFeatures)
    }, [collectionId, getFeatures])

    const toggleFilter = React.useCallback((feature) => (event: React.ChangeEvent<HTMLSelectElement>) => {
        const { [feature]: _, ...otherFilters } = filters
        if (event.target.value) {
            setFilters({ ...otherFilters, [feature]: event.target.value })
        } else {
            setFilters(otherFilters)
        }
    }, [filters])

    const onFilterClick = React.useCallback(() => {
        applyFilters(filters)
        state.close()
    }, [applyFilters, state])

    return (
        <>
            <button className="button" {...openButotnProps} ref={openButtonRef}>
                <TuneIcon />
                <span>Filter</span>
            </button>
            {state.isOpen && (
                <OverlayContainer>
                    <ModalDialog collection={collection} isOpen={true} onClose={state.close} isDismissable={true}>
                        <div className="filter">
                            <h1>
                                Filter
                                <img src={collection === 'cryptodinos' ? BuyingDino : BuyingSavior} alt="img" />
                            </h1>
                            <div className="fields">
                                {features && Object.entries(features).filter(([feature]) => feature !== 'Parent1' && feature !== 'Parent2').sort(featureOrder).map(([feature, values]) => (
                                    <div key={feature}>
                                        <label>{format(feature)}</label>
                                        <select value={filters[feature]} onChange={toggleFilter(feature)}>
                                            <option value="">--select--</option>
                                            {Object.entries(values).map(([name, count]) => (
                                                <option value={name}>{name} ({count})</option>
                                            ))}
                                        </select>
                                    </div>
                                ))}
                            </div>
                            <div className="actions">
                                <button onClick={onFilterClick}>Apply</button>
                            </div>
                        </div>
                    </ModalDialog>
                </OverlayContainer>
            )}
        </>
    )
}