import React from 'react'
import { PageTemplate } from '../components/PageTemplate'
import './HowToBuy.scss'

export const TermsOfService = () => (
    <PageTemplate collection="dinosaviors" title="Terms of service">
        <div className="guide" style={{ width: 1100, textAlign: 'left' }}>
            <ol>
                <li>The buyer of the DinoSavior NFT should expect that we don't know what floor price of the DinoSavior NFT collection is going to be.</li>
                <li>The information published on the Website cannot guarantee that the buyer of the DinoSavior NFT would not lose money.</li>
                <li>The buyer is solely responsible to determinate what, if any, taxes apply to their NFT purchases.</li>
                <li>The owners of, or contributors to, the Website are NOT responsible for determining the taxes that apply to NFT buying transactions.</li>
                <li>The Website is provided on an "as is" basis without any warranties of any kind regarding the Website and/or any content, data, materials and/or services provided on the Website.</li>
            </ol>
        </div>
    </PageTemplate>
)