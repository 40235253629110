import React from 'react'
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js'
import { Chart } from 'react-chartjs-2'
import { useData } from '../components/useData';
import { getGameAnalytics } from '../Api';
import { useAuth } from 'react-oidc-context';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController
);

const useAnalytics = (token: string | undefined) => {
    const callback = React.useCallback(() => token ? getGameAnalytics(token) : Promise.resolve(undefined), [token])
    return useData(callback)
}

export const ExpeditionsChart = () => {

    const { user } = useAuth()
    const { data: analytics } = useAnalytics(user?.access_token)

    const [labels, expeditions, rewards] = React.useMemo(() => [
        analytics?.map(x => x.date.substring(0, 10)) || [],
        analytics?.map(x => x.expeditionCount) || [],
        analytics?.map(x => x.expeditionRewards) || []
    ], [analytics])
    const data = {
        labels,
        datasets: [
            {
                type: 'line' as const,
                label: 'Daily rewards',
                borderColor: 'rgb(255, 99, 132)',
                borderWidth: 2,
                fill: false,
                data: rewards,
                yAxisID: 'rewards',
            },
            {
                type: 'bar' as const,
                label: 'Number of expeditions',
                backgroundColor: '#BC8EDD',
                data: expeditions,
                yAxisID: 'expeditions'
            },
        ],
    };

    const options = {
        scales: {
            rewards: {
                type: 'linear' as const,
                display: true,
                position: 'left' as const,
            },
            expeditions: {
                type: 'linear' as const,
                display: true,
                position: 'right' as const,
            },
        },
    }

    return (
        <div>
            <Chart type='bar' options={options} data={data} />
        </div>
    )
}