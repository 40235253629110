import React from 'react'
import './Explore.scss'
import { CollectionKeys, collections } from '../collections'
import { Gallery, useAssetItems } from './Gallery'
import { getMyOffers, OfferItem } from '../marketplace/marketplaceApi'
import { WalletAsset } from '../marketplace/SaleTokenProvider'
import { Link } from 'react-router-dom'
import BuyDinoOrSaviorSrc from './triceShoppingTrolley_bothPink.png'
import BuyDinoSrc from './triceShoppingTrolleyBlackBG_dino.png'
import BuySaviorSrc from './triceShoppingTrolleyBlackBG_savior.png'
import * as Api from '../Api'
import { useCardanoWalletProvider, useWalletAssets } from '../cardano/CardanoWalletProvider'
import { useData } from '../components/useData'
import EggColored001 from './eggs/EggColored_001.png'
import EggColored002 from './eggs/EggColored_002.png'
import EggColored003 from './eggs/EggColored_003.png'
import EggColored004 from './eggs/EggColored_004.png'
import EggColored005 from './eggs/EggColored_005.png'
import EggColored006 from './eggs/EggColored_006.png'
import { startTimeToRemainingDays } from '../breeding/Breeding'
import { assetLinkName } from './GalleryTile'
import { Tab, Tabs } from '../components/Tabs'
import { isBreedingEnabled } from '../configProvider'

const randomEgg = () => {
    const images = [EggColored001, EggColored002, EggColored003, EggColored004, EggColored005, EggColored006]
    return images[Math.floor(Math.random() * 100) % 6]
}

type CollectionAssets = {
    name: string,
    collection: CollectionKeys,
    collectionId: string,
    assetNames: string[],
}

const getMyOffersByPolicyId = (myOffers: OfferItem[]) => {
    return myOffers.map((offer) => [offer.policyId, offer.assetName]).reduce((acc, cur) => {
        const [policyId, name] = cur;
        acc[policyId] = [...(acc[policyId] || []), name]
        return acc
    }, {} as any)
}

const useMyAssets = () => {
    const [collectionAssets, setCollectionAssets] = React.useState<CollectionAssets[]>([])
    const { walletAddress } = useCardanoWalletProvider()
    const cryptodinoAssets = useWalletAssets(collections.cryptodinos.policyIds)
    const dinosaviorAssets = useWalletAssets(collections.dinosaviors.policyIds)
    const walletAssets = React.useMemo(() => ({
        cryptodinos: cryptodinoAssets,
        dinosaviors: dinosaviorAssets,
    }), [cryptodinoAssets, dinosaviorAssets])
    React.useEffect(() => {
        if (walletAddress) {
            const load = async () => {
                const policyIds = Object.entries(collections).map(([, collection]) => collection.policyIds[0])
                const myOfferAssets = getMyOffersByPolicyId((await Promise.all(policyIds.map(async policyId => getMyOffers(policyId, walletAddress)))).flat())
                const myOfferAssetsToUse = myOfferAssets && Object.keys(myOfferAssets).length > 0 ? myOfferAssets : []
                const breedingAssets = await Api.getBreedingAssets(walletAddress)
                const myAssets = Object.entries(collections).map(([collection, config]) => {
                    return {
                        collection: collection as CollectionKeys,
                        collectionId: config.collectionId,
                        name: config.name,
                        assetNames: [
                            ...(walletAssets[collection as CollectionKeys] as WalletAsset[]).map(x => x.name),
                            ...(myOfferAssetsToUse[config.policyIds[0]] ? myOfferAssetsToUse[config.policyIds[0]] : []),
                            ...breedingAssets.filter(x => x.startsWith(config.assetName))
                        ]
                    }
                })
                setCollectionAssets(myAssets)
            }
            load()
        }
    }, [walletAssets, walletAddress])

    return collectionAssets
}

export const NoItems = ({ collection }: { collection?: CollectionKeys }) => (
    <div className="no-items">
        <div><img src={collection === 'cryptodinos' ? BuyDinoSrc : collection === 'dinosaviors' ? BuySaviorSrc : BuyDinoOrSaviorSrc} /></div>
        <div>
            {collection && <>It looks like there is no {collections[collection].name} in your wallet.</>}
            {!collection && <>It looks like there is no CryptoDinos or DinoSaviors in your wallet.</>}
            Go <Link to={`/explore/${collection || 'cryptodinos'}?onSale=true&sort=offer.price-asc`}>buy</Link> one.
        </div>
    </div>
)

export const CollectionAssetsList = ({ assets }: { assets: CollectionAssets }) => {
    const data = useAssetItems(assets.collection, { assetNames: assets.assetNames })
    return (
        <article>
            <Gallery collection={assets.collection} data={data} noItemsComponent={<NoItems collection={assets.collection} />} />
        </article>
    )
}

export const ExploreBreedingEggs = () => {

    const { data: breedings } = useWalletBreedings()

    return (
        <article>
            <div className="asset-gallery-container">
                {breedings?.map((egg, index) => {
                    const remainingDays = startTimeToRemainingDays(egg.startTime)
                    return (
                        <div key={egg.parent1AssetName}>
                            <Link to={`/explore/cryptodinos/${assetLinkName(egg.parent1AssetName)}/breed`}>
                                <img src={randomEgg()} />
                                <div className="bottom-badge">
                                    {egg.state === 'Rejected' ? 'Rejected' : (remainingDays <= 0 ? 'Ready' : `Remaining: ${remainingDays} days`)}
                                </div>
                                <div>Baby dino</div>
                            </Link>
                        </div>
                    )
                })}
            </div>
        </article>
    )
}

export const TabTitle = ({ name, className }: any) => (
    <div className={className}>
        <span />
        <span>{name}</span>
    </div>
)

export const ExploreAccount = () => {

    const collectionAssets = useMyAssets()

    return (
        <Tabs>
            <Tab name="cryptodinos" title={<TabTitle name="CryptoDinos" className="cryptodinos" />}>
                {collectionAssets[0] && <CollectionAssetsList key={collectionAssets[0].collectionId} assets={collectionAssets[0]} />}
            </Tab>
            <Tab name="dinosaviors" title={<TabTitle name="DinoSaviors" className="dinosaviors" />}>
                {collectionAssets[1] && <CollectionAssetsList key={collectionAssets[1].collectionId} assets={collectionAssets[1]} />}
            </Tab>
            {isBreedingEnabled() && <Tab name="hatching-room" title={<TabTitle name="Hatching room" className="hatching-room" />}>
                <ExploreBreedingEggs />
            </Tab>}
        </Tabs>
    )
}

function useWalletBreedings() {
    const { walletAddress } = useCardanoWalletProvider()
    const callback = React.useCallback(() => walletAddress ? Api.getBreedingEggs(walletAddress) : Promise.resolve(undefined), [walletAddress])
    return useData(callback)
}
