import React from 'react'
import { SubscriptionFeature, getScholarshipAssets, putScholarshipAsset } from '../Api'
import { useData } from '../components/useData'
import { useAuth } from 'react-oidc-context'
import { AssetDialog, SelectedAsset } from '../explore/AssetDialog'
import { Button } from '../components/Button'
import { ModalDialog } from '../components/ModalDialog'
import { OverlayContainer } from '@react-aria/overlays'
import './Scholarship.scss'
import { IpfsImage } from '../explore/GalleryTile'
import { ReactComponent as DeleteIcon } from '../icons/Delete.svg'
import { ReactComponent as EditIcon } from '../icons/Edit.svg'
import { useAssetById } from '../explore/GalleryAsset'
import { useSubscriptionFeatures } from './Game'
import { EnableFeatureDialog } from './EnableFeatureDialog'

const useScholarshipAssets = () => {
    const { user } = useAuth()
    const getSchoolarshipAssetsCallback = React.useCallback(() => {
        if (user?.access_token) {
            return getScholarshipAssets(user?.access_token)
        } else {
            return Promise.resolve([])
        }
    }, [user])
    return useData(getSchoolarshipAssetsCallback)
}

const AddAssetToSchoolarship = ({ onComplete }: { onComplete: () => void }) => {
    const [isAssetDialogOpen, setIsAssetDialogOpen] = React.useState<boolean>(false)
    const [assetId, setAssetId] = React.useState<string>()
    const onAddAssetClick = React.useCallback(() => {
        setIsAssetDialogOpen(true)
    }, [setIsAssetDialogOpen])
    const onCloseAssetDialog = React.useCallback(() => {
        setIsAssetDialogOpen(false)
    }, [setIsAssetDialogOpen])
    const onSelectAsset = React.useCallback((selectedAsset: SelectedAsset) => {
        setIsAssetDialogOpen(false)
        setAssetId(selectedAsset.data.assetId)
    }, [setIsAssetDialogOpen, setAssetId])
    const onAddAsset = React.useCallback(() => {
        setAssetId(undefined)
        onComplete()
    }, [setAssetId, onComplete])

    return (
        <div>
            <Button onPress={onAddAssetClick}>Add asset</Button>
            {isAssetDialogOpen && <AssetDialog isOpen={isAssetDialogOpen} onSelect={onSelectAsset} onClose={onCloseAssetDialog} mineOnly={true} />}
            {assetId && <ModifyAssetScholarship assetId={assetId} onClose={() => setAssetId(undefined)} onComplete={onAddAsset} />}
        </div>
    )
}

const ModifyAssetScholarship = ({ assetId, rewardSharingRatio, onClose, onComplete }: { assetId: string, rewardSharingRatio?: number, onClose: () => void, onComplete: () => void }) => {
    const { user } = useAuth()
    const { data } = useAssetById(assetId)
    const [currentRewardSharingRatio, setCurrentRewardSharingRatio] = React.useState<number>(rewardSharingRatio || 50)
    const onRewardSharingRatioChange = React.useCallback((event: any) => {
        setCurrentRewardSharingRatio(parseInt(event.target.value))
    }, [setCurrentRewardSharingRatio])
    const saveChanges = React.useCallback(() => {
        if (user) {
            return putScholarshipAsset(assetId, currentRewardSharingRatio / 100, user.access_token).then(() => {
                onComplete()
            })
        }
    }, [user, assetId, currentRewardSharingRatio, onComplete])

    return (
        <OverlayContainer>
            <ModalDialog isOpen={true} onClose={onClose} isDismissable={true} zIndex={200}>
                <h1>Token configuration</h1>
                <div className="dialog-content scholarship-dialog">
                    <div className="form-group">
                        <div>{data && <IpfsImage hash={data.thumbprint.ipfsHash} name={data.name} />}</div>
                        <h3>{data?.name}</h3>
                    </div>
                    <div className="form-group">
                        <input className="numeric" value={currentRewardSharingRatio} onChange={onRewardSharingRatioChange} />%
                    </div>
                    <div className="form-group">
                        <Button onPress={saveChanges}>Save</Button>
                    </div>
                </div>
            </ModalDialog>
        </OverlayContainer>
    )
}

export const Scholarship = () => {
    const { user } = useAuth()
    const { data, inProgress, refresh } = useScholarshipAssets()
    const onEditClick = React.useCallback((assetId: string) => { }, [])
    const onDeleteClick = React.useCallback((assetId: string) => { }, [])
    const { data: features, refresh: refreshFeatures } = useSubscriptionFeatures(user?.access_token)
    const onFeatureEnabled = React.useCallback(async () => {
        await refresh()
        await refreshFeatures()
    }, [refresh, refreshFeatures])
    return (
        <div>
            {features?.scholarship ? (
                <>
                    <ul className="panel panel-list scholarship-entries">
                        {!inProgress && data?.length === 0 && (
                            <li className="empty">Looks like you have no assets in Scholarship.</li>
                        )}
                        {data?.map(asset => (
                            <li className="panel-section" key={asset.assetId}>
                                <div className="image"><IpfsImage hash={asset.hash} name={asset.name} /></div>
                                <div className="name">{asset.name}</div>
                                <div className="ratio">{asset.rewardSharingRatio * 100}%</div>
                                <div className="actions">
                                    <Button onPress={() => onEditClick(asset.assetId)}>
                                        <EditIcon />
                                    </Button>
                                    <Button onPress={() => onDeleteClick(asset.assetId)}>
                                        <DeleteIcon />
                                    </Button>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <AddAssetToSchoolarship onComplete={refresh} />
                </>
            ) : (
                <>
                    <p><strong>Unlock the Premium Experience:</strong> Unleash the full power of your assets! Enable this feature and boost rewards your get from all your tokens.</p>
                    <div><EnableFeatureDialog feature={SubscriptionFeature.Scholarship} onComplete={onFeatureEnabled} /></div>
                </>
            )}
        </div>
    )
}