import React from 'react'
import {
    useOverlay,
    usePreventScroll,
    useModal,
    OverlayProps,
} from '@react-aria/overlays'
import { useDialog } from '@react-aria/dialog'
import { FocusScope } from '@react-aria/focus'
import { AriaDialogProps } from "@react-types/dialog"
import './ModalDialog.scss'
import { CollectionKeys } from '../collections'
import { ReactComponent as CloseIcon } from './Close.svg'

type ModalDialogProps = {
    collection?: CollectionKeys
    zIndex?: number
} & OverlayProps & AriaDialogProps

export const ModalDialog: React.FC<ModalDialogProps> = ({ collection = 'cryptodinos', children, zIndex = 100, ...props }) => {

    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    let ref = React.useRef(null);
    let { overlayProps, underlayProps } = useOverlay(
        props,
        ref
    );

    // Prevent scrolling while the modal is open, and hide content
    // outside the modal from screen readers.
    usePreventScroll();
    let { modalProps } = useModal();

    // Get props for the dialog and its title
    let { dialogProps, titleProps } = useDialog(props, ref);

    return (
        <div
            style={{
                position: 'fixed',
                zIndex,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            {...underlayProps}>
            <FocusScope contain restoreFocus autoFocus>
                <div
                    {...overlayProps}
                    {...dialogProps}
                    {...modalProps}
                    ref={ref}
                    className={`modal-dialog ${collection}`}>
                    <button className="close-button" onClick={props.onClose}>
                        <CloseIcon />
                    </button>
                    {children}
                </div>
            </FocusScope>
        </div>
    );
}
