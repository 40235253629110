import { useEffect, useRef } from 'react'

type CancellablePromise<T> = {
    cancel: () => void,
    promise: Promise<T>
}

export function useCancellablePromise() {
    // think of useRef as member variables inside a hook
    // you cannot define promises here as an array because
    // they will get initialized at every render refresh
    const promises = useRef<CancellablePromise<any>[]>([]);
    // useEffect initializes the promises array
    // and cleans up by calling cancel on every stored
    // promise.
    // Empty array as input to useEffect ensures that the hook is
    // called once during mount and the cancel() function called
    // once during unmount
    useEffect(
        () => {
            promises.current = promises.current || [];
            return function cancel() {
                promises.current.forEach(p => p.cancel());
                promises.current = [];
            };
        }, []
    );

    // cancelablePromise remembers the promises that you
    // have called so far. It returns a wrapped cancelable
    // promise
    function cancellablePromise<T>(p: Promise<T>): Promise<T> {
        const cPromise = makeCancelable<T>(p);
        promises.current.push(cPromise);
        return cPromise.promise;
    }
    return { cancellablePromise };
}

function makeCancelable<T>(promise: Promise<T>): CancellablePromise<T> {
    let isCanceled = false;
    const wrappedPromise =
        new Promise<T>((resolve, reject) => {
            // Suppress resolution and rejection if canceled
            promise
                .then((val) => (!isCanceled && resolve(val)))
                .catch((error) => (!isCanceled && reject(error)));
        });
    return {
        promise: wrappedPromise,
        cancel() {
            isCanceled = true;
        },
    };
}