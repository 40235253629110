import React from 'react'
import { Link, Redirect, Route, Switch } from 'react-router-dom'
import { BrowserRouter } from 'react-router-dom'
import { Footer } from './Footer'
import { Header } from './Header'
import { Breeding } from '../whitepapers/Breeding'
import { Game } from '../whitepapers/Game'
import { HowToBuy } from '../sales/HowToBuy'
import { TermsOfService } from '../sales/TermsOfService'
import { Explore } from '../explore/Explore'
import { ExploreAccount } from '../explore/ExploreAccount'
import { GalleryAsset } from '../explore/GalleryAsset'
import { LinkWallet } from '../login/LinkWallet'
import { Admin } from '../breeding/Admin'
import { Rarity } from '../explore/Rarity'
import { Story1 } from '../stories/Story1'
import { Story2 } from '../stories/Story2'
import { BreedPage } from '../breeding/Breeding'
import { Home } from '../home/Home'
import { CardanoWalletProvider } from '../cardano/CardanoWalletProvider'
import { collections } from '../collections'
import { SaleTokenProvider } from '../marketplace/SaleTokenProvider'
import { FeatureProvider } from '../explore/FeatureContext'
import './Layout.scss'
import { Staking } from '../account/Staking'
import { Account } from '../account/Account'
import { AuthProvider } from 'react-oidc-context'
import { getOidcConfig } from '../configProvider'
import { PrivacyPolicy } from '../pages/PrivacyPolicy'
import { Accounting } from '../admin/Accounting'
import { Download } from '../account/Download'
import { WebStorageStateStore } from 'oidc-client-ts'
import { Leaderboard } from '../game/Leaderboard'
import { MeshProvider } from '@meshsdk/react'
import { SubscriptionBanner } from './ads/SubscriptionBanner'
import { AdBanner } from './AdBanner'

const Content = () => {
    return (<div className="content">
        <Switch>
            <Route path="/" exact={true} component={Home} />
            <Route path="/account" component={Account} />
            <Route path="/explore/my-account" exact={true} component={ExploreAccount} />
            <Route path="/explore/my-account/staking" component={Staking} />
            <Route path="/explore/:collection/rarity" component={Rarity} />
            <Route path="/explore/cryptodinos/:number/breed" component={BreedPage} />
            <Route path="/explore/:collection/:number" component={GalleryAsset} />
            <Route path="/explore/:collection" component={Explore} />
            <Route path="/roarpaper/breeding" component={Breeding} />
            <Route path="/admin/breeding/:all?" component={Admin} />
            <Route path="/admin/accounting" component={Accounting} />
            <Route path="/roarpaper/game" component={Game} />
            <Route path="/story/1" component={Story1} />
            <Route path="/story/2" component={Story2} />
            <Route path="/how-to-buy" component={HowToBuy} />
            <Route path="/terms-of-service" component={TermsOfService} />
            <Route path="/privacy-policy" component={PrivacyPolicy} />
            <Route path="/link-wallet" component={LinkWallet} />
            <Route path="/download" component={Download} />
            <Route path="/leaderboard" component={Leaderboard} />
        </Switch>
    </div>)
}

export const Layout = () => {

    const policyIds = React.useMemo(() => {
        return Object.entries(collections).map(([, { policyIds }]) => policyIds).flat()
    }, [collections])

    const oidcConfig = React.useMemo(() => ({
        ...getOidcConfig(),
        userStore: new WebStorageStateStore({ store: window.localStorage }),
    }), [])

    return (
        <BrowserRouter>
            <AuthProvider {...oidcConfig}>
                <FeatureProvider>
                    <MeshProvider>
                        <CardanoWalletProvider policyIds={policyIds}>
                            <SaleTokenProvider>
                                <div className="layout">
                                    <Header />
                                    <Content />
                                    <Footer />
                                    <AdBanner adName="subscripton" banner={SubscriptionBanner} />
                                </div>
                            </SaleTokenProvider>
                        </CardanoWalletProvider>
                    </MeshProvider>
                </FeatureProvider>
            </AuthProvider>
        </BrowserRouter>
    )
}