import React from 'react'
import { ModalDialog } from '../components/ModalDialog'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { OverlayContainer } from '@react-aria/overlays'
import { useButton } from '@react-aria/button'
import { CollectionKeys } from '../collections'
import BuyingSavior from '../explore/BuyingSavior.png'
import BuyingDino from '../explore/BuyingDino.png'
import { Button } from '../components/Button'
import { useSaleTokenContext } from './SaleTokenProvider'
import { useCancellablePromise } from '../components/useCancelablePromise'
import './OfferDialog.scss'

export const OfferButton = ({ collection, policyId, assetName }: { collection: CollectionKeys, policyId: string, assetName: string }) => {
    const state = useOverlayTriggerState({})
    const openButtonRef = React.useRef(null)
    const [askingPrice, setAskingPrice] = React.useState(60)
    const { cancellablePromise } = useCancellablePromise()

    const { buttonProps: openButotnProps } = useButton({
        onPress: () => state.open()
    }, openButtonRef)

    const { offer } = useSaleTokenContext()

    const onOfferClick = React.useCallback(() => {
        return cancellablePromise(offer(policyId, assetName, (askingPrice * 1_000_000).toString())).then(() => {
            state.close()
        })
    }, [assetName, policyId, askingPrice, cancellablePromise])

    return (
        <>
            <button {...openButotnProps} ref={openButtonRef}>
                Sell
            </button>
            {state.isOpen && (
                <OverlayContainer>
                    <ModalDialog collection={collection} isOpen={true} onClose={state.close} isDismissable={true}>
                        <div className="marketplace">
                            <h1>
                                Marketplace
                                <img src={collection === 'cryptodinos' ? BuyingDino : BuyingSavior} alt="img" />
                            </h1>
                            <div>
                                <span>Name your price</span><input value={askingPrice} onChange={(target) => setAskingPrice(parseInt(target.currentTarget.value))} />
                            </div>
                            <div className="actions">
                                <Button onPress={onOfferClick}>Put on sale</Button>
                            </div>
                        </div>
                    </ModalDialog>
                </OverlayContainer>
            )}
        </>
    )
}

export const UpdateButton = ({ collection, policyId, assetName, askingPrice: providedAskingPrice }: { collection: CollectionKeys, policyId: string, assetName: string, askingPrice: number }) => {
    const state = useOverlayTriggerState({})
    const openButtonRef = React.useRef(null)
    const [askingPrice, setAskingPrice] = React.useState(providedAskingPrice)
    const { cancellablePromise } = useCancellablePromise()

    const { update } = useSaleTokenContext()

    const { buttonProps: openButotnProps } = useButton({
        onPress: () => state.open()
    }, openButtonRef)

    const onOfferClick = React.useCallback(() => {
        return cancellablePromise(update(policyId, assetName, (askingPrice * 1_000_000).toString())).then(() => {
            state.close()
        })
    }, [assetName, policyId, askingPrice, cancellablePromise])

    return (
        <>
            <button {...openButotnProps} ref={openButtonRef}>
                Update
            </button>
            {state.isOpen && (
                <OverlayContainer>
                    <ModalDialog collection={collection} isOpen={true} onClose={state.close} isDismissable={true}>
                        <div className="marketplace">
                            <h1>
                                Marketplace
                                <img src={collection === 'cryptodinos' ? BuyingDino : BuyingSavior} alt="img" />
                            </h1>
                            <div>
                                <input value={askingPrice} onChange={(target) => setAskingPrice(parseInt(target.currentTarget.value))} />
                            </div>
                            <div className="actions">
                                <Button onPress={onOfferClick}>Update price</Button>
                            </div>
                        </div>
                    </ModalDialog>
                </OverlayContainer>
            )}
        </>
    )
}