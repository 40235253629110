import React from 'react'
import { PageTemplate } from '../components/PageTemplate'
import { CollectionKeys } from '../collections'
import ReactMarkdown from 'react-markdown'
import './Whitepapers.scss'
import remarkGfm from 'remark-gfm'

export const WhitepaperPage = ({ title, whitepaperUrl, collection }: { title: string, whitepaperUrl: string, collection: CollectionKeys }) => {
    const [story, setStory] = React.useState<string>('')
    React.useEffect(() => { fetch(whitepaperUrl).then(response => response.text()).then(setStory) }, [whitepaperUrl])

    return (
        <PageTemplate title={title} collection={collection}>
            <div className="whitepaper">
                <ReactMarkdown children={story} remarkPlugins={[remarkGfm]} />
            </div>
        </PageTemplate>
    )
}