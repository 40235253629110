import React from 'react'
import { AssetItem, ComposedAsset } from '../Api'
import { Link } from 'react-router-dom'
import { CollectionKeys, collections } from '../collections'
import { Skeleton } from '../components/Skeleton'
import DinoCoinSrc from '../breeding/DinoCoin_78x78.png'
import { useCooldownPeriod } from './AssetDialog'
import { ReactComponent as CooldownIcon } from './break-time-icon.svg'
import { ReactComponent as BreedingIcon } from '../breeding/breed_icon.svg'

export type IpfsImageProps = {
    name: string,
    hash: string,
}
export const IpfsImage = ({ hash }: IpfsImageProps) => (<img src={`https://ipfs.io/ipfs/${hash}`} alt="name" />)

export const decomposeAssetName = (assetName: string) => {
    const match = /(?:(\d+)\-)?(\d+)/.exec(assetName)
    if (match) {
        return {
            generation: match[1] || '0',
            number: match[2],
            printgen: assetName.indexOf('CryptoDino') == 0
        }
    } else {
        throw Error('Invalid assetName')
    }
}

export const assetShortName = (assetName: string) => {
    const { generation, number, printgen } = decomposeAssetName(assetName)
    return printgen ? `Gen ${generation} #${number}` : `#${number}`
}

export const assetLinkName = (assetName: string) => {
    const { generation, number } = decomposeAssetName(assetName)
    return `${generation}-${number.padStart(5, '0')}`
}

export const normalizedName = (assetName: string) => {
    const { generation, number } = decomposeAssetName(assetName)
    return generation === '0' ? `CryptoDino${number}` : `CryptoDino-${generation}-${number}`
}

export const GalleryTile = ({ collection, value }: { collection: CollectionKeys, value: ComposedAsset }) => {
    const cooldownPeriod = useCooldownPeriod(value)
    return (
        <Link to={`/explore/${collection}/${assetLinkName(value.asset.name)}`}>
            {value.offer && <div className="top-badge"><div>{(parseInt(value.offer.price) / 1_000_000).toFixed(2)} ADA</div></div>}
            <IpfsImage hash={value.asset.thumbprint.ipfsHash} name={value.asset.name} />
            <h3>
                <span>{assetShortName(value.asset.name)}</span>
                {value.dinoCoins && <img src={DinoCoinSrc} style={{ width: '24px', height: '24px', display: 'inline-block' }} alt="Has DinoCoins to claim" />}
            </h3>
            <p>
                <div title="Breeding count" style={{ marginRight: 10 }}>Breeding count: {value.counter.counter}</div>
                <div title="Remaining cooldown period">Cooldown period: {cooldownPeriod > 0 ? `${cooldownPeriod}day(s)` : 'ready'}</div>
                <div>Rank {value.asset.rank}</div>
            </p>
        </Link>
    )
}

export const GalleryTileSkeleton = () => {
    return (
        <div>
            <Skeleton className="img-skeleton" />
            <Skeleton className="name-skeleton" />
        </div>
    )
}