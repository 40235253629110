import { toHex, toByteArray, splitAddress, } from '../cardano/plutus-helpers'
import { cardanoApiAdapter } from '../cardano/cardano-adapter'
import * as ConfigProvider from '../configProvider'
import { appInsights } from '../AppInsights' 
import { BrowserWallet, Transaction } from '@meshsdk/core'

const transferFixedAmount = "1200000";

export const canTopUpGameAccount = async (api: BrowserWallet): Promise<Boolean> => {
    const cardano = cardanoApiAdapter(api)
    const dinoCoins = await cardano.getCoinBalanace(ConfigProvider.getDinoCoinPolicyIdV2())
    if (dinoCoins) {
        return parseInt(dinoCoins) > 0
    } else {
        return false;
    }
}

export const topUpGameAccount = async (wallet: BrowserWallet, dinolvlAmount: number): Promise<string> => {
    const selfAddress = await wallet.getUsedAddress()
    const topUpAddress = ConfigProvider.getTopUpAddress()
    const tx = new Transaction({ initiator: wallet })
        .sendAssets(
            topUpAddress,
            [
              {
                unit: 'lovelace',
                quantity: transferFixedAmount.toString(),
              },
              {
                unit: `${ConfigProvider.getDinoCoinPolicyIdV2()}${toHex(toByteArray(ConfigProvider.getBreedingDinoCoinName()))}`,
                quantity: dinolvlAmount.toString(),
              },
            ]
          );

    const meta: TopUpMetadata = {
        addr: splitAddress(selfAddress.to_bech32()),
        dinolvl: dinolvlAmount.toString(),
        op: "topup"

    };
    tx.setMetadata(606, meta);
    try {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`txHash for topUp ${txHash} DINO love amount: ${dinolvlAmount}`);
        appInsights.trackTrace({ message: 'topup' }, {
            owner: selfAddress.to_bech32(),
            wallet: localStorage.getItem('wallet'),
            txHash: txHash,
            dinoLvlAmount: dinolvlAmount.toString(),
            method: "topup"
        });
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            owner: selfAddress.to_bech32(),
            dinoLvlAmount: dinolvlAmount.toString(),
            method: "topup"
        });
        throw err;
    }
}

export interface TopUpMetadata {
    addr: string[]
    dinolvl: string,
    op: string
}