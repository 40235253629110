import React from 'react'
import { Link, NavLink } from 'react-router-dom'
import './Header.scss'
import CdLogoSrc from './CryptoDinoLogo.png'
import { WalletConnectAccountButton } from '../roadmap/Menu'

const Logo = () => (<Link to="/" className="logo"><img src={CdLogoSrc} alt="CryptoDino" /></Link>)
const Menu = ({ burgerMenuVisible, toggle }: {burgerMenuVisible: boolean, toggle: () => void }) => {
    return (
        <div className={`menu ${burgerMenuVisible ? 'show' : ''}`}>
            <ul className="main-menu">
                <li><Link to="/roarpaper/game" onClick={toggle}>About</Link></li>
                <li><Link to="/story/1" onClick={toggle}>Story</Link></li>
                <li><a href="https://cryptodino.creator-spring.com" target="_blank" rel="noreferrer">Merch</a></li>
                <li><Link to="/explore/cryptodinos" onClick={toggle}>Explore</Link></li>
                <li><a href="https://discord.gg/Mgvphj5JpW" onClick={toggle}>Community</a></li>
                <Link to="/leaderboard/players">Leaderboard</Link>
            </ul>
            <div className="account-menu" onClick={toggle}>
                <Link className="primary" to="/download">Play the game</Link>
                <WalletConnectAccountButton />
            </div>
        </div>
    )
}

const MenuButton = ({ toggle }: any) => (
    <button className="menu-button" onClick={toggle}>
        <svg width="24px" height="24px" viewBox="0 0 512 512"><path fill="#fff" d="M32 96v64h448V96H32zm0 128v64h448v-64H32zm0 128v64h448v-64H32z" /></svg>
    </button>
)

export const Header = () => {

    const [visible, setVisible] = React.useState(false)
    const toggleMenu = React.useCallback(() => {
        setVisible(!visible);
    }, [visible])

    return (
        <div className="header">
            <Logo />
            <MenuButton toggle={toggleMenu} />
            <Menu burgerMenuVisible={visible} toggle={toggleMenu} />
        </div>
    )
}