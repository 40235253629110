import {
    BaseAddress,
    Address,
    PlutusData} from '../custom_modules/@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib'
import { fromHex, toHex, toPlutusData, PlutusDataObject, PlutusFieldType, toByteArray } from '../cardano/plutus-helpers'

export const getLegacySalesOfferDatumInHex = async (policyId: string, assetName: string, price: string, ownerAddress: string): Promise<string> => {
    const selfAddress = Address.from_bech32(ownerAddress)
    const baseAddress = BaseAddress.from_address(selfAddress)!
    const pkh = toHex(baseAddress.payment_cred().to_keyhash()?.to_bytes() as Uint8Array);

    const salesOfferDatum = createSalesOfferDatum(pkh, price, policyId, assetName);
    const datum = toPlutusData(salesOfferDatum);
    const datumHex = toHex(datum.to_bytes())
    const compatibleDatumHex = toHex(PlutusData.from_bytes(
            fromHex(datumHex)
        ).to_bytes()
    )

    return compatibleDatumHex;
}

const createSalesOfferDatum = (sellerPublicKeyHash: string, price: string, policyId: string, assetName: string): PlutusDataObject => {
    return {
        constructorIndex: 0,
        fields: [
            {
                index: 0,
                type: PlutusFieldType.Bytes,
                key: "sellerPublicKeyHash",
                value: fromHex(sellerPublicKeyHash)
            },
            {
                index: 1,
                type: PlutusFieldType.Integer,
                key: "price",
                value: price
            },
            {
                index: 2,
                type: PlutusFieldType.Bytes,
                key: "policyId",
                value: fromHex(policyId)
            },
            {
                index: 3,
                type: PlutusFieldType.Bytes,
                key: "assetName",
                value: toByteArray(assetName)
            },
        ]
    }
}