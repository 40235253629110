import { splitAddress, } from '../cardano/plutus-helpers'
import * as ConfigProvider from '../configProvider'
import { appInsights } from '../AppInsights' 
import { BrowserWallet, Transaction } from '@meshsdk/core'

const rewardsTransferFixed = "3000000";

export const claimRewards = async (wallet: BrowserWallet, withdrawingTransactions: string[]): Promise<string> => {
    const selfAddress = await wallet.getUsedAddress()
    const rewardsAddress = ConfigProvider.getRewardsPaymentAddress()

    const tx = new Transaction({ initiator: wallet })
        .sendLovelace(rewardsAddress, rewardsTransferFixed.toString())

    const meta: RewardsMetadata = {
        owner: splitAddress(selfAddress.to_bech32()),
        withdraws: withdrawingTransactions
    };
    tx.setMetadata(605, meta);

    try {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`txHash: ${txHash} for rewards claiming withdraw trans: ${withdrawingTransactions.join(",")} requestor: ${selfAddress.to_bech32()}`);
        appInsights.trackTrace({ message: 'rewards' }, {
            owner: selfAddress.to_bech32(),
            wallet: localStorage.getItem('wallet'),
            txHash: txHash,
            withdraws: withdrawingTransactions.join(","),
            method: "rewards"
        });
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            requestor: selfAddress.to_bech32(),
            withdraws: withdrawingTransactions.join(","),
            method: "rewards"
        });
        throw err;
    }
}

export interface RewardsMetadata {
    owner: string[],
    withdraws: string[]
}