import React from 'react'
import { NavLink } from 'react-router-dom'
import { BannerProps } from '../AdBanner'

export const SubscriptionBanner: React.FC<BannerProps> = ({ close }) => (
    <div>
        <h1>New feature available</h1>
        <p>Elevate your CryptoDino experience with our monthly subscription.</p>
        <ul>
            <li>
                <div><strong>Breed with power</strong></div>
            </li>
            <li>
                <div><strong>Double the rewards</strong></div>
            </li>
            <li>
                <div><strong>Strategic advantage</strong></div>
            </li>
            <li>
                <div><strong>Future excitement</strong></div>
            </li>
        </ul>
        <div>
            <NavLink className="button" to={{ pathname: "/account", state: { openSubscription: true }} } onClick={close}>Learn more</NavLink>
        </div>
    </div>
)