import { getApiUrl } from "../configProvider"

type OfferState = 'PendingOnBlockchain' | 'AcceptedOnBlockchain'

export type Offer = {
    policyId: string,
    assetName: string,
    sellerAddress: string,
    sellerPkh: string,
    price: string,
    txHash: string,
    state: OfferState,
}

export type OfferItem = {
    policyId: string,
    assetName: string,
    price: string,
    sellerAddress: string,
}

export const getOffer = (policyId: string, assetName: string) => {
    return fetch(getApiUrl() + `/api/offers/${policyId}/${assetName}`).then<Offer>(response => response.json())
}

export const getOffers = (policyId: string) => {
    return fetch(getApiUrl() + `/api/offers/${policyId}`).then<OfferItem[]>(response => response.json())
}

export const getMyOffers = (policyId: string, address: string) => {
    return fetch(getApiUrl() + `/api/offers/${policyId}?sellerAddress=${address}`).then<OfferItem[]>(response => response.json())
}