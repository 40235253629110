import React from 'react'
import SkeletonComponent from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

type SkeletonProps = {
    containerClassName?: string,
    className?: string,
    width?: number,
    height?: number,
    borderRadius?: number,
}

export const Skeleton = ({ ...props }: SkeletonProps) => (<SkeletonComponent {...props} baseColor="#CBC2D0" highlightColor="#BCB0C1" />)