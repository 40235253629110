import React from 'react'
import { useSwitch } from '@react-aria/switch'
import { VisuallyHidden } from '@react-aria/visually-hidden';
import { useToggleState } from '@react-stately/toggle';
import { useFocusRing } from '@react-aria/focus';
import './Switch.scss'

export const Switch = (props: any) => {
    const state = useToggleState(props);
    const ref = React.useRef<HTMLInputElement>(null);
    const { inputProps } = useSwitch(props, state, ref);
    const { isFocusVisible, focusProps } = useFocusRing();

    return (
        <label style={{ display: 'inline-flex', alignItems: 'center' }} className="switch">
            <VisuallyHidden>
                <input {...inputProps} {...focusProps} ref={ref} />
            </VisuallyHidden>
            <svg
                width={60}
                height={34}
                aria-hidden="true"
                className={state.isSelected ? 'checked' : ''}
                style={{ marginRight: 4 }}>
                <rect
                    className="bkg"
                    x={4}
                    y={4}
                    width={48}
                    height={26}
                    rx={13}
                    fill={state.isSelected ? '#CDDB56' : 'white'}
                />
                <circle
                    cx={state.isSelected ? 40 : 17}
                    cy={17}
                    r={10}
                    className="cirlce"
                    fill="#411946"
                />
                {isFocusVisible &&
                    <rect
                        x={4}
                        y={4}
                        width={48}
                        height={26}
                        rx={13}
                        fill="none"
                        stroke="#411946"
                        strokeWidth={2} />
                }
            </svg>
            {props.children}
        </label>
    );
}