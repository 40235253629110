import React from 'react'
import { PageTemplate } from '../components/PageTemplate'
import Step1 from './guide/SaviorsBuyingGuide_01.png'
import Step2 from './guide/SaviorsBuyingGuide_02.png'
import Step3 from './guide/SaviorsBuyingGuide_03.png'
import Step4 from './guide/SaviorsBuyingGuide_04.png'
import Step5 from './guide/SaviorsBuyingGuide_05.png'
import Step6 from './guide/SaviorsBuyingGuide_06.png'
import './HowToBuy.scss'

export const HowToBuy = () => (
    <PageTemplate collection="dinosaviors" title="How to buy?">
        <div className="guide">
            <h2>Step 1</h2>
            <img src={Step1} />
            <h2>Step 2</h2>
            <img src={Step2} />
            <h2>Step 3</h2>
            <img src={Step3} />
            <h2>Step 4</h2>
            <img src={Step4} />
            <h2>Step 5</h2>
            <img src={Step5} />
            <h2>Step 6</h2>
            <img src={Step6} />
        </div>
    </PageTemplate>
)