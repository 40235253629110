import React from 'react'
import { PageTemplate } from '../components/PageTemplate'
import { useData } from '../components/useData'
import { getLeaderboard, PlayerType, getTokenLeaderboard } from '../Api'
import './Leaderboard.scss';
import { Link, NavLink, Route, Switch, useLocation } from 'react-router-dom';
import { assetLinkName } from '../explore/GalleryTile';

export const Leaderboard = () => {
    return (
        <PageTemplate title="Leaderboard">
            <div className="leaderboard">
                <ul className="categories-tabs">
                    <li>
                        <NavLink to="/leaderboard/players">Players</NavLink>
                    </li>
                    <li>
                        <NavLink to="/leaderboard/tokens">Tokens</NavLink>
                    </li>
                </ul>
                <Switch>
                    <Route path="/leaderboard/players" component={PlayerRanking} />
                    <Route path="/leaderboard/tokens" component={TokenRanking} />
                </Switch>
            </div>
        </PageTemplate>
    )
}

const PlayerRanking = () => {

    const location = useLocation()
    const getLeaderboardData = React.useCallback(() => {
        let playerType: PlayerType;
        switch (location.pathname) {
            case '/leaderboard/players/rookies':
                playerType = PlayerType.Rookies
                break
            default:
                playerType = PlayerType.All
        }
        return getLeaderboard(playerType)
    }, [location])
    const { data } = useData(getLeaderboardData)

    return (
        <>
            <ul className="categories-tabs">
                <li>
                    <NavLink exact={true} to="/leaderboard/players">All</NavLink>
                </li>
                <li>
                    <NavLink to="/leaderboard/players/rookies">Rookies</NavLink>
                </li>
            </ul>
            <table>
                <thead>
                    <tr>
                        <th></th>
                        <th>Name / Address</th>
                        <th>Level</th>
                        <th>Experience</th>
                        <th>Change</th>
                    </tr>
                </thead>
                <tbody>
                    {data?.map((item, index) => (
                        <tr key={item.accountId}>
                            <td>{index + 1}</td>
                            <td>{item.name || item.address}</td>
                            <td>{item.level}</td>
                            <td>{item.experience}</td>
                            <td>{item.delta}</td>
                        </tr>))}
                </tbody>
            </table>
        </>
    )
}

const TokenRanking = () => {
    const getLeaderboardData = React.useCallback(() => {
        return getTokenLeaderboard()
    }, [])
    const { data } = useData(getLeaderboardData)

    return (
        <table>
            <thead>
                <tr>
                    <th></th>
                    <th>Token</th>
                    <th>Level</th>
                    <th>Experience</th>
                </tr>
            </thead>
            <tbody>
                {data?.map((item, index) => (
                    <tr key={item.assetId}>
                        <td>{index + 1}</td>
                        <td><Link to={`/explore/${item.assetType.toLowerCase()}s/${assetLinkName(item.name)}`}>{item.name}</Link></td>
                        <td>{item.level}</td>
                        <td>{item.experience}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    )
}