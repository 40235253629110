import AssetFingerprint from '@emurgo/cip14-js'
import { Address, BigNum } from '@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib'
import { toHex } from '../legacy/cardano/plutus-helpers'
import { toByteArray } from '../cardano/plutus-helpers'
import { AssetExtended, UTxO } from '@meshsdk/core'

export type Value = any
export type TransactionUnspentOutput = any

export type CardanoWindow = typeof window & {
    cardano: Cardano
}

export type CardanoWallet = {
    isEnabled(): Promise<boolean>,
    enable(): Promise<CardanoApi>,
    name: string,
    icon: string,
}

export type Cardano = {
    [walletName: string]: CardanoWallet,
}

export const throwError = () => { throw new Error('No Cardano wallet') }

export type CardanoApi = {
    getBalance: () => Promise<Value>,
    getUtxos: (amount?: Value, paginate?: { page: number, limit: number }) => Promise<TransactionUnspentOutput[]>,
    getUsedAddresses(paginate?: { page: number, limit: number }): Promise<Address[]>,
    signTx(tx: string, b: boolean): Promise<string>,
    submitTx(txCBORHex: string): Promise<any>,
    getCollateral(): Promise<string[]>,
    signData(address: any, payload: string) : Promise<SignedData>,
    experimental: {
        getCollateral(): Promise<string[]>,
    }
}

export type SignedData = string | DataSignature

export type DataSignature = {
    key: string,
    signature: string
}

export enum ApiErrorCode {
    Refused = -3,
    AccountChanged = -4,
}

export type ApiError = {
    code: number,
    info: string,
}

export enum TxSignErrorCode {
    ProofGeneration = 1,
    UserDeclined = 2,
}

export const isApiError = (value: any): value is ApiError => value && Boolean(value.code) && Boolean(value.info)

export type CardanoAsset = {
    assetId: string,
    policyId: string,
    assetName: string,
    number: string,
    fingerprint: string,
}

function toHexString(byteArray: Uint8Array) {
    return Array.from(byteArray, function (byte: number) {
        return ('0' + (byte & 0xFF).toString(16)).slice(-2);
    }).join('')
}

function hex2a(hexx: string) {
    if (!hexx) {
        return "";
    }
    var hex = hexx.toString();//force conversion
    var str = '';
    for (var i = 0; i < hex.length; i += 2)
        str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}

export function hasCardano(window: Window | CardanoWindow): window is CardanoWindow {
    return (window as CardanoWindow).cardano !== undefined
}

export function getCoinsBalance(utxos: TransactionUnspentOutput[], policyId: string): string {
    return utxos.map((tx) => {
        const multiasset = tx.output().amount().multiasset()
        let balance = 0
        if (multiasset) {
            const len = multiasset.len()
            for (let i = 0; i < len; i++) {
                const rawKey = multiasset.keys().get(i)
                const key = rawKey.to_bytes()
                const multiassetPolicyId = toHexString(key)
                if (multiassetPolicyId === policyId) {
                    const currentHash = multiasset.get(rawKey)
                    if (currentHash) {
                        for (let j = 0; j < currentHash.len(); j++) {
                            const assetKey = currentHash.keys().get(j)
                            balance = balance + parseInt(currentHash.get(assetKey).to_str())
                        }
                    }
                }
            }
        }

        return balance
    }).reduce((acc, current) => acc + current, 0).toString()
}

export function getAssets(utxos: TransactionUnspentOutput[], policyIds: string[]): CardanoAsset[] {
    return utxos.map((tx) => {
        const multiasset = tx.output().amount().multiasset()
        const results = []
        if (multiasset) {
            const len = multiasset.len()
            for (let i = 0; i < len; i++) {
                const rawKey = multiasset.keys().get(i)
                const key = rawKey.to_bytes()
                const currentHash = multiasset.get(rawKey)
                if (currentHash) {
                    for (let j = 0; j < currentHash.len(); j++) {
                        const assetKey = currentHash.keys().get(j)
                        const assetHexName = toHexString(assetKey.name())
                        const assetName = hex2a(assetHexName)
                        const assetId = `${toHexString(key)}${assetHexName}`
                        results.push({
                            assetId,
                            policyId: toHexString(key),
                            assetName,
                            number: /\d+/.exec(assetName)?.[0] || '',
                            fingerprint: AssetFingerprint.fromParts(key, assetKey.name()).fingerprint()
                        })
                    }
                }
            }
        }

        return results
    }).flat().filter(x => policyIds.indexOf(x.policyId) !== -1)
}

export function assetExtendedToCardanoAsset(asset: AssetExtended): CardanoAsset {
    const cardanoAsset: CardanoAsset = {
        assetId: `${asset.policyId}${toHex(toByteArray(asset.assetName))}`,
        assetName: asset.assetName,
        fingerprint: asset.fingerprint,
        policyId: asset.policyId,
        number: /\d+/.exec(asset.assetName)?.[0] || ''
    }
    return cardanoAsset;
}

export const containsAsset = (item: any, policyId: string, name: string) => {
    if (item.output().amount().multiasset()?.to_js_value === undefined) {
        return false;
    } 
    const multiasset = [...item.output().amount().to_js_value().multiasset]
    return multiasset.some(x => x[0] === policyId && [...x[1]].some(x => x[0] === toHex(toByteArray(name))));
}

export const containsMinLovelace = (item: TransactionUnspentOutput, lovelace: Number) => {
    return item.output().amount().multiasset()?.to_js_value === undefined && Number.parseInt(item.output().amount().to_js_value().coin) >= lovelace
}
