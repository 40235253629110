import React, { useCallback } from 'react'
import { NotificationItem, NotificationType } from './NotificationProvider'
import './Toast.scss'
import { ReactComponent as CheckCircleOutlineIcon } from './CheckCirlceOutline.svg'
import { ReactComponent as ErrorOutlineOutlinedIcon } from './ErrorOutlineOutlined.svg'
import { ReactComponent as ReportProblemOutlinedIcon } from './ReportProblemOutlined.svg'

const typeToString = (type: NotificationType) => {
  switch (type) {
  case NotificationType.Info: return 'Success'
  case NotificationType.Warning: return 'Warning'
  case NotificationType.Error: return 'Error'
  }
}

const typeToClassName = (type: NotificationType) => {
  switch (type) {
  case NotificationType.Info: return 'success'
  case NotificationType.Warning: return 'warning'
  case NotificationType.Error: return 'error'
  }
}

const TypeIcon = ({ type }: { type: NotificationType }) => {
  switch (type) {
  case NotificationType.Info: return <CheckCircleOutlineIcon />
  case NotificationType.Warning: return <ReportProblemOutlinedIcon />
  case NotificationType.Error: return <ErrorOutlineOutlinedIcon />
  }
}

type ToastProps = NotificationItem & {
  onClick: (() => void) | undefined,
}
const Toast = ({ message, type, onClick }: ToastProps) => {
  return (
    <div className={`toast-item show ${typeToClassName(type)}`} onClick={onClick}>
      <div className="toast-icon"><TypeIcon type={type} /></div>
      <div className="toast-header">{typeToString(type)}</div>
      <div className="toast-message" role="alert">{message}</div>
    </div>
  )
}

export type ToastContainerProps = {
  notifications: NotificationItem[],
  dismiss: (notification: NotificationItem) => void,
}
export const ToastContainer = ({ notifications, dismiss }: ToastContainerProps): JSX.Element => {
  const notification = notifications[0]
  const isOpen = Boolean(notification)
  const close = useCallback(() => dismiss(notification), [notification, dismiss])
  React.useEffect(() => {
    if (notification?.type === NotificationType.Info) {
      setTimeout(close, 6000)
    }
  }, [notification, close])
  React.useEffect(() => {
    document.addEventListener('mousedown', close)
    return () => document.removeEventListener('mousedown', close)
  }, [close])
  return (
    <React.Fragment>
      {isOpen && (
        <div className="toast-container">
          <Toast {...notification} onClick={close} />
        </div>
      )}
    </React.Fragment>
  )
}