import React from 'react'
import { CollectionKeys } from '../collections'
import './PageTemplate.scss'
import { ReactComponent as ArrowUp } from './ArrowUp.svg'

type PageTemplateProps = {
    title: any,
    titleImgSrc?: string,
    collection?: CollectionKeys
}

export const PageTemplate: React.FC<PageTemplateProps> = ({ title, titleImgSrc, collection: collectionParam, children }) => {

    return (<div className="page">
        <div>
            <div>
                <header>
                    {titleImgSrc && <img src={titleImgSrc} alt="" />}
                    <h1>{title}</h1>
                </header>
            </div>
            {children}
            <div className="scroll-up" title="Scroll up">
                <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
                    <ArrowUp  />
                </button>
            </div>
        </div>
    </div>
    )
}