import React from 'react'
import { ModalDialog } from '../components/ModalDialog'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { OverlayContainer } from '@react-aria/overlays'
import { useButton } from '@react-aria/button'
import { Button } from '../components/Button'
import * as Api from '../Api'
import { useAuth } from 'react-oidc-context'
import DinoCoinIconSrc from '../breeding/DinoCoin_78x78.png'
import './EnableFeatureDialog.scss'
import { useSubscriptionFeatures } from './Game'
import { useHistory, useLocation } from 'react-router-dom'

const price = 2_000;

export const SubscriptionBanner = ({ onComplete }: { onComplete?: () => Promise<void> }) => {

    const state = useOverlayTriggerState({})
    const openButtonRef = React.useRef(null)
    const closeButtonRef = React.useRef(null)
    const { user, signinPopup } = useAuth()
    const location = useLocation<{ openSubscription?: boolean }>()
    const history = useHistory()

    const open = React.useCallback(() => {
        if (user?.access_token && !user.expired) {
            state.open()
        } else {
            signinPopup().then(() => state.open())
        }
    }, [user, signinPopup, state])

    const { buttonProps: openButotnProps } = useButton({
        onPress: () => {
            open()
        }
    }, openButtonRef)

    React.useEffect(() => {
        if (location.state?.openSubscription) {
            history.replace(location.pathname, {})
            open()
        }
    }, [location, open, history])

    const { data: subscription, refresh } = useSubscriptionFeatures(user?.expired ? undefined : user?.access_token)

    const enableFeature = React.useCallback(() => {
        return Api.activateSubscription(user?.access_token!).then(() => {
            state.close()
            refresh()
            if (onComplete) {
                return onComplete()
            }
        })
    }, [user?.access_token, state, refresh, onComplete])

    return (
        <>
            {subscription?.active !== true && (
                <div className="subscription-banner">
                    <button className="button" {...openButotnProps} ref={openButtonRef}>
                        Elevate your CryptoDino experience with our monthly subscription
                    </button>
                </div>
            )}

            {state.isOpen && (
                <OverlayContainer>
                    <ModalDialog collection="cryptodinos" isOpen={true} onClose={state.close} isDismissable={true}>
                        <h1>
                            Activate subscription
                        </h1>
                        <div className="dialog-content enable-feature-dialog">
                            <div className="featres-description">
                                <p>Elevate your CryptoDino experience with our monthly subscription.</p>
                                <ul>
                                    <li>
                                        <div><strong>Breed with power</strong></div>
                                        <div><small>New CryptoDinos, right from the start, begin at level 10!</small></div>
                                    </li>
                                    <li>
                                        <div><strong>Double the rewards</strong></div>
                                        <div><small>Multiply your $DINO staking rewards for maximum crypto gains.*</small></div>
                                    </li>
                                    <li>
                                        <div><strong>Strategic advantage</strong></div>
                                        <div><small>Access player performance insights and individual leaderboard positions.</small></div>
                                    </li>
                                    <li>
                                        <div><strong>Future excitement</strong></div>
                                        <div><small>Stay tuned for more thrilling features coming soon!</small></div>
                                    </li>
                                </ul>
                                <p><small style={{ fontSize: '0.5rem' }}>* - Multiplied reward applies up to 100 000 $DINO</small></p>
                            </div>
                            <div>Subscription fee:</div>
                            <h3>{price} <img src={DinoCoinIconSrc} style={{ height: 24, verticalAlign: 'text-bottom' }} alt="DINO" title='DINO' /> / month</h3>
                            <p><small>The amount will be deducted from your game account.</small></p>
                            <div className="actions">
                                <Button onPress={enableFeature}>Activate</Button>
                            </div>
                        </div>
                    </ModalDialog>
                </OverlayContainer>
            )
            }
        </>
    )
}