import React from 'react'
import { Button } from '../components/Button'
import { PageTemplate } from '../components/PageTemplate'
import './Staking.scss'
import DinoCoinSrc from '../breeding/DinoCoin_78x78.png'
import { useCardanoWalletProvider } from '../cardano/CardanoWalletProvider'
import * as Api from '../Api'
import { babydinosPolicyId, cryptodinosPolicyId, dinoPolicyId, dinosaviorsPolicyId } from '../collections'
import { ClaimRewardButton } from './ClaimRewardDialog'
import StakingHeaderImg from './staking.png'
import * as configProvider from '../configProvider'
import { useNotifications } from '../components/NotificationProvider'
import { format } from 'date-fns'

type StakingCollectionDefinition = {
    name: string,
    policyId: string,
    imageUrl: string,
}

const StakingCollection = ({ collection, rewards }: { collection: StakingCollectionDefinition, rewards?: Api.StakingRewards }) => {

    return (
        <div className="staking-collection">
            <div className="collection-image"><img src={collection.imageUrl} /></div>
            <div className="collection-name">{collection.name}</div>
            <div className="asset-count">{rewards?.quantity || 0}</div>
            <div className="daily-profit"><DinoCoinsAmount value={rewards?.dailyReward || 0} /></div>
            <div className="total-profit"><DinoCoinsAmount value={rewards?.totalReward || 0} /></div>
        </div>
    )
}

const useStaking = (walletAddress: string) => {
    const [summary, setSummary] = React.useState<Api.StakingWalletResponse | null>()
    const [loading, setLoading] = React.useState(false)
    const load = React.useCallback(() => {
        if (walletAddress) {
            setLoading(true)
            Api.getStakingSummary(walletAddress).then((response) => {
                setSummary(response)
                setLoading(false)
            });
        }
    }, [walletAddress])
    React.useEffect(load, [load])

    return { data: summary, loading, refresh: load }
}

export const Staking = () => {
    const { walletAddress } = useCardanoWalletProvider()
    const { addInfo, addError } = useNotifications()
    //const walletAddress = 'addr1q90er92smz78yulqqpney8wy0xm2jltqumrkd6y6ldggsahjxj8cpyjsd9wj95k5zwkypav7aptxx3ps56far2mfaw3s298pxg'
    const address = walletAddress!
    const { data: staking, loading, refresh } = useStaking(address)
    const cryptodinos: StakingCollectionDefinition = { policyId: cryptodinosPolicyId, name: 'CryptoDinos', imageUrl: 'https://cnft.pictures/d81deAdtiklj-MuIHBWHAkwY5Qsr_WRwCK59WEhYN1U/rs:fit:128:128:0:0/g:no/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2pwZy1zdG9yZS1pbWFnZXMvaGVyby1pbWFnZXMvY3J5cHRvZGlub3Mud2VicA.webp' }
    const dinosaviors: StakingCollectionDefinition = { policyId: dinosaviorsPolicyId, name: 'DinoSaviors', imageUrl: 'https://cnft.pictures/SGoAvyYXbKYf4zCY3nm1QvKcaKkc68i0ISeE3g_S2Sg/rs:fit:128:128:0:0/g:no/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2pwZy1zdG9yZS1pbWFnZXMvaGVyby1pbWFnZXMvZGlub3NhdmlvcnMud2VicA.webp' }
    const babydinos: StakingCollectionDefinition = { policyId: babydinosPolicyId, name: 'CryptoDinos - next gen', imageUrl: 'https://cnft.pictures/oklAJTTNZf3vfvcQtFTelg48YmpwwaVr0K4XFGRt7LY/rs:fit:128:128:0:0/g:no/aHR0cHM6Ly9pbWFnZXMuanBnc3RvcmVhcGlzLmNvbS9hODFlYTYzNy01ZmRkLTQ0ZmQtOTFjOC0yMGI5ZGZiY2YyZTk.webp' }
    const dinocoins: StakingCollectionDefinition = { policyId: dinoPolicyId, name: '$DINO', imageUrl: DinoCoinSrc }
    const startStaking = React.useCallback(async () => {
        const response = await Api.startStaking(address)
        if (response.ok) {
            addInfo('Staking address registered')
            refresh()
        } else {
            addError(`Unable to register ${address} status ${response.status} ${response.statusText}`)
        }
    }, [walletAddress, addInfo, addError])

    const rewards = React.useMemo(() => [
        { collection: cryptodinos, rewards: staking?.collections.find(c => c.policyId === cryptodinos.policyId) },
        { collection: dinosaviors, rewards: staking?.collections.find(c => c.policyId === dinosaviors.policyId) },
        { collection: babydinos, rewards: staking?.collections.find(c => c.policyId === babydinos.policyId) },
        { collection: dinocoins, rewards: staking?.collections.find(c => c.policyId === dinocoins.policyId) },
    ], [staking])

    const accountIds = React.useMemo(() => staking?.collections.map(x => x.accountId) || [], [staking])

    return (
        <div>
            {!loading && (
                staking ? (
                    staking.snapshotTime.startsWith('0001') ? (
                        <p>Your wallet has been successfully registered. New snapshot will be captured within next 24 hours.</p>
                    ) : (
                        <>
                            <div>
                                <div className="staking-collection-header">
                                    <div className="collection-name">Token</div>
                                    <div className="asset-count">Quantity</div>
                                    <div className="daily-profit">Est. next distribution</div>
                                    <div className="total-profit">Unclaimed rewards</div>
                                </div>
                                {rewards.map(r => <StakingCollection key={r.collection.policyId} {...r} />)}
                            </div>
                            <div>
                                Rewards available: <span className="total-reward"><DinoCoinsAmount value={staking?.totalReward} /> < img src={DinoCoinSrc} alt="$DINO" /></span>
                            </div>
                            <div style={{ fontSize: '0.75rem' }}>
                                <p>
                                    Snapshot time: {format(new Date(staking.snapshotTime), 'Ppppp')}<br />
                                    Wallet snapshot is captured once a day<br />
                                    During withdraw we are charging 1ADA service fee
                                </p>
                            </div>
                                <div style={{ paddingTop: 10 }}>
                                    {configProvider.isRewardsClaimingEnabled() && staking?.totalReward >= 1 && <ClaimRewardButton walletAddress={address} accountIds={accountIds} onComplete={refresh} max={staking?.totalReward ?? 0} />}
                            </div>
                        </>
                    ))
                    : (
                        <>
                            <p>You are not registered for staking. Click button below to register your wallet.</p>
                            <Button onPress={startStaking}>Register</Button>
                        </>
                    )
            )}
        </div>
    )
}

export const DinoCoinsAmount = ({ value }: { value?: number }) => (<>{value && (value / 1000000)}</>)