import { getDinoCoinPolicyIdV2, isTest } from "./configProvider"

import { ProjectMode } from './roadmap/Menu'

export type Collection = {
    collectionId: string,
    policyIds: string[],
    name: string,
    assetName: string,
    mode: ProjectMode,
    features: string[],
}

export type Collections = {
    cryptodinos: Collection,
    dinosaviors: Collection,
}

export const cryptodinosPolicyId = isTest() ? '34b5b8c25f0ee8c44bac8f88dae27432759203ffb0ae558d847f08ff' : '7a5a5c3757d33c2b2ff0b09405676e61f93d28b5d12805dd3320e31f'
export const babydinosPolicyId = isTest() ? '34b5b8c25f0ee8c44bac8f88dae27432759203ffb0ae558d847f08ff' : 'f201323ed0fce8db289bb98e0d9865f1d64631c76bb7055183b27454'
export const dinosaviorsPolicyId = isTest() ? 'e61bfc106338ed4aeba93036324fbea8150fd9750fcffca1cd9f1a19' : '887e4d7b2e7d574484f8c780abef1599ce0bad1c751fb14ccdf9d410'
export const dinoPolicyId = getDinoCoinPolicyIdV2()

export const getPolicyIdByName = (assetName: string): string => {
    if (assetName.indexOf('DinoSavior') === 0) {
        return dinosaviorsPolicyId
    } else {
        if (/\-\d\-/.test(assetName)) {
            return babydinosPolicyId
        } else {
            return cryptodinosPolicyId
        }
    }
}

export const collections: Collections = {
    cryptodinos: {
        collectionId: '86ecd384-a446-4219-a6b5-6fa1339fc1aa',
        policyIds: [cryptodinosPolicyId, babydinosPolicyId],
        name: 'CryptoDinos',
        assetName: 'CryptoDino',
        mode: 'buy-marketplace',
        features: [
            'Gen',
            'Species',
            'Body',
            'BodyType',
            'Spots',
            'Spikes',
            'Eyes',
            'Background',
            'Teeth',
            'Belly',
            'BellyButton',
            'HandsProp',
            'Footprints'
        ]
    },
    dinosaviors: {
        collectionId: '8a8206fb-bb11-4b0b-8e2f-65a00a6dddec',
        policyIds: [dinosaviorsPolicyId],
        name: 'DinoSaviors',
        assetName: 'DinoSavior',
        mode: 'buy-marketplace',
        features: [
            'type',
            'eyes',
            'patron',
            'typeHeritage',
            'leftHandProp',
            'rightHandProp',
            'bodyColor',
            'background',
            'headTop',
            'headMiddle',
            'headBottom',
            'backExtra',
            'bodyExtra',
            'forearmsExtra',
            'armsExtra',
            'chestExtra',
            'waistExtra'
        ]
    }
}

export type CollectionKeys = keyof typeof collections

export const getKeyByCollectionId = (collectionId: string) => (Object.keys(collections) as (CollectionKeys[])).find(key => collections[key].collectionId === collectionId);