import React from 'react'
import { ModalDialog } from '../components/ModalDialog'
import { OverlayContainer } from '@react-aria/overlays'
import { Asset, AssetItem } from '../Api'
import { CollectionKeys, collections } from '../collections'
import { AssetItemProps, Gallery, useAssetItems } from './Gallery'
import { useWalletAsset, useWalletAssets } from '../cardano/CardanoWalletProvider'
import { assetShortName, decomposeAssetName, IpfsImage, normalizedName } from './GalleryTile'
import './AssetDialog.scss'
import { Switch } from '../components/Switch'
import { NoItems } from './ExploreAccount'
import * as Api from '../Api'
import { WalletAsset } from '../cardano/CardanoWalletProvider'
import { addDays, differenceInDays } from 'date-fns'
import { ReactComponent as CooldownIcon } from './break-time-icon.svg'
import { ReactComponent as BreedingIcon } from '../breeding/breed_icon.svg'

type AssetDialogProps = {
    isOpen: boolean,
    onSelect: (selection: SelectedAsset) => void,
    onClose: () => void,
    exclude?: string[],
    generation?: string,
    mineOnly?: boolean
}

export type SelectedAsset = {
    data: AssetItem | Asset,
    token: WalletAsset | undefined,
    collection: CollectionKeys,
}

export const useCooldownPeriod = (value: Api.ComposedAsset) => React.useMemo(() => {
    const restTime = value.asset.name.startsWith(collections.cryptodinos.assetName) ? 14 : 7
    return Math.max(
        value.asset.mintingDate ? differenceInDays(addDays(new Date(value.asset.mintingDate), 28), new Date()) + 1 : 0,
        value.counter.lastBreedingDate ? differenceInDays(addDays(new Date(value.counter.lastBreedingDate), restTime), new Date()) + 1 : 0)
}, [value])

const AssetDialogTile = ({ value, collection, onSelect }: AssetItemProps & { onSelect: (selection: SelectedAsset) => void }) => {
    const token = useWalletAsset(value.asset.name)
    const cooldownPeriod = useCooldownPeriod(value)
    return (
        <div className={`asset-item ${cooldownPeriod > 0 ? 'faded' : ''}`} onClick={() => onSelect({ data: value.asset, collection, token })}>
            <div className="frame">
                <IpfsImage hash={value.asset.thumbprint.ipfsHash} name={value.asset.number.toString()} />
                <div className="bottom-badge">
                    <span title="Breeding count" style={{ marginRight: 10 }}><BreedingIcon style={{ width: 16, height: 16, marginRight: 5, fill: 'currentcolor' }} />{value.counter.counter}</span>
                    <span title="Remaining cooldown period"><CooldownIcon style={{ width: 16, height: 16, marginRight: 5, fill: 'currentcolor' }} />{cooldownPeriod > 0 ? `${cooldownPeriod}day(s)` : 'ready'}</span>
                </div>
            </div>
            <div className="name">{assetShortName(value.asset.name)}</div>
        </div>
    )
}

export const AssetDialog = ({ isOpen, onSelect, onClose, exclude = [], generation, mineOnly }: AssetDialogProps) => {
    const [collection, setCollection] = React.useState<CollectionKeys>('cryptodinos')
    const walletAssets = useWalletAssets(collections[collection].policyIds)
    const [mineOnlyValue, setMineOnly] = React.useState(true);
    const walletAssetNames = React.useMemo(() => {
        if (mineOnlyValue) {
            return walletAssets
                .filter(x => {
                    const { generation: assetGeneration } = decomposeAssetName(x.name)
                    return collections[collection].policyIds.some(pid => pid === x.policyId)
                        && exclude.indexOf(x.name) === -1
                        && (collection === 'dinosaviors' || generation === undefined || generation === assetGeneration)
                }).map(x => x.name)
        }
        else {
            return undefined
        }
    }, [walletAssets, mineOnlyValue, collection])
    const DialogTile = (props: AssetItemProps) => <AssetDialogTile {...props} onSelect={onSelect} />
    const onCollectionSwitch = React.useCallback((collection: CollectionKeys) => {
        const element = document.getElementById('asset-dialog-gallery');
        element?.scrollTo(0, 0)
        setCollection(collection)
    }, [])

    const onFindByNumberKeyPress = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value
        if (event.key === 'Enter' && /^(\d+-)?\d{1,5}$/.test(value)) {
            const name = collection === 'cryptodinos' ? normalizedName(value) : `DinoSavior${value.padStart(5,'0')}`
            const token = walletAssets.find(t => t.name === name && collections[collection].policyIds.indexOf(t.policyId) !== -1)
            Api.getAsset(collections[collection].collectionId, name).then(result => onSelect({ data: { ...result, isMinted: true }, token, collection }))
        }
    }, [onSelect, collection]);
    const filter = React.useMemo(() => ({ Gen: generation }), [generation])
    const data = useAssetItems(collection, { filter: collection === 'cryptodinos' ? filter : undefined, assetNames: walletAssetNames })

    return (
        <OverlayContainer>
            <ModalDialog isOpen={true} onClose={onClose} isDismissable={true} zIndex={200}>
                <div className="asset-dialog">
                    <h1>Select asset</h1>
                    <nav>
                        {collection === 'cryptodinos' && (<div className="switch-collection" onClick={() => onCollectionSwitch('dinosaviors')}>
                            <span className="dinosaviors-icon"></span>
                            <span>Switch to DinoSaviors</span>
                        </div>)}
                        {collection === 'dinosaviors' && (<div className="switch-collection" onClick={() => onCollectionSwitch('cryptodinos')}>
                            <span className="cryptodinos-icon"></span>
                            <span>Switch to CryptoDinos</span>
                        </div>)}
                        <div>
                            <input placeholder="Find by number" onKeyPress={onFindByNumberKeyPress} />
                            {mineOnly || <Switch isSelected={mineOnlyValue} onChange={setMineOnly}>Only mine</Switch>}
                        </div>
                    </nav>
                    <div className="gallery" id="asset-dialog-gallery">
                        <Gallery
                            collection={collection}
                            data={data}
                            component={DialogTile}
                            scrollableTarget="asset-dialog-gallery"
                            noItemsComponent={<NoItems collection={collection} />}
                        />
                    </div>
                </div>
            </ModalDialog>
        </OverlayContainer>
    )
}