import React from 'react'
import { CardanoApi, CardanoWallet, hasCardano } from '../nami'
import { cardanoAdapter, cardanoApiAdapter } from './cardano-adapter'
import { useNotifications } from '../components/NotificationProvider'
import { getTransaction } from './plutus-helpers'
import { usePolling } from '../usePolling'
import { BrowserWallet } from '@meshsdk/core'
import { useWallet } from '@meshsdk/react'

type AssetState = 'completed' | 'pending'

export type WalletAsset = {
    policyId: string,
    name: string,
}

type CardanoWalletContextProps = {
    pendingTxs: PendingTransaction[],
    walletAssets: WalletAsset[],
    walletAddress: string | undefined,
    connected: boolean,
    connect: (walletName: string) => Promise<void>,
    getCardano(): BrowserWallet,
    addAsset: (asset: WalletAsset, awaitingTxHash?: string) => Promise<void>,
    removeAsset: (asset: WalletAsset, awaitingTxHash?: string) => Promise<void>,
    wallets: CardanoWallet[]
}

export const CardanoWalletContext = React.createContext<CardanoWalletContextProps | undefined>(undefined)

type CardanoWalletProviderProps = {
    policyIds: string[],
}

type PendingTransaction = {
    assetName: string,
    hash: string,
    callback: () => any
}

export const CardanoWalletProvider: React.FC<CardanoWalletProviderProps> = ({ policyIds, children }) => {

    const { addWarn } = useNotifications()
    const api = React.useRef<BrowserWallet>()
    const [walletAssets, setWalletAssets] = React.useState<WalletAsset[]>([])
    const [walletAddress, setWalletAddress] = React.useState<string | undefined>(undefined)
    const [pendingTxs, setPendingTxs] = React.useState<PendingTransaction[]>([])
    const [refreshWallets, setRefreshWallets] = React.useState(false)

    const { wallet, connect, connected } = useWallet()

    const wallets = React.useMemo(() => {
        if (hasCardano(window)) {
            const cardano = cardanoAdapter(window.cardano)
            return cardano.getWallets();
        } else {
            return []
        }
    }, [refreshWallets])

    React.useEffect(() => { setTimeout(() => setRefreshWallets(true), 5000) }, [])

    const getCardano = React.useCallback(() => {
        return wallet
    }, [wallet])

    const addAsset = React.useCallback((asset: WalletAsset, awaitingTxHash?: string) => {
        return new Promise<void>((resolve) => {
            setWalletAssets([...walletAssets, asset])
            if (awaitingTxHash) {
                setPendingTxs([...pendingTxs, { assetName: asset.name, hash: awaitingTxHash, callback: resolve }])
            }
        });
    }, [walletAssets, pendingTxs])

    const removeAsset = React.useCallback((asset: WalletAsset, awaitingTxHash?: string) => {
        return new Promise<void>((resolve) => {
            setWalletAssets(walletAssets.filter(walletAsset => !(walletAsset.policyId === asset.policyId && walletAsset.name === asset.name)))
            if (awaitingTxHash) {
                setPendingTxs([...pendingTxs, { assetName: asset.name, hash: awaitingTxHash, callback: resolve }])
            }
        })
    }, [walletAssets, pendingTxs])

    React.useEffect(() => {
        const walletName = localStorage.getItem('wallet') || undefined
        if (walletName) {
            setTimeout(() => {
                if (hasCardano(window)) {
                    const wallet = cardanoAdapter(window.cardano).getWallets().find(w => w.name === walletName)
                    if (wallet) {
                        wallet.isEnabled().then(enabled => {
                            enabled && connect(walletName)
                        })
                    }
                }
            }, 0)
        }
    }, [connect])

    React.useEffect(() => {
        if (connected) {
            cardanoApiAdapter(getCardano()).getAssets(policyIds).then((assets) => {
                setWalletAssets(assets.map(asset => ({
                    policyId: asset.policyId,
                    number: asset.number,
                    state: 'completed',
                    name: asset.assetName,
                })))
            })
            cardanoApiAdapter(getCardano()).getWalletAddress().then(address => setWalletAddress(address.to_bech32()))
        }
    }, [connected])

    const pollingCallback = React.useCallback(async () => {
        const foundTxs = (await Promise.all(pendingTxs.map(async (pendingTx) => ([pendingTx, Boolean(await getTransaction(wallet, pendingTx.hash))]))))
            .filter(([, found]) => found)
            .map(([value]) => value as PendingTransaction)

        foundTxs.forEach(tx => tx.callback());
        setPendingTxs(pendingTxs.filter(ptx => !foundTxs.some(tx => ptx.hash === ptx.hash)))
    }, [walletAssets, pendingTxs])

    usePolling(pollingCallback, 30_000, pendingTxs.length > 0)

    const context = {
        pendingTxs,
        walletAssets,
        walletAddress,
        connected,
        connect,
        getCardano,
        addAsset,
        removeAsset,
        wallets,
    }

    return <CardanoWalletContext.Provider value={context}>{children}</CardanoWalletContext.Provider>
}

export const useWallets = (): CardanoWallet[] => {
    const { wallets } = useCardanoWalletProvider()
    return wallets
}

export const useCardanoWalletProvider = (): CardanoWalletContextProps => {
    const context = React.useContext<CardanoWalletContextProps | undefined>(CardanoWalletContext)
    if (!context) {
        throw Error('Cannot access SaleTokenContext out of scope')
    }

    return context
}

export const useWalletAssets = (policyIds: string[]): WalletAsset[] => {
    const { walletAssets } = useCardanoWalletProvider()
    return React.useMemo(() => {
        return walletAssets.filter(x => policyIds.some(pid => pid === x.policyId))
    }, [walletAssets, policyIds])
}

export const useWalletAsset = (name: string | undefined): WalletAsset | undefined => {
    const { walletAssets } = useCardanoWalletProvider()
    return React.useMemo(() => {
        return name ? walletAssets.find(x => x.name === name) : undefined
    }, [name, walletAssets])
}

export const useCoinsBalance = (policyId: string): number | undefined => {
    const { getCardano, connected } = useCardanoWalletProvider()
    const [balance, setBalance] = React.useState(0)
    React.useEffect(() => {
        if (connected) {
            var adapter = cardanoApiAdapter(getCardano())
            var coinBalance = adapter.getCoinBalanace(policyId)
            coinBalance.then(value => 
                {
                    setBalance(parseInt(value))
                })
        }
    }, [getCardano, connected])
    return balance
}

export const useWalletAssetState = (policyId: string, assetName: string) => {
    const { pendingTxs } = useCardanoWalletProvider()
    const asset = useWalletAsset(assetName)
    return React.useMemo(() => {
        return asset && (pendingTxs.some(x => x.assetName === assetName) ? 'pending' : 'completed')
    }, [asset, assetName])
}