import React from 'react'

export enum NotificationType {
  Info,
  Warning,
  Error,
}

export type NotificationContextProps = {
  addNotification: (message: string, type: NotificationType) => void,
}

export const NotificationContext = React.createContext<NotificationContextProps | undefined>(undefined)

export type NotificationItem = {
  message: string,
  type: NotificationType,
}

type NotificationsProps = {
  addNotificationRef: React.MutableRefObject<((notification: NotificationItem) => void) | undefined>,
  component: any,
}

const Notifications = ({ addNotificationRef, component: Component }: NotificationsProps) => {
  const [notifications, setNotifications] = React.useState<NotificationItem[]>([])
  addNotificationRef.current = (notification) => {
    setNotifications([...notifications, notification])
  }
  const dismiss = (notification: NotificationItem) => {
    setNotifications(notifications.filter((x) => x !== notification))
  }

  return <Component notifications={notifications} dismiss={dismiss} />
}

export type NotificationProviderProps = {
  component: any,
}

export const NotificationProvider: React.FC<NotificationProviderProps> = ({
  children,
  component,
}) => {
  const addNotificationRef = React.useRef<((notification: NotificationItem) => void) | undefined>(undefined)
  const value: NotificationContextProps = {
    addNotification: (message: string, type: NotificationType) => {
      if (addNotificationRef.current) {
        addNotificationRef.current({ message, type })
      }
    },
  }

  return (
    <NotificationContext.Provider value={value}>
      {children}
      <Notifications addNotificationRef={addNotificationRef} component={component} />
    </NotificationContext.Provider>
  )
}

export type Notifications = {
    addInfo: (message: string) => void,
    addWarn: (message: string) => void,
    addError: (message: string) => void,
}

export const useNotifications = (): Notifications => {
    const context = React.useContext<NotificationContextProps | undefined>(NotificationContext)
    if (!context) {
        throw Error('You cannot call useNotification outside of NotificationContext scope')
    }

    return {
        addInfo: React.useCallback((message: string) => context.addNotification(message, NotificationType.Info), [context]),
        addWarn: React.useCallback((message: string) => context.addNotification(message, NotificationType.Warning), [context]),
        addError: React.useCallback((message: string) => context.addNotification(message, NotificationType.Error), [context]),
    }
}