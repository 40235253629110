import { toHex, toByteArray, } from '../cardano/plutus-helpers'
import * as ConfigProvider from '../configProvider'
import { appInsights } from '../AppInsights' 
import { BrowserWallet, Transaction } from '@meshsdk/core'
import { cardanoApiAdapter } from '../cardano/cardano-adapter';

const denominationTransferFixed = "2000000";

export const canDenominateDinoCoins = async (wallet: BrowserWallet): Promise<Boolean> => {
    const dinoCoinPolicyIdV1 = ConfigProvider.getDinoCoinPolicyId()
    var cardano = cardanoApiAdapter(wallet)
    const dinoCoinsBalance = await cardano.getCoinBalanace(dinoCoinPolicyIdV1)
    if (dinoCoinsBalance) {
        return parseInt(dinoCoinsBalance) > 0
    } else {
        return false;
    }
}

export const denominateDinoCoins = async (wallet: BrowserWallet): Promise<string> => {
    const selfAddress = await wallet.getUsedAddress()
    const denominationAddress = ConfigProvider.getDinoCoinDenominationAddress();
    const dinoCoinPolicyIdV1 = ConfigProvider.getDinoCoinPolicyId()
    var cardano = cardanoApiAdapter(wallet)
    const dinoCoinsBalance = await cardano.getCoinBalanace(dinoCoinPolicyIdV1)

    const tx = new Transaction({ initiator: wallet })
        .sendAssets(
            denominationAddress,
            [
              {
                unit: 'lovelace',
                quantity: denominationTransferFixed.toString(),
              },
              {
                unit: `${dinoCoinPolicyIdV1}${toHex(toByteArray(ConfigProvider.getDinoCoinName()))}`,
                quantity: dinoCoinsBalance,
              },
            ]
          );
    const meta : DenominateDinoCoinsMetadata = { 
        lvlAmount: denominationTransferFixed,
        dcAmount: dinoCoinsBalance,
        op: "denominate"
    }

    tx.setMetadata(505, meta);

    try {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`txHash for denominate DinoCoin: ${txHash} DC amount: ${dinoCoinsBalance}`);
        appInsights.trackTrace({ message: 'denominate' }, {
            owner: selfAddress.to_bech32(),
            wallet: localStorage.getItem('wallet'),
            txHash: txHash,
            dcAmount: dinoCoinsBalance,
            method: "denominate"
        });
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            owner: selfAddress.to_bech32(),
            dcAmount: dinoCoinsBalance,
            method: "denominate"
        });
        throw err;
    }
}

export interface DenominateDinoCoinsMetadata {
    lvlAmount: string,
    dcAmount: string,
    op: string
}