import React from 'react'
import { hasCardano } from '../nami'
import { BuyButton } from '../sales/BuyDialog'
import { MoreButton } from './MenuMore'
import { Link } from 'react-router-dom'
import { cardanoAdapter } from '../cardano/cardano-adapter'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { ModalDialog } from '../components/ModalDialog'
import { OverlayContainer } from '@react-aria/overlays'
import { useCardanoWalletProvider, useWallets } from '../cardano/CardanoWalletProvider'

export type ProjectMode = 'buy' | 'buy-marketplace'

const PhaseButon = ({ mode, collectionId, collection }: { mode: ProjectMode, collectionId: string, collection: string }) => {
    switch (mode) {
        case 'buy':
            return <BuyButton collectionId={collectionId} />
        case 'buy-marketplace':
            return <Link className="action-button" to={`/explore/${collection}?onSale=true&order=offer.price-asc`}>buy</Link>
    }
}

export const ConnectButton = ({ children = 'Connect', ...props }: any) => {

    const { connect } = useCardanoWalletProvider()
    const providers = useWallets()

    const state = useOverlayTriggerState({})
    const onConnectClick = React.useCallback(() => {
        if (providers.length > 1) {
            state.open()
        } else {
            const walletName = providers[0]
            connect(walletName.name)
        }
    }, [providers, connect])


    return (
        <React.Fragment>
            <button {...props} onClick={onConnectClick}>{children}</button>
            {state.isOpen && (
                <OverlayContainer>
                    <ModalDialog collection="cryptodinos" isOpen={true} onClose={state.close} isDismissable={true}>
                        <div className="cryptodinos connect-dialog">
                            <h1>
                                Choose wallet
                            </h1>
                            <div>
                                {providers.map((provider) => (
                                    <div className="wallet-item" key={provider.name}>
                                        <img src={provider.icon} alt="Wallet icon" width="32" height="32" />
                                        <span>{provider.name}</span>
                                        <button onClick={() => connect(provider.name)}>Connect</button>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </ModalDialog>
                </OverlayContainer>
            )}
        </React.Fragment>
    )
}

export const WalletConnectAccountButton = () => {
    const { connected } = useCardanoWalletProvider()

    if (connected) {
        return (<Link to="/account">Account</Link>)
    } else {
        return (<ConnectButton />)
    }
}


type MenuProps = {
    mode: ProjectMode | undefined,
    collection: string,
    collectionId?: string
}

export const Menu = ({ mode, collection, collectionId }: MenuProps) => {


    return (
        <div className="menu">
            <ul>
                <li><Link to={`/account`}>explore</Link></li>
                <li>
                    <MoreButton />
                </li>
                <li>
                    <WalletConnectAccountButton />
                </li>
            </ul>
            {mode && <PhaseButon mode={mode} collectionId={collectionId || ''} collection={collection || 'cryptodinos'} />}
        </div>
    )
}