import React, { CSSProperties } from 'react'
import { useButton } from '@react-aria/button'
import { ReactComponent as Spinner } from './Spinner.svg'
import './Button.scss'

function isPromise<T>(value: T | Promise<T>): value is Promise<T> {
    return value && typeof (value as any).then === 'function'
}

type ButtonProps = {
    disabled?: boolean,
    style?: CSSProperties | undefined,
    onPress: () => (void | Promise<void>)
}

export const Button: React.FC<ButtonProps> = ({ onPress, children, style, disabled = false }) => {
    const [busy, setBusy] = React.useState(false)
    const ref = React.useRef<HTMLButtonElement>()
    const { buttonProps } = useButton({
        isDisabled: busy || disabled,
        onPress: () => {
            const result = onPress()
            if (isPromise(result)) {
                setBusy(true)
                result.then(() => setBusy(false))
            }
        }
    }, ref as any)

    return (
        <button className="button" {...buttonProps} ref={ref} style={style}>
            {busy && <Spinner className="spinner" />}
            {children}
        </button>
    )
}