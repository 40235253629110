import React from 'react'
import { ModalDialog } from '../components/ModalDialog'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { OverlayContainer } from '@react-aria/overlays'
import { useButton } from '@react-aria/button'
import { Button } from '../components/Button'
import * as Api from '../Api'
import { claimRewards } from './rewardsClaimingManager'
import * as configProvider from '../configProvider'
import { useCardanoWalletProvider } from '../cardano/CardanoWalletProvider'
import './ClaimRewardDialog.scss'
import { CancelError } from '../cardano/cardano-adapter'
import { useNotifications } from '../components/NotificationProvider'
import { appInsights } from '../AppInsights'
import { canTopUpGameAccount, topUpGameAccount } from './topUpDinoManager'

export const ClaimRewardButton = ({ walletAddress, accountIds, max, onComplete }: { walletAddress: string, accountIds: string[], max: number, onComplete?: () => any }) => {

    const maxDino = React.useMemo(() => Math.floor(max / 1_000_000), [max])
    const state = useOverlayTriggerState({})
    const openButtonRef = React.useRef(null)
    const closeButtonRef = React.useRef(null)
    const { getCardano } = useCardanoWalletProvider()
    const { addWarn } = useNotifications()

    const handleError = React.useCallback((err: any) => {
        if (err instanceof CancelError) {
            return
        } else if (typeof err === 'string') {
            addWarn(err)
            appInsights.trackTrace({ message: err })
        } else {
            addWarn(err.info || err.message)
            appInsights.trackException({ exception: err })
        }
    }, [addWarn])

    const { buttonProps: openButotnProps } = useButton({
        onPress: () => state.open()
    }, openButtonRef)

    const [isValid, setIsValid] = React.useState(true)
    const [amount, setAmount] = React.useState<number>(maxDino)
    const onAmountChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (value > parseInt(event.target.max) || value < 1) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }

        if (!isNaN(value)) {
            setAmount(value)
        } else if (event.target.value === '') {
            setAmount(0)
        }
    }, [setAmount]);


    const onWidthdrawClick = React.useCallback(async () => {
        const canWithdraw = configProvider.isRewardsClaimingEnabled()
        if (canWithdraw) {
            try {
                const txids = await Api.claimReward(walletAddress, accountIds, amount * 1_000_000)
                try {
                    const cardanoApi = getCardano()
                    await claimRewards(cardanoApi, txids);
                } catch (err) {
                    await Api.cancelReward(walletAddress, txids)
                    handleError(err)
                }
            } catch (err) {
                handleError(err)
            }
            state.close();
            onComplete?.();
        } else {
            alert('Rewards withdraws are disabled');
        }
    }, [amount, state, walletAddress, accountIds, getCardano])

    return (
        <>
            <button className="button" {...openButotnProps} ref={openButtonRef}>
                Claim reward
            </button>
            {state.isOpen && (
                <OverlayContainer>
                    <ModalDialog collection="cryptodinos" isOpen={true} onClose={state.close} isDismissable={true}>
                        <h1>
                            Claim reward
                        </h1>
                        <div className="dialog-content claim-reward-dialog">
                            <p>Enter amount to withdraw</p>
                            <div>
                                <input className={isValid ? '' : 'error'} value={amount} max={maxDino} min={0} onChange={onAmountChange} />
                                {!isValid && <p>Invalid amount</p>}
                            </div>
                            <div className="actions">
                                <Button disabled={!isValid} onPress={onWidthdrawClick}>Withdraw</Button>
                            </div>
                        </div>
                    </ModalDialog>
                </OverlayContainer>
            )
            }
        </>
    )
}

export const TopUpButton = ({ onComplete }: { onComplete?: () => any }) => {

    const state = useOverlayTriggerState({})
    const openButtonRef = React.useRef(null)
    const closeButtonRef = React.useRef(null)
    const { getCardano } = useCardanoWalletProvider()
    const { addWarn } = useNotifications()

    const handleError = React.useCallback((err: any) => {
        if (err instanceof CancelError) {
            return
        } else if (typeof err === 'string') {
            addWarn(err)
            appInsights.trackTrace({ message: err })
        } else {
            addWarn(err.info || err.message)
            appInsights.trackException({ exception: err })
        }
    }, [addWarn])

    const { buttonProps: openButotnProps } = useButton({
        onPress: () => state.open()
    }, openButtonRef)

    const [isValid, setIsValid] = React.useState(true)
    const [amount, setAmount] = React.useState<number>(1000)
    const onAmountChange = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        const value = parseInt(event.target.value);
        if (value > parseInt(event.target.max) || value < 1) {
            setIsValid(false)
        } else {
            setIsValid(true)
        }

        if (!isNaN(value)) {
            setAmount(value)
        } else if (event.target.value === '') {
            setAmount(0)
        }
    }, [setAmount]);


    const onTopUpClick = React.useCallback(async () => {
            try {
                const canDenominate = await canTopUpGameAccount(getCardano())
                if (canDenominate) {
                    const txHash = await topUpGameAccount(getCardano(), amount * 1_000_000)
                    console.log(`topup game account tx hash: ${txHash}`)
                } else {
                    addWarn("You don't have any DINO to be sent to your game account")
                }
            } catch (err) {
                handleError(err)
            }
            state.close();
            onComplete?.();
    }, [amount, state, getCardano])

    return (
        <>
            <button className="button" {...openButotnProps} ref={openButtonRef}>
                Top up account
            </button>
            {state.isOpen && (
                <OverlayContainer>
                    <ModalDialog collection="cryptodinos" isOpen={true} onClose={state.close} isDismissable={true}>
                        <h1>
                            Top up account
                        </h1>
                        <div className="dialog-content claim-reward-dialog">
                            <p>Enter amount to top up</p>
                            <div>
                                <input className={isValid ? '' : 'error'} value={amount} min={0} onChange={onAmountChange} />
                                {!isValid && <p>Invalid amount</p>}
                            </div>
                            <div className="actions">
                                <Button disabled={!isValid} onPress={onTopUpClick}>Top up</Button>
                            </div>
                        </div>
                    </ModalDialog>
                </OverlayContainer>
            )
            }
        </>
    )
}