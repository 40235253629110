import React from 'react'
import './Explore.scss'
import { Link, useParams } from 'react-router-dom'
import { collections, CollectionKeys, getPolicyIdByName } from '../collections'
import * as Api from '../Api'
import { useFeature } from './FeatureContext'
import { PageTemplate } from '../components/PageTemplate'
import { format } from '../components/camelCase'
import { UpdateButton } from '../marketplace/OfferDialog'
import { assetNumber, useOffer, useOfferOperations, useSaleTokenContext } from '../marketplace/SaleTokenProvider'
import { Button } from '../components/Button'
import { ReactComponent as Spinner } from '../components/Spinner.svg'
import { assetLinkName, assetShortName, IpfsImage } from './GalleryTile'
import { useData } from '../components/useData'
import DinoCoinSrc from '../breeding/DinoCoin_78x78.png'
import { BreedingCounter } from '../breeding/Breeding'
import { toByteArray, toHex } from '../cardano/plutus-helpers'

const normalizeNumber = (number: string) => {
    if (number.startsWith('0-')) {
        return number.substr(2);
    } else {
        return '-' + number;
    }
}

export const useAsset = (collection: CollectionKeys, number: string) => {
    const callback = React.useCallback(() => Api.getAsset(collections[collection].collectionId, `${collections[collection].assetName}${normalizeNumber(number)}`), [collection, number])
    return useData(callback)
}

export const useAssetById = (assetId: string) => {
    const callback = React.useCallback(() => Api.getAssetById(assetId), [assetId])
    return useData(callback)
}

export const useAssetDinoCoinsState = (assetId: string | undefined) => {
    const callback = React.useCallback(() => assetId ? Api.getAssetDinoCoinsState(assetId) : Promise.resolve(undefined), [assetId])
    return useData(callback)
}

export const useAssetProgress = (assetId: string | undefined) => {
    const callback = React.useCallback(() => assetId ? Api.getAssetProgress(assetId) : Promise.resolve(undefined), [assetId])
    return useData(callback)
}

const Feature = ({ name, value, uniqueness }: any) => {
    if (!value) {
        return <React.Fragment />
    }

    return (
        <div className="feature">
            <div>
                <div>{name}:</div>
                <div>{value.toLowerCase()}</div>
            </div>
            <UniquenessStatus value={uniqueness} />
        </div>
    )
}

export const UniquenessStatus = ({ value }: any) => {
    let rarity = '';
    if (value) {
        if (value >= 0.25) {
            rarity = 'common'
        } else if (value >= 0.07) {
            rarity = 'uncommon'
        } else if (value >= 0.03) {
            rarity = 'rare'
        } else if (value >= 0.01) {
            rarity = 'super rare'
        } else {
            rarity = 'exceptional'
        }
    }

    const percentage = (value * 100).toLocaleString(undefined, { minimumFractionDigits: 2 }) + '%'
    const intPercentage = (value * 100).toLocaleString('en-us', { minimumFractionDigits: 0 }) + '%'

    return (
        <div className="uniqueness">
            <div style={{ width: intPercentage }} className={rarity.replace(' ', '-')} />
            <div>{percentage} - {rarity}</div>
        </div>
    )
}

const useAssetFingerprint = (assetName: string) => React.useMemo(() => {
    return getPolicyIdByName(assetName) + toHex(toByteArray(assetName))
}, [assetName])

export const AssetMarketplaceActions = ({ assetName, policyId, collection }: { assetName: string, policyId: string, collection: CollectionKeys }) => {
    const { buy, cancel } = useSaleTokenContext()
    const offer = useOffer(policyId, assetNumber(assetName))
    const { canBuy, canOffer, canUpdateOrCancel, isMine, state } = useOfferOperations(policyId, assetName)

    const assetFingerprint = useAssetFingerprint(assetName)

    const buyToken = React.useCallback(() => {
        return buy(policyId, assetName)
    }, [assetName, policyId, buy])

    const cancelOffer = React.useCallback(() => {
        return cancel(policyId, assetName)
    }, [assetName, policyId, cancel])

    return (
        <div>
            {(isMine) && (
                <div>
                    <div className="address">
                        You are the owner
                    </div>
                </div>
            )}
            {state === 'pending' ? (
                <div>
                    <div><Spinner className="spinner" /></div>
                    <div>Your request is waiting for acceptance on blockchain. It usually takes a few minutes.</div>
                </div>
            ) : (
                <React.Fragment>
                    {offer?.askingPrice && <div className="price-tag">Now on sale for {lovelaceToAda(offer?.askingPrice)} ADA</div>}
                    {collection === 'cryptodinos' && <Link className="button" to={`/explore/cryptodinos/${assetLinkName(assetName)}/breed`}>Breed</Link>}
                    <a className="button" href={`https://www.jpg.store/asset/${assetFingerprint}`} target="_blank">Go to jpg.store</a>
                    {canBuy && <Button onPress={buyToken}>Buy</Button>}
                    {canUpdateOrCancel && <UpdateButton collection={collection} policyId={policyId} assetName={assetName} askingPrice={parseInt(offer?.askingPrice || '60000000') / 1_000_000} />}
                    {canUpdateOrCancel && <Button onPress={cancelOffer}>Cancel</Button>}
                    {(canBuy || canOffer || canUpdateOrCancel) && <div style={{ marginTop: 10, fontSize: '0.75rem' }}>Service fee: 2% (no less than 1ADA)</div>}
                </React.Fragment>
            )}
        </div>
    )
}

export const GameLevel = ({ assetId }: { assetId: string }) => {
    const { data } = useAssetProgress(assetId)
    if (!data) {
        return (
            <div>
                No game progress
            </div>
        )
    }

    return (
        <div className="game-level">
            <div>Level: <strong>{data.level}</strong></div>
            <div>Experience: <strong>{data?.experience}</strong></div>
        </div>
    )
}

export const GalleryAsset = () => {
    const { number, collection } = useParams<{ collection: CollectionKeys, number: string }>()

    const { data: asset } = useAsset(collection, number)
    const { data: dinoCoins } = useAssetDinoCoinsState(asset?.assetId)
    const features = useFeature(collections[collection].collectionId)

    const featureOrder = React.useCallback(([a], [b]) => {
        const { features } = collections[collection]
        return features.indexOf(a) - features.indexOf(b)
    }, [collection])

    return (
        <div className="explore">
            {asset && (
                <PageTemplate title={`${collections[collection].assetName} ${assetShortName(asset.name)}`}>
                    <article className="asset">
                        <div>
                            <IpfsImage hash={asset.asset.ipfsHash} name={asset.name} />
                            <AssetMarketplaceActions assetName={asset.name} policyId={collections[collection].policyIds[0]} collection={collection} />
                        </div>
                        <div>
                            <h3>Rank {asset.rank}</h3>
                            <div className="asset-params">
                                {dinoCoins &&
                                    (dinoCoins.wasClaimed ? (<>DinoCoins already claimed</>) : (
                                        <div className="dinocoins-amount">
                                            <span>DinoCoins to claim: {dinoCoins.amount}</span>
                                            <img src={DinoCoinSrc} alt="DinoCoins" style={{ width: '24px', height: '24px', display: 'inline-block' }} />
                                        </div>)
                                    )}
                                <BreedingCounter asset={asset} collection={collection} />
                                <GameLevel assetId={asset.assetId} />
                            </div>
                            {Object.entries(asset.data).sort(featureOrder).map(([key, value]: [string, any]) => (
                                <Feature key={key} name={format(key)} value={value} uniqueness={features?.[key]?.[value] / 10000} />
                            ))}
                        </div>
                    </article>
                </PageTemplate>
            )}
        </div>
    )
}

const lovelaceToAda = (lovelace: string) => (parseInt(lovelace) / 1_000_000).toFixed(2)