import { CardanoApi } from '../nami'
import { CoinSelection, setCardanoSerializationLib } from './coinSelection'
import {
    PlutusList,
    PlutusData,
    BigNum,
    BigInt,
    ConstrPlutusData,
    TransactionBuilder,
    LinearFee,
    TransactionUnspentOutput,
    TransactionInput,
    TransactionHash,
    TransactionOutput,
    Value,
    MultiAsset,
    Assets,
    AssetName,
    ScriptHash,
    TransactionOutputs,
    Transaction,
    Address,
    BaseAddress,
    min_ada_required,
    TransactionBuilderConfigBuilder,
    UnitInterval
} from '@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib'
import * as CardanoSerialization from '@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib'
import { getAssetUtxos, getUtxoMetadata } from './blockfrost'
import { cardanoApiAdapter } from './cardano-adapter'
import { BrowserWallet } from '@meshsdk/core'

export const toHex = (bytes: Uint8Array) => Buffer.from(bytes).toString("hex");
export const toAssetName = (bytes: Uint8Array) => Buffer.from(bytes).toString();
export const fromHex = (hex: string) => Buffer.from(hex, "hex");
export const toByteArray = (name: string) => Buffer.from(name, "utf8");
export const toBigNum = (value: any) => {
    return BigNum.from_str(value.toString()) as BigNum;
}
export const splitAddress = (address: string) => {
    const maxSize = 64;
    const size = Math.round(address.length / maxSize)
    const results: string[] = []
    for (var i = 0; i < size; i++) {
        let end = (i + 1) * maxSize > address.length ? address.length : (i + 1) * maxSize
        results.push(address.slice(i * maxSize, end))
    }

    return results
}

export const getPublicKeyHash = (addressParts: string[]) => {
    const addressInString = addressParts.join("");
    const address = Address.from_bech32(addressInString)
    const baseAddress = BaseAddress.from_address(address) as BaseAddress;
    const pkh = toHex(baseAddress.payment_cred().to_keyhash()?.to_bytes() as Uint8Array);
    return pkh;
}

export const toPlutusData = (plutusDataObj: PlutusDataObject) => {
    const datumFields = PlutusList.new();
    plutusDataObj.fields.sort((a, b) => a.index - b.index);
    plutusDataObj.fields.forEach(f => {
        switch (f.type) {
            case PlutusFieldType.Integer:
                datumFields.add(PlutusData.new_integer(BigInt.from_str(f.value.toString())));
                break;
            case PlutusFieldType.Data:
                datumFields.add(toPlutusData(f.value));
                break;
            case PlutusFieldType.Bytes:
                datumFields.add(PlutusData.new_bytes(f.value));
                break;
        }
    })

    return PlutusData.new_constr_plutus_data(
        ConstrPlutusData.new(
            BigNum.from_str(plutusDataObj.constructorIndex.toString()),
            datumFields
        )
    );
}

export const extractUnitPrices = (val: number) => {
    const afterComma = val.toString().split('.')[1]
    const denominatorPower = afterComma.length
    const numerator = afterComma
    return UnitInterval.new(BigNum.from_str(numerator), BigNum.from_str(Math.pow(10, denominatorPower).toString()))
}

export const createTransactionBuilder = async (protocolParams: CardanoProtocolParameters, exUnitPrices: CardanoSerialization.ExUnitPrices | undefined = undefined) => {
    let configBuilder = TransactionBuilderConfigBuilder.new()
            .fee_algo(LinearFee.new(BigNum.from_str(protocolParams.min_fee_a.toString()), BigNum.from_str(protocolParams.min_fee_b.toString())))
            .pool_deposit(BigNum.from_str(protocolParams.pool_deposit.toString()))
            .key_deposit(BigNum.from_str(protocolParams.key_deposit.toString()))
            .coins_per_utxo_byte(BigNum.from_str(protocolParams.coins_per_utxo_word))
            .max_value_size(protocolParams.max_val_size)
            .max_tx_size(protocolParams.max_tx_size)
            .prefer_pure_change(true)
    if (exUnitPrices) {
        configBuilder = configBuilder.ex_unit_prices(exUnitPrices)
    }

    const txBuilder = TransactionBuilder.new(
        configBuilder.build()
    );

    return txBuilder
}

export const addInputs = (txBuilder: TransactionBuilder, utxos: TransactionUnspentOutput[], scriptUtxo: TransactionUnspentOutput, transactionOutputs: TransactionOutputs) => {
    const csResult = CoinSelection.randomImprove(
        utxos,
        transactionOutputs,
        8,
        [scriptUtxo]
    );

    csResult.inputs.forEach((utxo) => {
        txBuilder.add_input(
            utxo.output().address(),
            utxo.input(),
            utxo.output().amount()
        );
    });
    return csResult;
}

export type PlutusDataObject = {
    constructorIndex: number,
    fields: PlutusField[],
}

export enum PlutusFieldType {
    Data = 0,
    Integer = 1,
    String = 2,
    Bytes = 3
}

export type PlutusField = {
    index: number,
    type: PlutusFieldType,
    key: string,
    value: any
}

export type CardanoProtocolParameters = {
    min_fee_a: number,
    min_fee_b: number,
    min_utxo: number,
    pool_deposit: number,
    key_deposit: number,
    max_tx_size: number,
    max_val_size: number,
    price_mem: number,
    price_step: number,
    coins_per_utxo_word: string
}

export const logTx = (tx: Transaction) => {
    console.log("Full Tx Size", tx.to_bytes().length);
    //console.log(toHex(tx.to_bytes()))
}

export const logTxDetails = (tx: Transaction) => {
    console.log('Inputs')
    for (let i = 0; i < tx.body().inputs().len(); i++) {
        console.log('%o: %o', tx.body().inputs().get(i).index(), toHex(tx.body().inputs().get(i).transaction_id().to_bytes()))
    }
    console.log('Outputs')
    for (let i = 0; i < tx.body().outputs().len(); i++) {
        console.log('%o: %o/%o ADA', i, tx.body().outputs().get(i).address().to_bech32(), tx.body().outputs().get(i).amount().coin().to_str())
        if (tx.body().outputs().get(i).data_hash()) {
            toHex(tx.body().outputs().get(i).data_hash()?.to_bytes() as Uint8Array)
        }
        const multiAssets = tx.body().outputs().get(i).amount().multiasset();
        if (multiAssets) {
            var keyHashesLength = multiAssets.keys().len()
            for (var j = 0; j < keyHashesLength; j++)
            {
                var kh = multiAssets.keys().get(j);
                var ma = multiAssets.get(kh);
                var keyLength = ma?.keys().len()!;
                for (var z = 0; z < keyLength; z++){
                    var assetName = ma?.keys().get(z)
                    console.log(toHex(assetName?.name() as Uint8Array))
                }
            }
        }
    }
}

export const setupCoinSelector = async (protocolParameters: CardanoProtocolParameters) => {
    setCardanoSerializationLib(CardanoSerialization)
   
    CoinSelection.setProtocolParameters(
        protocolParameters.min_utxo.toString(),
        protocolParameters.min_fee_a.toString(),
        protocolParameters.min_fee_b.toString(),
        protocolParameters.max_tx_size.toString()
    )
}

export const getUnspentTransactionUtxoForNft = async (contractAddress: Address, policyId: string, assetName: string) => {
    const utxos = await getAssetUtxos(assetName, policyId, contractAddress.to_bech32());
    if (utxos.length === 0) {
        throw Error(`Could not find UTXO for ${assetName}`);
    }

    var details = utxos[0];
    var metadata = await getUtxoMetadata(details.tx_hash)
    console.log(metadata)
    const lovelaceAmount = details.amount.find(x => x.unit === 'lovelace');

    const scriptUtxo = TransactionUnspentOutput.new(
        TransactionInput.new(
            TransactionHash.from_bytes(fromHex(details.tx_hash)), details.tx_index
        ),
        TransactionOutput.new(
            contractAddress,
            getContractOutput(lovelaceAmount?.quantity || '0', policyId, assetName)
        )
    );

    return { 
            scriptUtxo: scriptUtxo,
            metadata: metadata
    }
}

export const getContractOutput = (lovelanceAmount: string, policyId: string, assetName: string) => {
    const assetDetails: AssetDetails[] = []
    assetDetails.push({
        assetNameInHex: toHex(toByteArray(assetName)),
        policyIdInHex: policyId,
        amount: '1'
    })
    return getAssetValue(lovelanceAmount, assetDetails);
}

export const getAssetValue = (lovelace: string, assets: AssetDetails[]) => {
    const assetValue = Value.new(toBigNum(lovelace));
    const multiAsset = MultiAsset.new();

    const dict: { [policyId: string]: AssetDetails[] } = {}
    assets.forEach(a => {
        if (!(a.policyIdInHex in dict)) {
            dict[a.policyIdInHex] = []
        }

        dict[a.policyIdInHex].push(a)
    });

    Object.keys(dict).forEach(key => {
        const assets = Assets.new()
        const items = dict[key]
        items.forEach(i => {
            assets.insert(
                AssetName.new(fromHex(i.assetNameInHex)),
                toBigNum(i.amount)
            );
        })
        
        multiAsset.insert(
            ScriptHash.from_bytes(fromHex(key)),
            assets
        );
    }); 

    assetValue.set_multiasset(multiAsset)
    return assetValue;
}

export interface AssetDetails {
    policyIdInHex: string,
    assetNameInHex: string,
    amount: any
}


export const getCollateralUnspentTransactionOutput = async (cardano: BrowserWallet) => {
    const getCollateral = cardano.getCollateral || (cardano as any).getCollateralUtxos || (() => { throw Error('Your wallet does not support `getCollateral` API from CIP-30.') })
    const utxosHex = await getCollateral()
    const utxos = utxosHex
        .map((utxoHex: string) => TransactionUnspentOutput.from_bytes(fromHex(utxoHex)))
        .filter((utxo) => utxo !== undefined)
    if (utxos.length === 0) {
        throw new Error('Collateral is not added. Please go to your wallet and add collateral')
    }
    // eternal response to issue maxCollaterai input equals 3 but got 5 "They should only use 1 collateral, not ALL of them - one input should be sufficient
    return [utxos[0]]
};

export const getTransaction = async (cardano: BrowserWallet, txhash: string) => {
    return cardanoApiAdapter(cardano).getUtxos().then(utxos => utxos.find(utxo => {
        return toHex(utxo.input().transaction_id().to_bytes()) === txhash
    }))
}



export const splitIfChangeValueIsTooBig = (change: Value, protocolParameters: CardanoProtocolParameters, txBuilder: TransactionBuilder, changeAddress: Address) => {
    const changeMultiAssets = change.multiasset();

    // check if change value is too big for single output
    if (changeMultiAssets && change.to_bytes().length * 2 > protocolParameters.max_val_size) {
      const partialChange = Value.new(BigNum.from_str("0"));

      const partialMultiAssets = MultiAsset.new();
      const policies = changeMultiAssets.keys();
      const makeSplit = () => {
        for (let j = 0; j < changeMultiAssets.len(); j++) {
          const policy = policies.get(j);
          const policyAssets = changeMultiAssets.get(policy)!;
          const assetNames = policyAssets.keys();
          const assets = Assets.new();
          for (let k = 0; k < assetNames.len(); k++) {
            const policyAsset = assetNames.get(k);
            const quantity = policyAssets.get(policyAsset)!;
            assets.insert(policyAsset, quantity);
            //check size
            const checkMultiAssets = MultiAsset.from_bytes(partialMultiAssets.to_bytes());
            checkMultiAssets.insert(policy, assets);
            const checkValue = Value.new(BigNum.from_str("0"));
            checkValue.set_multiasset(checkMultiAssets);
            if (checkValue.to_bytes().length * 2 >= protocolParameters.max_val_size) {
              partialMultiAssets.insert(policy, assets);
              return;
            }
          }
          partialMultiAssets.insert(policy, assets);
        }
      };
      makeSplit();
      partialChange.set_multiasset(partialMultiAssets);
      const minAda = min_ada_required(
        partialChange,
        true,
        BigNum.from_str(protocolParameters.coins_per_utxo_word.toString())
      );
      partialChange.set_coin(minAda);

      txBuilder.add_output(
        TransactionOutput.new(
          changeAddress,
          partialChange
        )
      );
    }
}
