import { toHex, toByteArray, splitAddress } from '../cardano/plutus-helpers'
import { getAssetUtxos, getLatestBlockSlot, getLatestPosixTime, getUtxoMetadata } from '../cardano/blockfrost'
import * as ConfigProvider from '../configProvider'
import { appInsights } from '../AppInsights' 
import { BreedingInputParams } from './BreedingInputParams'
import { Asset, BrowserWallet, Data, Transaction, resolveDataHash, resolvePaymentKeyHash, UTxO, PlutusScript, resolvePlutusScriptAddress } from '@meshsdk/core'

const nftMoveFixedPrice = "3000000";
const mintingFee = "2000000";
const adminClaimFee = "1100000";

export const getPlatformFees = () : number => {
    if (ConfigProvider.getBreedingV2()) {
        return parseInt(nftMoveFixedPrice)
    } else {
        return parseInt(nftMoveFixedPrice) + parseInt(mintingFee)
    }
}

export const lockForBreeding = async (wallet: BrowserWallet, breedingParams: BreedingInputParams): Promise<string> => {
    const selfAddress = await wallet.getChangeAddress()
    const addresses = await wallet.getUsedAddresses();
    const pkh = resolvePaymentKeyHash(addresses[0]);
    if (breedingParams.deadline == "") {
        const deadline = (await getLatestPosixTime()) + ConfigProvider.getBreedingTime();
        breedingParams.deadline = deadline.toString();
    }

    const scriptAddr = getContractAddress()
    const meshDatum = createBreedingDatumForMesh(pkh, breedingParams)
    const meshDatumHash = resolveDataHash(meshDatum);
    console.log(`meshDatumHash ${meshDatumHash}`)
    console.log(`selfAddress ${selfAddress} addresses[0] ${addresses[0]} pkh ${pkh}`)

    const amount = parseInt(breedingParams.serviceFee) + getPlatformFees()
    const outputs = getBreedingContractOutput(amount.toString(), breedingParams)
    const tx = new Transaction({ initiator: wallet })
        .sendAssets(
            {
                address: scriptAddr,
                datum: {
                    value: meshDatum
                },
            },
            outputs
        );

    const meta: BreedingMetadata = getBreedingMetadata(selfAddress, breedingParams, "start")
    tx.setMetadata(505, meta);

    try {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`txHash for start breeding offer: ${txHash} prime: ${breedingParams.primePolicyId}.${breedingParams.primeAssetName} second: ${breedingParams.secondPolicyId}.${breedingParams.secondAssetName}, deadline: ${breedingParams.deadline}`);
        appInsights.trackTrace({ message: 'startbreeding' }, {
            owner: selfAddress,
            pkh: pkh,
            wallet: localStorage.getItem('wallet'),
            txHash: txHash,
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "startbreeding"
        });
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            owner: selfAddress,
            pkh: pkh,
            wallet: localStorage.getItem('wallet'),
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "startbreeding"
        });
        throw err;
    }
}

 export const cancel = async (wallet: BrowserWallet, breedingParams: BreedingInputParams): Promise<string> => {
    const selfAddress = await wallet.getChangeAddress()
    const addresses = await wallet.getUsedAddresses();
    const pkh = resolvePaymentKeyHash(addresses[0]);

    const unspentOutput = await getBreedingUnspentTransactionUtxoForNft(getContractAddress(), breedingParams);
    const deadline = unspentOutput.metadata?.deadline
    breedingParams.deadline = deadline

    const meshDatum = createBreedingDatumForMesh(pkh, breedingParams)
    const meshDatumHash = resolveDataHash(meshDatum);
    // console.log(`meshDatumHash: ${meshDatumHash}`)
    const contractScript = getContractScript()
    const redeemer = createRedeemer(RedeemerType.Cancel)
    const latestSlot = await getLatestBlockSlot()
    const tx = new Transaction({ initiator: wallet })
        .redeemValue({
            value: unspentOutput.scriptUtxo,
            script: contractScript,
            datum: meshDatum,
            redeemer: redeemer
          })
          .sendValue(selfAddress, unspentOutput.scriptUtxo) 
          .setTimeToStart(latestSlot.toString())
          .setRequiredSigners([selfAddress]);

    const meta: BreedingMetadata = getBreedingMetadata(selfAddress, breedingParams, "cancel")
    tx.setMetadata(505, meta);
    try {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx, true);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`cancel breeding txhash: ${txHash}`)
        appInsights.trackTrace({ message: 'cancelbreeding' }, {
            owner: selfAddress,
            pkh: pkh,
            wallet: localStorage.getItem('wallet'),
            txHash: txHash,
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "cancelbreeding"
        });
        return txHash
    } catch (err) {
        console.log(err)
        appInsights.trackException({ exception: err as Error }, {
            owner: selfAddress,
            pkh: pkh,
            wallet: localStorage.getItem('wallet'),
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "cancelbreeding",
        });
        throw err;
    }   
}

export const cancelAdmin = async (wallet: BrowserWallet, breedingParams: BreedingInputParams, transactionOriginalSigningAddress: string): Promise<string> => {
    const selfAddress = await wallet.getChangeAddress()
    
    const originatorAddress = transactionOriginalSigningAddress
    const pkh = resolvePaymentKeyHash(originatorAddress)

    const unspentOutput = await getBreedingUnspentTransactionUtxoForNft(getContractAddress(), breedingParams);
    const deadline = unspentOutput.metadata?.deadline
    breedingParams.deadline = deadline

    const meshDatum = createBreedingDatumForMesh(pkh, breedingParams)
    const meshDatumHash = resolveDataHash(meshDatum);
    // console.log(`meshDatumHash: ${meshDatumHash}`)
    const contractScript = getContractScript()
    const redeemer = createRedeemer(RedeemerType.CloseAdmin)

    const amount = parseInt(breedingParams.serviceFee) + getPlatformFees()
    const amountWithoutAdminFee = amount - parseInt(adminClaimFee)
    const userAssetsMinusFee = await getBreedingContractOutput(amountWithoutAdminFee.toString(), breedingParams)

    const latestSlot = await getLatestBlockSlot()
    const tx = new Transaction({ initiator: wallet })
        .redeemValue({
            value: unspentOutput.scriptUtxo,
            script: contractScript,
            datum: meshDatum,
            redeemer: redeemer
          })
          .sendAssets(originatorAddress, userAssetsMinusFee)
          .sendLovelace(selfAddress, adminClaimFee)
          .setChangeAddress(selfAddress)
          .setTimeToStart(latestSlot.toString())
          .setRequiredSigners([selfAddress]);

    const meta: BreedingMetadata = getBreedingMetadata(selfAddress, breedingParams, "cancel")
    tx.setMetadata(505, meta);

    try
    {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx, true);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`cancel admin breeding txhash: ${txHash}`)
        appInsights.trackTrace({ message: 'canceladminbreeding' }, {
            owner: selfAddress,
            originatorAddress: originatorAddress,
            originatorPkh: pkh,
            wallet: localStorage.getItem('wallet'),
            txHash: txHash,
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "cancelbreeding"
        });
        return txHash
    } catch (err) {
        console.log(err)
        appInsights.trackException({ exception: err as Error }, {
            owner: selfAddress,
            originatorAddress: originatorAddress,
            originatorPkh: pkh,
            wallet: localStorage.getItem('wallet'),
            originalSigner: transactionOriginalSigningAddress,
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "canceladminbreeding"
        });
        throw err;
    }
}

export const claim = async (wallet: BrowserWallet, breedingParams: BreedingInputParams): Promise<string> => {
    const selfAddress = await wallet.getChangeAddress()
    const addresses = await wallet.getUsedAddresses();
    const pkh = resolvePaymentKeyHash(addresses[0]);
    const contractScript = getContractScript()
    const unspentOutput = await getBreedingUnspentTransactionUtxoForNft(getContractAddress(), breedingParams);
    const deadline = unspentOutput.metadata?.deadline
    breedingParams.deadline = deadline

    const commissionAddress = getCommissionAddress()
    const mintingAddress = getServiceMintingAddress()
    const serviceFee = ConfigProvider.getBreedingV2() ? Number.parseInt(breedingParams.serviceFee) - Number.parseInt(mintingFee) : Number.parseInt(breedingParams.serviceFee)

    const meshDatum = createBreedingDatumForMesh(pkh, breedingParams)
    const meshDatumHash = resolveDataHash(meshDatum);    
    
    const redeemer = createRedeemer(RedeemerType.Claim)
    const ownerOutput = getClaimContractOutput(nftMoveFixedPrice, breedingParams)
    const commissionOutput = getCommissionContractOutput(serviceFee.toString(), breedingParams)
    unspentOutput.scriptUtxo.output.dataHash = meshDatumHash
    const latestSlot = await getLatestBlockSlot()
    const tx = new Transaction({ initiator: wallet })
        .redeemValue({
            value: unspentOutput.scriptUtxo,
            script: contractScript,
            datum: meshDatum,
            redeemer: redeemer,
          })
          .sendLovelace(mintingAddress, mintingFee)
          .sendAssets(selfAddress, ownerOutput)
          .sendAssets(commissionAddress, commissionOutput)
          .setTimeToStart(latestSlot.toString())
          .setRequiredSigners([selfAddress]);
    const meta: BreedingMetadata = getBreedingMetadata(selfAddress, breedingParams, "claim")
    tx.setMetadata(505, meta);

    try {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx, true);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`claimbreeding txhash: ${txHash}`)
        appInsights.trackTrace({ message: 'claimbreeding' }, {
            owner: selfAddress,
            pkh: pkh,
            wallet: localStorage.getItem('wallet'),
            txHash: txHash,
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "claimbreeding"
        });
        return txHash
    } catch (err) {
        console.log(err)
        appInsights.trackException({ exception: err as Error }, {
            owner: selfAddress,
            pkh: pkh,
            wallet: localStorage.getItem('wallet'),
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "claimbreeding",
        });
        throw err;
    }  
}


export const claimAdmin = async (wallet: BrowserWallet, breedingParams: BreedingInputParams, transactionOriginalSigningAddress: string): Promise<string> => {
    const selfAddress = await wallet.getChangeAddress()
    const originatorAddress = transactionOriginalSigningAddress
    const pkh = resolvePaymentKeyHash(originatorAddress);

    const contractScript = getContractScript()
    const unspentOutput = await getBreedingUnspentTransactionUtxoForNft(getContractAddress(), breedingParams);
    const deadline = unspentOutput.metadata?.deadline
    breedingParams.deadline = deadline

    const commissionAddress = getCommissionAddress()
    const mintingAddress = getServiceMintingAddress()
    const serviceFee = ConfigProvider.getBreedingV2() ? Number.parseInt(breedingParams.serviceFee) - Number.parseInt(mintingFee) : Number.parseInt(breedingParams.serviceFee)
    const mintingServiceFee = mintingFee

    const meshDatum = createBreedingDatumForMesh(pkh, breedingParams)
    const meshDatumHash = resolveDataHash(meshDatum);    
    
    const redeemer = createRedeemer(RedeemerType.CloseAdmin)
    const nftMoveFixedPriceWithoutAdmin = parseInt(nftMoveFixedPrice) - parseInt(adminClaimFee)
    const ownerOutput = getClaimContractOutput(nftMoveFixedPriceWithoutAdmin.toString(), breedingParams)
    const commissionOutput = getCommissionContractOutput(serviceFee.toString(), breedingParams)
    unspentOutput.scriptUtxo.output.dataHash = meshDatumHash
    const latestSlot = await getLatestBlockSlot()
    const tx = new Transaction({ initiator: wallet })
        .redeemValue({
            value: unspentOutput.scriptUtxo,
            script: contractScript,
            datum: meshDatum,
            redeemer: redeemer,
          })
          .sendAssets(originatorAddress, ownerOutput)
          .sendLovelace(selfAddress, adminClaimFee)
          .sendAssets(commissionAddress, commissionOutput)
          .sendLovelace(mintingAddress, mintingServiceFee)
          .setTimeToStart(latestSlot.toString())
          .setRequiredSigners([selfAddress]);
    const meta: BreedingMetadata = getBreedingMetadata(selfAddress, breedingParams, "claim")
    tx.setMetadata(505, meta);
    
    try {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx, true);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`claim admin breeding txhash: ${txHash}`)
        appInsights.trackTrace({ message: 'claimadminbreeding' }, {
            owner: selfAddress,
            originatorAddress: originatorAddress,
            originatorPkh: pkh,
            wallet: localStorage.getItem('wallet'),
            txHash: txHash,
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "claimadminbreeding"
        });
        return txHash
        
    } catch (err) {
        console.log(err)
        appInsights.trackException({ exception: err as Error }, {
            owner: selfAddress,
            originatorAddress: originatorAddress,
            originatorPkh: pkh,
            wallet: localStorage.getItem('wallet'),
            primePolicyId: breedingParams.primePolicyId,
            primeAssetName: breedingParams.primeAssetName,
            primeTknGen: breedingParams.primeTknGen,
            secondPolicyId: breedingParams.secondPolicyId,
            secondAssetName: breedingParams.secondAssetName,
            secondTknGen: breedingParams.secondTknGen,
            serviceFee: breedingParams.serviceFee,
            serviceDFee: breedingParams.serviceDFee,
            method: "claimadminbreeding",
        });
        throw err;
    }  
}
    

const getBreedingContractOutput = (lovelaceAmount: string, breedingParams: BreedingInputParams) => {
    const assets: Asset[] = []
    assets.push({
        unit: 'lovelace',
        quantity: lovelaceAmount
    })
    assets.push({
        unit: `${breedingParams.primePolicyId}${toHex(toByteArray(breedingParams.primeAssetName))}`,
        quantity: '1'
    })
    assets.push({
        unit: `${breedingParams.secondPolicyId}${toHex(toByteArray(breedingParams.secondAssetName))}`,
        quantity: '1'
    })
    assets.push({
        unit: `${ConfigProvider.getBreedingDinoCoinPolicyId()}${toHex(toByteArray(ConfigProvider.getBreedingDinoCoinName()))}`,
        quantity: breedingParams.serviceDFee
    })
     
    return assets
}

const getClaimContractOutput = (lovelaceAmount: string, breedingParams: BreedingInputParams) => {
    const assets: Asset[] = []
    assets.push({
        unit: 'lovelace',
        quantity: lovelaceAmount
    })
    assets.push({
        unit: `${breedingParams.primePolicyId}${toHex(toByteArray(breedingParams.primeAssetName))}`,
        quantity: '1'
    })
    assets.push({
        unit: `${breedingParams.secondPolicyId}${toHex(toByteArray(breedingParams.secondAssetName))}`,
        quantity: '1'
    })
     
    return assets
}

const getCommissionContractOutput = (lovelaceAmount: string, breedingParams: BreedingInputParams) => {
    const assets: Asset[] = []
    assets.push({
        unit: 'lovelace',
        quantity: lovelaceAmount
    })
    assets.push({
        unit: `${ConfigProvider.getBreedingDinoCoinPolicyId()}${toHex(toByteArray(ConfigProvider.getBreedingDinoCoinName()))}`,
        quantity: breedingParams.serviceDFee
    })
     
    return assets
}

const getBreedingUnspentTransactionUtxoForNft = async (contractAddress: string, breedingParams: BreedingInputParams) => {
    const utxos = await getAssetUtxos(breedingParams.primeAssetName, breedingParams.primePolicyId, contractAddress);
    if (utxos.length === 0) {
        throw Error(`Could not find UTXO for prime: ${breedingParams.primeAssetName}`);
    }

    var details = utxos[0];
    var metadata = await getUtxoMetadata(details.tx_hash)
    const lovelaceAmount = details.amount.find(x => x.unit === 'lovelace');
    
    const amount = getBreedingContractOutput(lovelaceAmount?.quantity || '0', breedingParams)
    const utxo: UTxO = {
        input: { txHash: details.tx_hash, outputIndex: details.tx_index },
        output: { 
            address: contractAddress, 
            amount: amount
        }
    }
    
    return { 
            scriptUtxo: utxo,
            metadata: metadata
    }
}

const getContractAddress = () => {
    const contractAddress = resolvePlutusScriptAddress(getContractScript(), ConfigProvider.getCardanoNetworkId()) 
    return contractAddress;
}

const getContractScript = () => {
    // we need to return cbor with 6 leading digits from smart contract, cardano-serialization-lib was expecting us to remove 6 digits from cbor
    const script: PlutusScript = {
        code: ConfigProvider.getBreedingContractCbor(),
        version: 'V1',
      };
    return script
}

const getCommissionAddress = () => {
    return ConfigProvider.getBreedingServiceFeeAddress()
}

const getServiceMintingAddress = () => {
    return ConfigProvider.getBreedingMintingFeeAddress()
}

const enum RedeemerType {
    Claim = 0,
    Cancel = 1,
    CloseAdmin = 2
}

const createRedeemer = (index: number) => {
    const redeemer = {
        data: { alternative: index, fields: []}
    };
    return redeemer;
}

const createBreedingDatumForMesh = (owner: string, breedingParams: BreedingInputParams): Data => {
    console.log(`params owner: ${owner} deadline: ${breedingParams.deadline} primePolicyId: ${breedingParams.primePolicyId} primeAssetName: ${breedingParams.primeAssetName} primeTknGen: ${breedingParams.primeTknGen} secondPolicyId: ${breedingParams.secondPolicyId} secondAssetName: ${breedingParams.secondAssetName} secondTknGen: ${breedingParams.secondTknGen} serviceFee: ${breedingParams.serviceFee.toString()} serviceDFee: ${breedingParams.serviceDFee.toString()}`)
    const datum: Data = {
        alternative: 0,
        fields:[
            owner,
            Number.parseInt(breedingParams.deadline),
            breedingParams.primePolicyId,
            breedingParams.primeAssetName,
            Number.parseInt(breedingParams.primeTknGen),
            breedingParams.secondPolicyId,
            breedingParams.secondAssetName,
            Number.parseInt(breedingParams.secondTknGen),
            Number.parseInt(breedingParams.serviceFee),
            Number.parseInt(breedingParams.serviceDFee)
        ]
    }
    return datum
}

const getBreedingMetadata = (ownerAddress: string, breedingParams: BreedingInputParams, operation: string) => {
    const meta: BreedingMetadata = {
        owner: splitAddress(ownerAddress),
        deadline: breedingParams.deadline,
        currency1: breedingParams.primePolicyId,
        token1: breedingParams.primeAssetName,
        tkn1gen: breedingParams.primeTknGen,
        currency2: breedingParams.secondPolicyId,
        token2: breedingParams.secondAssetName,
        tkn2gen: breedingParams.secondTknGen,
        op: operation
    };

    return meta;
}

interface BreedingMetadata{
    owner: string[],
    deadline: string,
    currency1: string,
    token1: string,
    tkn1gen: string,
    currency2: string,
    token2: string,
    tkn2gen: string,
    op: string
}