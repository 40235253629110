import React from 'react'
import { ModalDialog } from '../components/ModalDialog'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { OverlayContainer } from '@react-aria/overlays'
import { useButton } from '@react-aria/button'
import { Button } from '../components/Button'
import * as Api from '../Api'
import { useAuth } from 'react-oidc-context'
import DinoCoinIconSrc from '../breeding/DinoCoin_78x78.png'
import './EnableFeatureDialog.scss'

const SubscriptionFeaturesDefinition = {
    [Api.SubscriptionFeature.UserRating]: { price: 750, name: 'User rating' },
    [Api.SubscriptionFeature.Scholarship]: { price: 10000, name: 'Scholarship' },
    [Api.SubscriptionFeature.UserAnalytics]: { price: 1500, name: 'User analytics' },
}

export const EnableFeatureDialog = ({ onComplete, feature }: { onComplete?: () => Promise<void>, feature: Api.SubscriptionFeature }) => {

    const state = useOverlayTriggerState({})
    const openButtonRef = React.useRef(null)
    const closeButtonRef = React.useRef(null)
    const { user } = useAuth()

    const { buttonProps: openButotnProps } = useButton({
        onPress: () => state.open()
    }, openButtonRef)

    const enableFeature = React.useCallback(() => {
        return Api.postSubscriptionFeatures(feature, user?.access_token!).then(() => {
            state.close()
            if (onComplete) {
                return onComplete()
            }
        })
    }, [user, feature, onComplete, state])

    return (
        <>
            <button className="button" {...openButotnProps} ref={openButtonRef}>
                Enable
            </button>
            {state.isOpen && (
                <OverlayContainer>
                    <ModalDialog collection="cryptodinos" isOpen={true} onClose={state.close} isDismissable={true}>
                        <h1>
                            Enable {SubscriptionFeaturesDefinition[feature].name}
                        </h1>
                        <div className="dialog-content enable-feature-dialog">
                            <div>Amount to pay:</div>  
                            <h3>{SubscriptionFeaturesDefinition[feature].price} <img src={DinoCoinIconSrc} style={{ height: 24, verticalAlign: 'text-bottom' }} alt="DINO" title='DINO' /></h3>
                            <p><small>The amount will be deducted from your game account.</small></p>
                            <div className="actions">
                                <Button onPress={enableFeature}>Pay now</Button>
                            </div> 
                        </div>
                    </ModalDialog>
                </OverlayContainer>
            )
            }
        </>
    )
}