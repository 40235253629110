import React from 'react'
import { useCardanoWalletProvider, useCoinsBalance, useWalletAssets } from '../cardano/CardanoWalletProvider'
import { getDinoCoinPolicyId, getDinoCoinPolicyIdV2, isBreedingEnabled, isDenominationEnabled } from '../configProvider'
import DinoCoinIconSrc from '../breeding/DinoCoin_78x78.png'
import { Button } from '../components/Button'
import { useNotifications } from '../components/NotificationProvider'
import { CancelError } from '../cardano/cardano-adapter'
import { appInsights } from '../AppInsights'
import { canDenominateDinoCoins, denominateDinoCoins } from '../explore/dinoCoinDenominationManager'
import { canClaimDinoCoinForAssets, claimDinoCoinForAssets } from '../explore/dinoCoinClaimingManager'
import './AccountSummary.scss'
import { babydinosPolicyId, collections, cryptodinosPolicyId, dinosaviorsPolicyId } from '../collections'

export const AccountSummary = () => {
    const dinoCoinsNew = useCoinsBalance(getDinoCoinPolicyIdV2())
    const displayDinoCoins = React.useMemo(() => {
        const intl = Intl.NumberFormat()
        return dinoCoinsNew ? intl.format(dinoCoinsNew / 1000000) : 0
    }, [dinoCoinsNew])

    return (
        <div style={{ margin: '0 auto', textAlign: 'center' }}>
            <div className="panel">
                <h3 style={{ fontSize: 24 }}>
                    <span style={{ fontWeight: 400, marginRight: 10 }}>Total amount:</span><span>{displayDinoCoins}</span>
                    <img src={DinoCoinIconSrc} style={{ height: 32, verticalAlign: 'middle', marginLeft: 5 }} alt="DINO" title='DINO' />
                </h3>
            </div>
            <AccountCollectiblesPanel />
            <DinoCoins />
        </div>
    )
}

const AccountCollectiblesPanel = () => {

    const cryptodinoAssets = useWalletAssets([cryptodinosPolicyId])
    const dinosaviorAssets = useWalletAssets([dinosaviorsPolicyId])
    const babydinoAssets = useWalletAssets([babydinosPolicyId])
    const collectibles = React.useMemo(() => {
        return [
            { title: 'CryptoDino', imageUrl: 'https://cnft.pictures/oklAJTTNZf3vfvcQtFTelg48YmpwwaVr0K4XFGRt7LY/rs:fit:128:128:0:0/g:no/aHR0cHM6Ly9pbWFnZXMuanBnc3RvcmVhcGlzLmNvbS9hODFlYTYzNy01ZmRkLTQ0ZmQtOTFjOC0yMGI5ZGZiY2YyZTk.webp', count: cryptodinoAssets.length },
            { title: 'DinoSaviors', imageUrl: 'https://cnft.pictures/SGoAvyYXbKYf4zCY3nm1QvKcaKkc68i0ISeE3g_S2Sg/rs:fit:128:128:0:0/g:no/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2pwZy1zdG9yZS1pbWFnZXMvaGVyby1pbWFnZXMvZGlub3NhdmlvcnMud2VicA.webp', count: dinosaviorAssets.length },
            { title: 'CryptoDino next-generations', imageUrl: 'https://cnft.pictures/d81deAdtiklj-MuIHBWHAkwY5Qsr_WRwCK59WEhYN1U/rs:fit:128:128:0:0/g:no/aHR0cHM6Ly9zdG9yYWdlLmdvb2dsZWFwaXMuY29tL2pwZy1zdG9yZS1pbWFnZXMvaGVyby1pbWFnZXMvY3J5cHRvZGlub3Mud2VicA.webp', count: babydinoAssets.length },
        ]
    }, [cryptodinoAssets, dinosaviorAssets])

    return (
        <div className="panel summary-collectibles">
            {collectibles.map(c => (
                <div>
                    <img src={c.imageUrl} title={c.title} />
                    {c.count}
                </div>
            ))}
        </div>
    )
}

const useClaimDinoCoins = () => {
    const { getCardano } = useCardanoWalletProvider()
    const { addWarn } = useNotifications()
    const handleError = React.useCallback((err: any) => {
        if (err instanceof CancelError) {
            return
        } else if (typeof err === 'string') {
            addWarn(err)
            appInsights.trackTrace({ message: err })
        } else {
            addWarn(err.info || err.message)
            appInsights.trackException({ exception: err })
        }
    }, [addWarn])
    const onClaimDinoCoins = React.useCallback(async () => {
        try {
            const canClaim = await canClaimDinoCoinForAssets(getCardano())
            if (canClaim) {
                const txHash = await claimDinoCoinForAssets(getCardano())
                console.log(`claiming dino coins tx hash: ${txHash}`)
            } else {
                addWarn("No more DinoCoins to claim")
            }
        }
        catch (err) {
            handleError(err)
        }
    }, [])
    return onClaimDinoCoins
}

const useDenominateCoins = () => {
    const { getCardano } = useCardanoWalletProvider()
    const { addWarn } = useNotifications()
    const handleError = React.useCallback((err: any) => {
        if (err instanceof CancelError) {
            return
        } else if (typeof err === 'string') {
            addWarn(err)
            appInsights.trackTrace({ message: err })
        } else {
            addWarn(err.info || err.message)
            appInsights.trackException({ exception: err })
        }
    }, [addWarn])
    const onDenominateCoins = React.useCallback(async () => {
        try {
            const canDenominate = await canDenominateDinoCoins(getCardano())
            if (canDenominate) {
                const txHash = await denominateDinoCoins(getCardano())
                console.log(`denominate dino coins tx hash: ${txHash}`)
            } else {
                addWarn("All DinoCoins have already been sent for denomination")
            }
        }
        catch (err) {
            handleError(err)
        }
    }, [])
    return onDenominateCoins
}

const DinoCoins = () => {

    const onClaimDinoCoins = useClaimDinoCoins()
    const onDenominateCoins = useDenominateCoins()
    const dinoCoinsOld = useCoinsBalance(getDinoCoinPolicyId())
    return isBreedingEnabled() ? (
        <div className="panel">
            <Button onPress={onClaimDinoCoins}>Claim DINO</Button>
            {isDenominationEnabled() && dinoCoinsOld && dinoCoinsOld > 0 ? (<Button onPress={onDenominateCoins} style={{ marginRight: '100px' }}>Denominate</Button>) : (<div></div>)}
            {/* <Button onPress={onClaimDinoCoinsBuy} style={{ marginRight: '100px' }}>Claim DinoBuy</Button> */}
        </div>
    ) : (<div></div>)
}