import React, { ReactNode } from 'react'
import { useLocation, Link } from 'react-router-dom'
import './Tabs.scss'

export type TabsProps = {
    children?: ReactNode,
    extraContent?: ReactNode,
}

export const Tabs = ({ children, extraContent }: TabsProps): JSX.Element => {
    const location = useLocation()
    const tabs = React.Children.map(children || [] as any, (child) => child && child.props).filter(Boolean) as TabProps[]
    const activeTab = tabs.filter((tab) => `#${tab.name}` === location.hash)[0] || tabs[0]

    return (
        <React.Fragment>
            <div className="tabs">
                <ul>
                {tabs.map((tab) => (<li key={tab.name}><Link to={{ state: undefined, hash: tab.name }} className={activeTab.name === tab.name ? 'active-tab' : ''}>{tab.title}</Link></li>))}
                </ul>
                {extraContent}
            </div>
            <div>
                {activeTab.children}
            </div>
        </React.Fragment>
    )
}

export type TabProps = {
    title: string | ReactNode,
    name: string,
    children?: ReactNode,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const Tab = (props: TabProps): JSX.Element => {
    return <React.Fragment />
}
