import React from 'react'
import './Explore.scss'
import { Gallery, useAssetItems } from './Gallery'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom'
import './Gallery.scss'
import { GalleryFilter } from './GalleryFilter'
import { collections, CollectionKeys } from '../collections'
import { PageTemplate } from '../components/PageTemplate'
import { Switch } from '../components/Switch'
import { SortAssetsButton } from './SortButton'
import { assetLinkName } from './GalleryTile'
import { ReactComponent as RarityIcon } from './RarityIcon.svg'

function useQuery<T>() {
    const { replace } = useHistory()
    const { pathname, search } = useLocation()
    const state = React.useMemo(() => Object.fromEntries(new URLSearchParams(search).entries()), [search]) as unknown as T
    const change = React.useCallback((params) => {
        replace({ pathname: pathname, search: '?' + new URLSearchParams(params).toString() })
    }, [pathname])
    return [state, change]
}

export const Explore = () => {

    const history = useHistory()
    const { pathname } = useLocation()
    const { collection: collectionName } = useParams<{ collection: CollectionKeys }>()
    const collection = collections[collectionName]
    const size = '10.000';
    const [query, setQuery] = useQuery()
    const onSale = query.onSale === 'true'
    const order = query.order || 'asset.number'
    const setOnSale = React.useCallback((value) => {
        setQuery({ ...query, onSale: value })
    }, [query, setQuery])
    const setOrder = React.useCallback((order) => {
        setQuery({ ...query, order })
    }, [query, setQuery]);
    const [filter, setFilters] = React.useState<any>()
    const onFindByNumberKeyPress = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && /(\d+-)?\d{1,5}/.test(event.currentTarget.value)) {
            history.push(`${pathname}/${assetLinkName(event.currentTarget.value)}`)
        }
    }, [pathname]);
    const switchCollection = collectionName === 'cryptodinos' ? {
        className: 'dinosaviors-icon',
        name: collections.dinosaviors.name,
        url: `/explore/dinosaviors?onSale=${onSale}&order=${order}`,
    } : {
        className: 'cryptodinos-icon',
        name: collections.cryptodinos.name,
        url: `/explore/cryptodinos?onSale=${onSale}&order=${order}`
    }

    React.useEffect(() => {
        if (onSale === false && (order === 'price-asc' || order === 'price-desc')) {
            setOrder('number')
        }
    }, [onSale, order])

    const data = useAssetItems(collectionName, { filter, onSale, order })

    return (
        <div className="explore">
            <PageTemplate title={(<><big>C</big>ollection</>)}>
                <nav>
                    <div className="title">
                        <Link to={switchCollection.url}>
                            <span className={switchCollection.className}></span>
                            <span>Go to {switchCollection.name}</span>
                        </Link>
                    </div>
                    <div className="filters">
                        <input placeholder="Find by number" onKeyPress={onFindByNumberKeyPress} />
                        <GalleryFilter collection={collectionName} collectionId={collection.collectionId} applyFilters={setFilters} />
                        <Link to={`/explore/${collectionName}/rarity`} className="button">
                            <RarityIcon />
                            <span>Rarity</span>
                        </Link>
                    </div>
                    <div className="extras">
                        <Switch isSelected={onSale} onChange={setOnSale}>On sale</Switch>
                        <SortAssetsButton order={order} onOrderChange={setOrder} onSale={onSale} />
                    </div>
                </nav>
                <article>
                    <Gallery collection={collectionName} data={data} />
                </article>
            </PageTemplate>
        </div>
    )
}