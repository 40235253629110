import * as ConfigProvider from './configProvider'
import { getOffers } from './marketplace/marketplaceApi'
import EggPulsingSrc from './breeding/EggPulsingLight.png'
import { SignedData } from './nami'
const apiUrl = ConfigProvider.getApiUrl() //'https://prod-api-weu-dino.azurewebsites.net'
const gameApiUrl = ConfigProvider.getGameApiUrl()

type Paged<T> = {
    items: T[],
    count: number,
}

export type ComposedAsset = {
    asset: AssetItem,
    counter: {
        counter: number,
        lastBreedingDate?: string,
    },
    offer?: {
        price: string,
    },
    dinoCoins?: boolean,
}

export type AssetItem = {
    assetId: string,
    name: string,
    number: number,
    rank: number,
    mintingDate: string,
    thumbprint: {
        ipfsHash: string,
    },
    isMinted: boolean,
}

export type Asset = {
    assetId: string,
    collectionId: string,
    name: string,
    number: number,
    asset: {
        ipfsHash: string,
    },
    thumbprint: {
        ipfsHash: string,
    },
    data: any,
    rank: number,
    mintingDate: string,
}

export const getAssets = (collectionId: string, filters: any, names: string[], order: string | undefined, { page, pageSize }: any): Promise<Paged<AssetItem>> => {
    if (order === 'price-asc' || order === 'price-desc') {
        order = 'number'
    }

    const query = new URLSearchParams([
        ['pageNumber', page],
        ['pageSize', pageSize],
        ['order', order],
        ['names', names.length > 0 ? names.join(',') : undefined],
        ...Object.entries(filters || {})
    ].filter(([, value]) => Boolean(value)));

    return fetch(`${apiUrl}/api/collections/${collectionId}/assets?${query}`).then((response) => response.json())
}

export type Filter = {
    [field: string]: string,
}

export const explore = (collectionId: string, filters: Filter | undefined, names: string[] | undefined, order: string | undefined, onSale: boolean, { page, pageSize }: any): Promise<ComposedAsset[]> => {
    const query = new URLSearchParams([
        ['collectionId', collectionId],
        ['pageNumber', page],
        ['pageSize', pageSize],
        ['order', order],
        ['onSale', onSale],
        ['names', names ? names?.join(',') : undefined],
        ...Object.entries(filters || {}).map(([key, value]) => [`Filters.${key}`, value])
    ].filter(([, value]) => Boolean(value)));

    return fetch(`${apiUrl}/api/explore?${query}`).then((response) => response.json())
}

export const getAsset = (collectionId: string, name: string): Promise<Asset> => {
    return fetch(`${apiUrl}/api/collections/${collectionId}/assets/${name}`).then((response) => response.json())
}

export const getAssetById = (assetId: string): Promise<Asset> => {
    return fetch(`${apiUrl}/api/assets/${assetId}`).then((response => response.json()))
}

export type CollectionFeatures = {
    [name: string]: {
        [value: string]: number,
    },
}

export const getFeatures = (collectionId: string): Promise<CollectionFeatures> => {
    return fetch(`${apiUrl}/api/collections/${collectionId}/features`).then(response => response.json())
}

type PlaceOrderResponse = {
    orderId: string,
    location: string,
}

export const placeOrder = (collectionId: string, couponCode: string, quantity: number): Promise<PlaceOrderResponse> => {
    return fetch(`${apiUrl}/api/reservations`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ collectionId, couponCode, quantity })
    }).then(response => response.json().then(orderId => {
        return {
            orderId,
            location: response.headers.get('location')!,
        }
    }));
}

type OrderState = {
    orderId: string,
    state: 'Approved' | 'Refused' | 'Expired',
}

export const getApprovalState = (orderUrl: string): Promise<OrderState | undefined> => {
    return fetch(orderUrl, {
        method: 'GET',
    }).then<OrderState | undefined>(response => {
        if (response.status === 404) {
            return undefined;
        } else {
            return response.json();
        }
    });
}

type PaymentState = {
    orderId: string,
    orderTotal: string,
    state: 'AwaitingPayment' | 'Expired' | 'Confirmed' | 'PriceAccpeted' | 'Quoted',
    address: string | undefined
}

export const acceptPrice = (orderId: string): Promise<any> => {
    return fetch(`${apiUrl}/api/payments/${orderId}`, {
        method: 'POST'
    }).then(response => response.json())
}

export const getPaymentState = (orderId: string): Promise<PaymentState | undefined> => {
    return fetch(`${apiUrl}/api/payments/${orderId}`, {
        method: 'GET',
    }).then<PaymentState | undefined>(response => {
        if (response.status === 404) {
            return undefined;
        } else {
            return response.json();
        }
    });
}

export const getInventory = (collectionId: string): Promise<number> => {
    return fetch(`${apiUrl}/api/reservations/inventory/${collectionId}`).then<number>(response => response.json());
}

export const simulateBreeding = async (asset1: string, asset2: string): Promise<string> => {
    return fetch(`${apiUrl}/api/breeding/simulation?assetId1=${asset1}&assetId2=${asset2}`)
        .then(async (response) => {
            if (response.ok) {
                const blob = await response.blob()
                return new Promise<string>(callback => {
                    const reader = new FileReader()
                    reader.onload = (ev) => callback(ev.target?.result as string)
                    reader.readAsDataURL(blob)
                })
            } else if (response.status === 403) {
                return EggPulsingSrc
            } else {
                throw 'Error during simulation'
            }
        });
}

type BreedingState = 'Started' | 'Claimed' | 'Completed' | 'Canceled' | 'Rejected'
export type Breeding = {
    orderId: string,
    parent1AssetId: string,
    parent2AssetId: string,
    startTime: string,
    lovelace: number,
    state: BreedingState,
    stateMessage: string | undefined,
}

export const getBreeding = async (walletAddress: string, assetId: string): Promise<Breeding | undefined> => {
    return fetch(`${apiUrl}/api/breeding/${walletAddress}/assets/${assetId}`).then(response => {
        if (response.ok) {
            return response.json()
        } else if (response.status === 404) {
            return undefined
        } else {
            throw Error(response.statusText)
        }
    })
}

export function getBreedingDetailsForAssets(assetId1: string, assetId2: string) {
    return fetch(`${apiUrl}/api/breeding/first/${assetId1}/second/${assetId2}`).then(response => {
        if (response.ok) {
            return response.json()
        } else if (response.status === 404) {
            return undefined
        } else {
            throw Error(response.statusText)
        }
    })
}

export type AssetCounter = {
    assetId: string,
    counter: number,
    limit: number | undefined,
    lastBreedingDate: string | undefined,
}

export const getCounter = async (assetId: string): Promise<AssetCounter> => {
    return fetch(`${apiUrl}/api/breeding/${assetId}/counter`).then(response => response.json())
}

export const getBreedingAssets = async (walletAddress: string): Promise<string[]> => {
    return fetch(`${apiUrl}/api/breeding/${walletAddress}/assets`).then(response => response.json())
}

export type BreedingEgg = {
    parent1AssetName: string,
    startTime: string,
    state: string,
}

export const getBreedingEggs = async (walletAddress: string): Promise<BreedingEgg[]> => {
    return fetch(`${apiUrl}/api/breeding/${walletAddress}`).then(response => response.json())
}

export type AssetDinoCoinsState = {
    assetId: string,
    wasClaimed: boolean,
    amount: number,
}

export function getAssetDinoCoinsState(assetId: string): Promise<AssetDinoCoinsState | undefined> {
    return fetch(`${apiUrl}/api/assetcoinsclaiming/${assetId}`).then(response => {
        if (response.ok) {
            return response.json()
        } else if (response.status === 404) {
            return undefined
        } else {
            throw Error(response.statusText)
        }
    })
}

export type BreedingItem = {
    parent1AssetName: string,
    parent2AssetName: string,
    lovelace: number,
    dinoCoins: number,
    state: BreedingState,
    deliveryAddress: string,
    stateMessage: string,
    startTime: string
}

export const getBreedings = (): Promise<BreedingItem[]> => {
    return fetch(`${apiUrl}/api/breedingadmin/unclaimed`).then(response => response.json())
}

export const getBreedingsAll = (): Promise<BreedingItem[]> => {
    return fetch(`${apiUrl}/api/breedingadmin/unclaimedall`).then(response => response.json())
}

export type BreedingPrice = {
    lovelace: number
}

export const getBreedingLovelacePrice = async (): Promise<BreedingPrice> => {
    return fetch(`${apiUrl}/api/breeding/lovelaceprice`).then(response => response.json())
}

export type StakingRewards = {
    accountId: string,
    quantity: number,
    dailyReward: number,
    totalReward: number,
}

export type StakingWalletResponse = {
    collections: (StakingRewards & {
        policyId: string,
    })[],
    snapshotTime: string,
    totalReward: number,
}

const handleResponse = (response: Response) => {
    if (response.ok) {
        return response
    } else {
        let err = new Error("HTTP status code: " + response.status)
        throw err
    }
}

export const getStakingSummary = async (walletAddress: string): Promise<StakingWalletResponse | null> => {
    return fetch(`${apiUrl}/api/staking/${walletAddress}`).then(response => response.status === 404 ? null : response.json())
}

export const claimReward = async (walletAddress: string, accountIds: string[], amount: number): Promise<string[]> => {
    return fetch(`${apiUrl}/api/rewards/${walletAddress}/withdraw`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ amount, accountIds, description: 'Rewards withdraw' })
    }).then(response => handleResponse(response).json())
}

export const cancelReward = async (walletAddress: string, txids: string[]): Promise<void> => {
    return Promise
        .all(txids.map(txid => fetch(`${apiUrl}/api/rewards/${walletAddress}/withdraw/${txid}`, { method: 'DELETE' })))
        .then(() => { })
}

export const startStaking = (walletAddress: string) => {
    return fetch(`${apiUrl}/api/staking`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ walletAddress }),
    })
}

export type SignedWalletRequest = {
    walletAddress: string,
    data: SignedData
}

export type SignedWalletRequestWithLedger = {
    txId: string
}

export const joinScholarship = (referal: string | undefined, token: string) => {
    return fetch(`${apiUrl}/api/game/join-scholarship`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ referal: referal })
    })
}

export const registerUser = (request: SignedWalletRequest, token: string) => {
    return fetch(`${apiUrl}/api/game/register`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(request)
    })
}

export const registerUserWithLedger = (request: SignedWalletRequestWithLedger, token: string) => {
    return fetch(`${apiUrl}/api/game/registerwithledger`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(request)
    })
}

export type AccountType = 'Wallet' | 'Scholarship'

export type GameResponse = {
    stakeAddress: string,
    balance: number,
    accountId: string,
    updatedAt?: string,
    assetsCount?: number,
    accountType: AccountType
}

export const getUser = (token: string) => {
    return fetch(`${apiUrl}/api/game`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then<GameResponse>(response => response.ok ? response.json() : Promise.resolve(undefined))
}

export type DinoSupply = {
    amount: number
}

export const getDinoCirculating = async (): Promise<DinoSupply> => {
    return fetch(`${apiUrl}/api/projectaccounting/dino/circulating`).then(response => response.json())
}

export type AccountData = {
    name: string,
    description: string,
    address: string,
    assets: AssetData[]
}

export type AssetData = {
    name: string,
    quantity: number,
    policyId: string
}

export const getProjectAccounting = async (): Promise<AccountData[]> => {
    return fetch(`${apiUrl}/api/projectaccounting`).then(response => response.json())
}

export type LeaderboardItem = {
    accountId: string,
    name: string | undefined,
    address: string,
    level: number,
    experience: number,
    total: number,
    delta: number,
}

export enum PlayerType {
    All = 'All',
    Rookies = 'Rookies'
}

export const getLeaderboard = async (playerType: PlayerType): Promise<LeaderboardItem[]> => {
    return fetch(`${apiUrl}/api/leaderboard?playerType=${playerType}`).then(response => response.json())
}

export type TokenLeaderboardItem = {
    assetId: string,
    assetType: 'Cryptodino' | 'Dinosavior',
    ipfs: string,
    name: string,
    level: number,
    experience: number
}

export const getTokenLeaderboard = async (): Promise<TokenLeaderboardItem[]> => {
    return fetch(`${apiUrl}/api/game-assets/leaderboard`).then(response => response.json())
}

export type LeaderboardSettings = {
    showName: boolean
}

export type LeaderboardDetails = {
    totalPosition?: number,
    dailyPosition?: number,
}

export type LeaderboardSettingsAndDetails = LeaderboardDetails & LeaderboardSettings

export const getLeaderboardDetails = (token: string) => {
    return fetch(`${apiUrl}/api/leaderboard/me`, {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then<LeaderboardSettingsAndDetails>(response => response.json())
}

export const updateLeaderboardSettings = async (settings: LeaderboardSettings, token: string) => {
    return fetch(`${apiUrl}/api/leaderboard/me`, {
        method: 'POST',
        body: JSON.stringify(settings),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
}

type AssetProgress = {
    assetId: string,
    level: number,
    experience: number,
}

export const getAssetProgress = async (assetId: string) => {
    return fetch(`${apiUrl}/api/game-assets/${assetId}/progress`).then<AssetProgress>(response => response.json())
}

export type SubscriptionFeatures = {
    userRatingEnabled: boolean,
    userAnalyticsEnabled: boolean,
    scholarship: boolean,
    active: boolean,
}

export const getSubscriptionFeatures = async (token: string) => {
    return fetch(`${apiUrl}/api/subscriptions/me`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    }).then<SubscriptionFeatures>(response => response.json());
}

export enum SubscriptionFeature {
    UserRating = 'UserRating',
    Scholarship = 'Scholarship',
    UserAnalytics = 'UserAnalytics',
}

export const postSubscriptionFeatures = async (feature: SubscriptionFeature, token: string) => {
    return fetch(`${apiUrl}/api/subscriptions/me`, {
        method: 'POST',
        body: JSON.stringify(feature),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
}

export const activateSubscription = async (token: string) => {
    return fetch(`${apiUrl}/api/subscriptions/activate`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
}

export type ScholarshipAsset = {
    assetId: string,
    name: string,
    hash: string,
    rewardSharingRatio: number,
}

export const getScholarshipAssets = (token: string) => {
    return fetch(`${apiUrl}/api/scholarship-assets`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then<ScholarshipAsset[]>(response => response.json())
}

export type PutScholarship = {
    rewardSharingRatio: number
}

export const putScholarshipAsset = (assetId: string, rewardSharingRatio: number, token: string) => {
    return fetch(`${apiUrl}/api/scholarship-assets/${assetId}`, {
        method: 'PUT',
        body: JSON.stringify({ rewardSharingRatio }),
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
        }
    });
}

export type GameAnalyticsItem = {
    date: string,
    expeditionCount: number,
    expeditionRewards: number,
}

export const getGameAnalytics = (token: string) => {
    return fetch(`${apiUrl}/api/reward-analytics/me`, {
        method: 'GET',
        headers: {
            'Authorization': `Bearer ${token}`
        }
    }).then<GameAnalyticsItem[]>(response => response.json())
}