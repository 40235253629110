import React from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import * as Api from '../Api'
import { CollectionKeys, Collections, collections } from '../collections'
import { Data } from '../components/useData'
import { GalleryTile, GalleryTileSkeleton } from './GalleryTile'
import { AssetsOrder } from './SortButton'

export type AssetFilter = {}

export type AssetItemProps = {
    value: Api.ComposedAsset,
    collection: keyof Collections,
}

type GalleryProps = {
    collection: CollectionKeys,
    data: InfiniteScrollData<Api.ComposedAsset>
    component?: React.FC<AssetItemProps>,
    scrollableTarget?: React.ReactNode | string,
    noItemsComponent?: React.ReactNode,
    loadingComponent?: React.ReactNode,
}

type InfiniteScrollData<T> = Data<T[]> & {
    fetchNext: () => any,
    hasMore: boolean,
}

export const useAssetItems = (collection: CollectionKeys, { filter, assetNames, onSale = false, order = 'asset.number' }: { filter?: AssetFilter, assetNames?: string[], onSale?: boolean, order?: AssetsOrder }) => {

    const collectionId = collections[collection].collectionId
    const [assets, setAssets] = React.useState<Api.ComposedAsset[]>([])
    const [page, setPage] = React.useState(1)
    const [inProgress, setInProgress] = React.useState(false)
    const [hasMore, setHasMore] = React.useState(true)

    const getAssets = React.useCallback((page, pageSize) => {
        if (assetNames && assetNames.length === 0) {
            return Promise.resolve([])
        }

        return Api.explore(collectionId, filter, assetNames, order, onSale, { page, pageSize })
    }, [collectionId, filter, assetNames, onSale, order])

    const fetchNext = React.useCallback((): any => {
        setInProgress(true)
        getAssets(page + 1, 15).then((result) => {
            setAssets([...assets, ...result])
            setPage(page + 1)
            setInProgress(false)
            const hasMoreData = result.length === 15
            setHasMore(hasMoreData)
        })
    }, [getAssets, page, assets])

    React.useEffect(() => {
        setInProgress(true)
        getAssets(1, 30).then((result) => {
            setAssets(result)
            setPage(2)
            setHasMore(result.length === 30)
            setInProgress(false)
            //setTotalSize(result.count)
        })
    }, [getAssets])
    
    return React.useMemo<InfiniteScrollData<Api.ComposedAsset>>(() => ({
        inProgress,
        data: assets,
        fetchNext,
        hasMore,
        refresh: () => Promise.resolve(),
    }), [inProgress, assets, fetchNext, hasMore]);
}

const GallerySkeleton = ({ component: Item }: { component: React.FC<any> }) => {
    return (
        <div className="asset-gallery-container">
            {Array(10).fill(0).map((_, index) => <Item key={index} />)}
        </div>
    )
}

export const Gallery = ({ collection, data, component, scrollableTarget, noItemsComponent, loadingComponent }: GalleryProps) => {

    const Tile = component || GalleryTile
    const LoadingTile = GalleryTileSkeleton

    return (
        <React.Fragment>
            <InfiniteScroll
                dataLength={data.data?.length || 0}
                next={data.fetchNext}
                hasMore={data.hasMore}
                loader={<GallerySkeleton component={LoadingTile} />}
                scrollableTarget={scrollableTarget}
            >
                {!data.inProgress && data.data?.length === 0 && noItemsComponent ? noItemsComponent : (
                    <div className="asset-gallery-container">
                        {data.data?.map((asset) => (
                            <div key={asset.asset.assetId}>
                                <Tile value={asset} collection={collection} />
                            </div>
                        ))}
                    </div>
                )}
            </InfiniteScroll>
        </React.Fragment >
    )
}