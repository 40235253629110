import {
    PlutusList,
    PlutusData,
    BigNum,
    BigInt,
    ConstrPlutusData,
    Int} from '../custom_modules/@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib'

export const toHex = (bytes: Uint8Array) => Buffer.from(bytes).toString("hex");
export const fromHex = (hex: string) => Buffer.from(hex, "hex");
export const toByteArray = (name: string) => Buffer.from(name, "utf8");
export const toBigNum = (value: any) => {
    return BigNum.from_str(value.toString()) as BigNum;
}

export const toPlutusData = (plutusDataObj: PlutusDataObject) => {
    const datumFields = PlutusList.new();
    plutusDataObj.fields.sort((a, b) => a.index - b.index);
    plutusDataObj.fields.forEach(f => {
        switch (f.type) {
            case PlutusFieldType.Integer:
                datumFields.add(PlutusData.new_integer(BigInt.from_str(f.value.toString())));
                break;
            case PlutusFieldType.Data:
                datumFields.add(toPlutusData(f.value));
                break;
            case PlutusFieldType.Bytes:
                datumFields.add(PlutusData.new_bytes(f.value));
                break;
        }
    })

    return PlutusData.new_constr_plutus_data(
        ConstrPlutusData.new(
            Int.new_i32(plutusDataObj.constructorIndex),
            datumFields
        )
    );
}

export type PlutusDataObject = {
    constructorIndex: number,
    fields: PlutusField[],
}

export enum PlutusFieldType {
    Data = 0,
    Integer = 1,
    String = 2,
    Bytes = 3
}

export type PlutusField = {
    index: number,
    type: PlutusFieldType,
    key: string,
    value: any
}

export interface AssetDetails {
    policyIdInHex: string,
    assetNameInHex: string,
    amount: any
}