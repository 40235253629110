import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Link } from 'react-router-dom';
import { useCardanoWalletProvider, useWallets } from '../cardano/CardanoWalletProvider';
import { Button } from '../components/Button';
import { PageTemplate } from '../components/PageTemplate';
import { ReactComponent as CheckIcon } from './Check.svg'
import './Download.scss'
import { useGame, useVerifyWallet, useVerifyWalletForLedger } from './Game';
import { ReactComponent as Spinner } from '../components/Spinner.svg'
import { ConnectButton } from '../roadmap/Menu';
import { usePolling } from '../usePolling';
import { AccountType, getUser, joinScholarship } from '../Api';
import { ReactComponent as WindowsLogo } from './Windows_logo.svg';
import { ReactComponent as MacOSLogo } from './MacOS_logo.svg';
import { isScholarshipEnabled } from '../configProvider';

type OperatingSystem = 'Win' | 'MacOS' | 'Other'

const useOperatingSystem = () => {
    return React.useMemo<OperatingSystem>(() => {
        return navigator.appVersion.search('Win') ? 'Win' :
            navigator.appVersion.indexOf('Mac') ? 'MacOS' :
                'Other'
    }, [])
}

const DownloadGame = () => {
    return (
        <div>
            <div className="download">
                <div>
                    <div><WindowsLogo style={{ width: 200, height: 60 }} /></div>
                    <div>
                        <a href="https://drive.google.com/file/d/1trBvWASOF_siZ5wT3zEh37ijJA0IpZLa/view?usp=sharing" target="_blank">Download for Windows 10/11</a>
                    </div>
                </div>
                <div>
                    <div><MacOSLogo style={{ width: 200, height: 60 }} /></div>
                    <div><a href="https://drive.google.com/file/d/1YNWt_2I1cGKCmq6wEv3l1lQjorImLllA/view?usp=sharing" target="_blank">Download for macOS</a></div>
                </div>
            </div>
            <h3>How to run it</h3>
            <div className="download list">
                <div>
                    <ol>
                        <li>Extract content to the folder</li>
                        <li>Find CryptoDino.exe and run the game</li>
                        <li>At this moment you might see message: "Window protected your PC". This is the typical warning for software downloaded from internet. To continue click "More info" and then "Run anyway". After that this warning will never show again.</li>
                        <li>Enjoy the game</li>
                    </ol>
                </div>
                <div>
                    <ol>
                        <li>Extract content to the folder</li>
                        <li>Find cryptodino.app and control-click the app icon, then choose Open from the shortcut menu.</li>
                        <li>Click Open.</li>
                        <li>Enjoy the game</li>
                    </ol>
                </div>
            </div>
        </div>
    )
}

export type Steps = {
    [key: string]: {
        condition?: boolean,
        done: boolean,
    }
}

const getSteps = (steps: Steps) => Object
    .entries(steps)
    .filter(([key, value]) => value.condition === undefined || value.condition)
    .map(([key]) => key)

const getActiveStep = (steps: Steps) => {
    const stepNames = getSteps(steps)
    for (var i = 0; i < stepNames.length; i++) {
        const stepName = stepNames[i]
        if (!steps[stepName].done) {
            return stepName
        }
    }
}

const isStepDone = (stepNames: string[], activeStep: string, stepName: string) => {
    return stepNames.indexOf(stepName) < stepNames.indexOf(activeStep)
}

export const Step: React.FC<{ name: string, steps: Steps, title: string }> = ({ name, steps, title, children }) => {
    const Check = () => (<CheckIcon className="check" />)
    const stepNames = getSteps(steps)
    const activeStep = getActiveStep(steps)
    const done = isStepDone(stepNames, activeStep!, name)
    const active = name === activeStep
    const number = stepNames.indexOf(name) + 1

    if (steps[name].condition === false) {
        return (<></>)
    }

    return (
        <div className={`step ${done && 'done'} ${active && 'active'}`}>
            <div className="step-header">
                {done ? <Check /> : <div className="step-number">{number}</div>}
                <div>{title}</div>
            </div>
            {active && <div className="step-content">{children}</div>}
        </div>
    )
}



export const Download = () => {

    const { walletAddress, connected } = useCardanoWalletProvider()

    const { user, isAuthenticated, signinPopup } = useAuth()

    const { data, refresh } = useGame(user?.access_token)

    const [txSubmited, setTxSubmited] = React.useState(false)

    const [accountType, setAccountType] = React.useState<AccountType>()
    React.useEffect(() => {
        if (data) {
            setAccountType(data.accountType)
        }
    }, [data])

    const steps = React.useMemo(() => ({
        'login': {
            done: isAuthenticated,
        },
        'accountType': {
            done: Boolean(accountType),
        },
        'connectWallet': {
            condition: accountType === 'Wallet',
            done: connected,
        },
        'verifyWallet': {
            condition: accountType === 'Wallet',
            done: Boolean(data?.stakeAddress),
        },
        'syncWallet': {
            condition: accountType === 'Wallet',
            done: Boolean(data?.updatedAt && data?.assetsCount && data?.assetsCount > 0)
        },
        'initializeScholarshipAccount': {
            condition: accountType === 'Scholarship',
            done: Boolean(data?.updatedAt && data?.assetsCount && data?.assetsCount > 0)
        },
        'download': {
            done: false
        }
    }), [isAuthenticated, data?.stakeAddress, data?.updatedAt, data?.assetsCount, connected, accountType])

    const verifyWallet = useVerifyWallet(walletAddress, user?.access_token)
    const onVerifyClick = React.useCallback(() => {
        return verifyWallet().then(() => refresh())
    }, [verifyWallet, refresh])


    const verifyWalletWithLedger = useVerifyWalletForLedger(walletAddress, user)
    const onVerifyWithLedgerClick = React.useCallback(() => {
        return verifyWalletWithLedger().then(() => setTxSubmited(true))
    }, [verifyWalletWithLedger])

    const onChooseRegularAccountClick = React.useCallback(() => {
        setAccountType('Wallet')
    }, [])
    const onChooseScholarshipAccountClick = React.useCallback(() => {
        if (user) {
            return joinScholarship(undefined, user?.access_token).then(() => refresh())
        }
    }, [user, refresh])

    const pollingCallback = React.useCallback(async () => {
        if (user?.access_token) {
            await refresh()
        }
    }, [user, refresh])
    usePolling(pollingCallback, 10_000, Boolean(user?.access_token) && (data && !data.updatedAt) || (!data && txSubmited))


    const wallets = useWallets()

    return (
        <PageTemplate collection="cryptodinos" title={(<><big>P</big>lay the game</>)}>
            <div style={{ width: 800, margin: '0 auto' }}>
                <Step name="login" steps={steps} title="Log in">
                    <p>
                        Log in using one of supported accounts. This ensures secure access to the game while safeguarding your sensitive information.
                    </p>
                    <Button onPress={() => signinPopup().then(() => { })}>Sign in</Button>
                </Step>
                <Step name="accountType" steps={steps} title="Choose account type">
                    <p>
                        Which account type would you like to use?
                    </p>
                    <div className="account-type-choice">
                        <div>
                            <h3>Regular</h3>
                            <p>
                                Unleash the full power of your Cardano wallet! Play with your own CryptoDino assets and enjoy full rewards.
                            </p>
                            <Button onPress={onChooseRegularAccountClick}>Choose</Button>
                        </div>
                        <div>
                            <h3>Scholarship</h3>
                            <p>
                                No wallet needed! Dive into CryptoDino with 3 shared assets. Rewards are split between you and the original owners. Join the community, have fun, and let the shared journey begin!
                            </p>
                            {isScholarshipEnabled() ? <Button onPress={onChooseScholarshipAccountClick}>Choose</Button> : <Button onPress={() => { }} disabled={true}>Coming Soon</Button>}
                        </div>
                    </div>
                </Step>
                <Step name="connectWallet" steps={steps} title="Connect wallet">
                    {wallets.length > 0 ? (
                        <>
                            <p>
                                Link your Cardano wallet to the website. This will enable the website to interact with your wallet and activate features such as Breeding, Staking, and exploring your wallet's assets.
                            </p>
                            <ConnectButton className="button">Connect wallet</ConnectButton>
                        </>) : (
                        <>
                            <p>
                                It seems you don't have a Cardano wallet yet. No problem - let's get you started with the popular and user-friendly Nami wallet. <a href="https://namiwallet.io/" target="_blank">Download it</a>, set it up, and come back to continue.
                            </p>
                        </>
                    )
                    }
                </Step>
                <Step name="verifyWallet" steps={steps} title="Associate wallet with Discord account">
                    <p>
                        Confirm ownership of your linked wallet by signing the provided message. This step verifies that you are the sole owner of the wallet, granting you access to its assets.
                    </p>
                    {txSubmited && (
                        <div className="sync-wallet">
                            <Spinner className="spinner" />
                        </div>
                    )}
                    {!txSubmited && (
                        <>
                            <Button onPress={onVerifyClick}>Verify wallet</Button>
                            <Button onPress={onVerifyWithLedgerClick}>Verify wallet with ledger</Button>
                            <div className="warning">
                                Note: Some hardware wallets do not currently support the data signing functionality of the Cardano wallet.
                            </div>
                        </>
                    )}
                </Step>
                <Step name="syncWallet" steps={steps} title="Synchronize wallet">
                    {data?.assetsCount === 0 ? (
                        <>
                            <p>
                                Your wallet has been synchronized, but it seems there are no assets available for you to use. To acquire some, you can visit jpg.store.
                                The great news is, you don't have to use ADA for payment; you can choose to use alternative cryptocurrencies or even a credit card.
                            </p>
                            <div>
                                <a className="button" href="https://www.jpg.store/collection/cryptodinonextgenerations?tab=items" target="_blank">Go to jpg.store</a>
                            </div>
                        </>
                    ) : (
                        <>
                            <p>
                                We're almost there. Please give us a moment to synchronize your wallet with the game.
                            </p>
                            <div className="sync-wallet">
                                <Spinner className="spinner" />
                            </div>
                        </>
                    )}
                </Step>
                <Step name="initializeScholarshipAccount" steps={steps} title="Initializing account">

                    <p>
                        We're almost there. Please give us a moment to initialize your game account.
                    </p>
                    <div className="sync-wallet">
                        <Spinner className="spinner" />
                    </div>
                </Step>
                <Step name="download" steps={steps} title="Download the game">
                    <p>
                        Everything is set up. Now, simply download the game using the link below, extract the contents of the package, and immerse yourself in the game to experience its features and gameplay.
                    </p>
                    <DownloadGame />
                </Step>
            </div >
        </PageTemplate >
    )
}