import React from 'react';
import './App.scss';
import { RoadmapCarousel } from './roadmap/RoadmapCarousel'
import { NotificationProvider } from './components/NotificationProvider'
import { ToastContainer } from './components/Toast'
import { Layout } from './layout/Layout'

function App() {
    return (
        <div className="App">
            <NotificationProvider component={ToastContainer}>
                <Layout />
            </NotificationProvider>
        </div>
    );
}

export default App;
