import React from 'react'
import { ReactComponent as CloseIcon } from '../components/Close.svg'
import { OverlayContainer, useModal, useOverlay, usePreventScroll } from '@react-aria/overlays';
import { useDialog } from '@react-aria/dialog';
import { FocusScope } from '@react-aria/focus';
import './AdBanner.scss'
import { useOverlayTriggerState } from '@react-stately/overlays';

const canShowAd = (name: string) => {
    const adSettings = localStorage.getItem('ad:settings')
    return adSettings === undefined || adSettings !== name
}

const setHideAd = (name: string) => {
    localStorage.setItem('ad:settings', name)
}

export type BannerProps = {
    close: () => void
}

export type AdBannerDialogProps = {
    adName: string,
    banner: React.ComponentType<BannerProps>
}

export const AdBanner: React.FC<AdBannerDialogProps> = (props) => {

    const state = useOverlayTriggerState({
        defaultOpen: canShowAd(props.adName),
        onOpenChange: isOpen => {
            if (!isOpen) {
                setHideAd(props.adName)
            }
        }
    })

    return (
        <>
            {state.isOpen && (
                <OverlayContainer>
                    <AdBannerDialog {...props} onClose={state.close} />
                </OverlayContainer>
            )
        }
        </>
    )
}

export const AdBannerDialog: React.FC<AdBannerDialogProps & { onClose: () => void }> = ({ adName, banner: Banner, onClose }) => {


    const zIndex = 100
    // Handle interacting outside the dialog and pressing
    // the Escape key to close the modal.
    let ref = React.useRef(null);
    let { overlayProps, underlayProps } = useOverlay(
        {},
        ref
    );

    // Prevent scrolling while the modal is open, and hide content
    // outside the modal from screen readers.
    usePreventScroll();
    let { modalProps } = useModal();

    // Get props for the dialog and its title
    const { dialogProps, titleProps } = useDialog({}, ref);

    return (
        <div
            style={{
                position: 'fixed',
                zIndex,
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}
            {...underlayProps}>
            <FocusScope contain restoreFocus autoFocus>
                <div
                    {...overlayProps}
                    {...dialogProps}
                    {...modalProps}
                    ref={ref}
                    className="ad-dialog">
                    <button className="close-button" onClick={onClose}>
                        <CloseIcon />
                    </button>
                    <Banner close={onClose} />
                </div>
            </FocusScope>
        </div>
    );
}