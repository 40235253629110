import { Address, AuxiliaryData, BigNum, GeneralTransactionMetadata, MetadataJsonSchema, Transaction, TransactionBuilder, TransactionOutputBuilder, TransactionUnspentOutputs, TransactionWitnessSet, Value, encode_json_str_to_metadatum } from '@emurgo/cardano-serialization-lib-browser'
import { CardanoApi, SignedData, containsMinLovelace } from '../nami'
import { getProtocolProtocolParams } from './blockfrost'
import { cardanoApiAdapter, handleSignError } from './cardano-adapter'
import { createTransactionBuilder, setupCoinSelector, splitAddress, toBigNum } from './plutus-helpers'
import { retry } from 'ts-retry'
import { appInsights } from '../AppInsights'
import { BrowserWallet } from '@meshsdk/core'

export const signPayload = async (api: BrowserWallet, payload: string): Promise<SignedData> => {
    const cardano = cardanoApiAdapter(api)
    const selfAddress = (await cardano.getWalletAddresses())[0]
    var res = await cardano.signData(selfAddress, payload)
    return res
}

export const getSigningAddress = async (api: BrowserWallet): Promise<string> => {
    const cardano = cardanoApiAdapter(api)
    return (await cardano.getWalletAddress()).to_bech32()
}

export const signPayloadWithLedger = async (api: BrowserWallet, walletAddress: string, userId: string): Promise<string> => {
    const cardano = cardanoApiAdapter(api)
    const protocolParameters = await getProtocolProtocolParams()
    setupCoinSelector(protocolParameters)

    const txBuilder = await createTransactionBuilder(protocolParameters)
    const selfAddress = await cardano.getWalletAddress()
    const selfPaymentAddress = Address.from_bech32(walletAddress) 

    let txOutputBuilder = TransactionOutputBuilder.new();
    txOutputBuilder = txOutputBuilder
        .with_address(selfPaymentAddress)
    const txOutputAmountBuilder = txOutputBuilder.next();
    const lovelaceValue = Value.new(toBigNum("1000000"))
    const txOutputAmountBuilderWithCoins = txOutputAmountBuilder.with_value(lovelaceValue)

    const aux_data: AuxiliaryData = await addSelfPaymentMetadata(txBuilder, walletAddress, userId)

    const txOutput = txOutputAmountBuilderWithCoins.build();
    txBuilder.add_output(txOutput)

    const txUnspentOutputs  = TransactionUnspentOutputs.new();
    const walletOutputs  = await cardano.getUtxos();

    const adaOnlyOutputs = walletOutputs.filter(x => containsMinLovelace(x, 5_000_000))
    if (adaOnlyOutputs !== undefined && adaOnlyOutputs.length > 0) {
        console.log(`found ADA only unspent outputs good for this transaction`)
        adaOnlyOutputs.forEach(utxo => txUnspentOutputs.add(utxo))
    } else {
        walletOutputs.forEach(utxo => txUnspentOutputs.add(utxo))
    }

    await retry(() => {
        try {
            txBuilder.add_inputs_from(txUnspentOutputs, 0)
        }
        catch (err) {
            console.log(err)
            txBuilder.add_inputs_from(txUnspentOutputs, 1)
        }
    }, { maxTry: 10 })
    
    txBuilder.add_change_if_needed(selfAddress)
    const tx = txBuilder.build_tx();
    const transactionWitnessSet = TransactionWitnessSet.from_bytes(tx.witness_set().to_bytes());
    let signedtxVkeyWitnesses: TransactionWitnessSet
    try {
        signedtxVkeyWitnesses = await cardano.signTx(tx, true);
    }
    catch (err) {
        throw handleSignError(err)
    }

    transactionWitnessSet.set_vkeys(signedtxVkeyWitnesses.vkeys()!);
    const signedTx = Transaction.new(
        tx.body(),
        transactionWitnessSet,
        aux_data
    );

    try {
        const txHash = await cardano.submitTx(signedTx);
        console.log(`txHash: ${txHash} for ledger sign for address: ${walletAddress}`);
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            requestor: selfAddress.to_bech32(),
            userid: userId,
            method: "signwithledger"
        });
        throw err;
    }
}

const addSelfPaymentMetadata = async (txBuilder: TransactionBuilder, walletAddress: string, userId: string) => {
    const aux_data: AuxiliaryData = AuxiliaryData.new();
    const generalTransactionMetadata: GeneralTransactionMetadata = GeneralTransactionMetadata.new();
    const meta: SelfPaymentMetadata = {
        address: splitAddress(walletAddress),
        userid: userId
    };

    const metadata = encode_json_str_to_metadatum(JSON.stringify(meta), MetadataJsonSchema.BasicConversions);
    generalTransactionMetadata.insert(BigNum.from_str("611"), metadata)
    aux_data.set_metadata(generalTransactionMetadata)
    txBuilder.set_auxiliary_data(aux_data)
    return aux_data
}

export interface SelfPaymentMetadata {
    address: string[],
    userid: string
}