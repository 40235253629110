import * as Api from '../Api'

export const AccountingDetails = ({ account }: { account: Api.AccountData }) => {
    return (
        <div className="accounting-details panel" key={account.name}>
            <h2>{account.name}</h2>
            <p>{account.description}</p>
            <pre>{account.address}</pre>
                {account.assets.map(asset => <AssetDetails asset={asset} key={account.name + asset.name + asset.policyId} />)}
            <br />
        </div>
    )
}

const AssetDetails = ({ asset } : { asset: Api.AssetData }) => {
    return (
        <div>
            {asset.quantity / 1_000_000} {asset.name == "lovelace" ? "ADA" : asset.name}
        </div>
    )
}