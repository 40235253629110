import React from 'react'
import { useMenuTriggerState } from '@react-stately/menu';
import { useButton } from '@react-aria/button';
import { useMenu, useMenuItem, useMenuTrigger } from '@react-aria/menu';
import { useTreeState } from '@react-stately/tree';
import { Item } from '@react-stately/collections';
import { mergeProps } from '@react-aria/utils';
import { FocusScope } from '@react-aria/focus';
import { useFocus } from '@react-aria/interactions';
import { useOverlay, DismissButton } from '@react-aria/overlays';
import { useLink, AriaLinkOptions } from '@react-aria/link'
import { Link, useHistory } from 'react-router-dom'
import './SortButton.scss'
import { ReactComponent as ArrowDown } from './arrow-down.svg';
import { ReactComponent as ArrowUp } from './arrow-up.svg';

const MenuButton = (props: any) => {
    // Create state based on the incoming props
    let state = useMenuTriggerState(props);

    // Get props for the menu trigger and menu elements
    let ref = React.useRef(null);
    let { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref);

    // Get props for the button based on the trigger props from useMenuTrigger
    let { buttonProps } = useButton(menuTriggerProps, ref);

    return (
        <div style={{ position: 'relative', display: 'inline-block' }}>
            <span {...buttonProps} className="sort-trigger" ref={ref}>
                {props.label} {state.isOpen ? <ArrowUp /> : <ArrowDown />}
            </span>
            {state.isOpen && (
                <MenuPopup
                    {...props}
                    domProps={menuProps}
                    autoFocus={state.focusStrategy}
                    onClose={() => state.close()}
                />
            )}
        </div>
    );
}

const MenuPopup = (props: any) => {
    // Create menu state based on the incoming props
    let state = useTreeState({ ...props, selectionMode: 'none' });

    // Get props for the menu element
    let ref = React.useRef(null);
    let { menuProps } = useMenu(props, state, ref);

    // Handle events that should cause the menu to close,
    // e.g. blur, clicking outside, or pressing the escape key.
    let overlayRef = React.useRef(null);
    let { overlayProps } = useOverlay(
        {
            onClose: props.onClose,
            shouldCloseOnBlur: true,
            isOpen: true,
            isDismissable: true
        },
        overlayRef
    );

    // Wrap in <FocusScope> so that focus is restored back to the
    // trigger when the menu is closed. In addition, add hidden
    // <DismissButton> components at the start and end of the list
    // to allow screen reader users to dismiss the popup easily.
    return (
        <FocusScope restoreFocus>
            <div {...overlayProps} ref={overlayRef} className="sort-menu">
                <DismissButton onDismiss={props.onClose} />
                <ul
                    {...mergeProps(menuProps, props.domProps)}
                    ref={ref}
                    style={{
                        position: 'absolute',
                        margin: '4px 0 0 0',
                        listStyle: 'none',
                    }}>
                    {[...state.collection].map((item) => (
                        <MenuItem
                            key={item.key}
                            item={item}
                            state={state}
                            onAction={props.onAction}
                            onClose={props.onClose}
                        />
                    ))}
                </ul>
                <DismissButton onDismiss={props.onClose} />
            </div>
        </FocusScope>
    );
}

const MenuItem = ({ item, state, onAction, onClose }: any) => {

    // Get props for the menu item element
    const ref = React.useRef(null);
    const { menuItemProps } = useMenuItem(
        {
            key: item.key,
            isDisabled: item.isDisabled,
            onAction,
            onClose
        },
        state,
        ref
    );

    // Handle focus events so we can apply highlighted
    // style to the focused menu item
    let [isFocused, setFocused] = React.useState(false);
    let { focusProps } = useFocus({ onFocusChange: setFocused });
    const { linkProps } = useLink({} as AriaLinkOptions, ref)
    return (
        <li role="none">
            <Link ref={ref} {...mergeProps(menuItemProps, focusProps, linkProps)} to={item.key}>
                {item.rendered}
            </Link>
        </li>
    );
}

const AssetsOrderLabels = {
    'asset.number': 'Number',
    'asset.rank': 'Rank',
    'offer.price-asc': 'Price: Low to High',
    'offer.price-desc': 'Price: High to Low',
}

export type AssetsOrder = keyof typeof AssetsOrderLabels


export const SortAssetsButton = ({ order, onOrderChange, onSale }: { order: AssetsOrder, onOrderChange: (order: AssetsOrder) => void, onSale: boolean }) => {
    return (
        <MenuButton label={AssetsOrderLabels[order]} onAction={onOrderChange}>
            {Object.entries(AssetsOrderLabels).filter(([key]) => onSale || key.indexOf('price') === -1).map(([key, text]) => <Item key={key}>{text}</Item>)}
        </MenuButton>
    )
}
