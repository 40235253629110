import * as ConfigProvider from '../configProvider'
import { appInsights } from '../AppInsights' 
import { getApiUrl } from '../configProvider'
import { BrowserWallet, Transaction } from '@meshsdk/core'

const claimingTransferFixed = "2000000";

const getClaimableAmountPerAddress = (address: string) => {
    return fetch(getApiUrl() + `/api/assetcoinsclaiming/address/${address}`).then<ClaimableData>(response => response.json())
}

export const canClaimDinoCoinForAssets = async (wallet: BrowserWallet): Promise<Boolean> => {
    const selfAddress = await wallet.getUsedAddress()
    var res = await getClaimableAmountPerAddress(selfAddress.to_bech32())
    if (res.amount > 0) {
        console.log(`canClaimDinoCoinForAssets selfAddress ${selfAddress.to_bech32()} ammount: ${res.amount}`)
        return true
    } else {
        return false
    }
}

export const claimDinoCoinForAssets = async (wallet: BrowserWallet): Promise<string> => {
    const selfAddress = await wallet.getUsedAddress()
    const dinoCoinClaimAddress = ConfigProvider.getDinoCoinClaimingAddress()

    const tx = new Transaction({ initiator: wallet })
        .sendLovelace(dinoCoinClaimAddress, claimingTransferFixed.toString())

    const meta : ClaimDinoCoinsMetadata = { 
        lvlAmount: claimingTransferFixed,
        dcAmount: "0",
        op: "nft"
    }

    tx.setMetadata(505, meta);

    try {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`txHash for claiming dino coins: ${txHash} requestor: ${selfAddress.to_bech32()}`);
        appInsights.trackTrace({ message: 'dinocoinclaim' }, {
            requestor: selfAddress.to_bech32(),
            txHash: txHash,
            method: "dinocoinclaim"
        });
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            requestor: selfAddress.to_bech32(),
            method: "dinocoinclaim"
        });
        throw err;
    }
}

export const claimDinoCoin = async (wallet: BrowserWallet, paymentAddress: string, meta: ClaimDinoCoinsMetadata): Promise<string> => {
    const selfAddress = await wallet.getUsedAddress()
    const lovelaceWithFee = parseInt(meta.lvlAmount) + parseInt(claimingTransferFixed)

    const tx = new Transaction({ initiator: wallet })
        .sendLovelace(paymentAddress, lovelaceWithFee.toString())

    tx.setMetadata(505, meta);

    try {
        const unsignedTx = await tx.build();
        const signedTx = await wallet.signTx(unsignedTx);
        const txHash = await wallet.submitTx(signedTx);
        console.log(`txHash for ${meta.op} claiming dino coins: ${txHash} requestor: ${selfAddress.to_bech32()}`);
        appInsights.trackTrace({ message: 'dinocoinclaim' }, {
            requestor: selfAddress.to_bech32(),
            txHash: txHash,
            method: "dinocoinclaim"
        });
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            requestor: selfAddress.to_bech32(),
            method: "dinocoinclaim"
        });
        throw err;
    }
}

type ClaimableData = {
    amount: number,
    assetIds: string[]
} 

export interface ClaimDinoCoinsMetadata {
    lvlAmount: string,
    dcAmount: string,
    op: string
}