import React, { useEffect } from 'react'
import { useAuth } from 'react-oidc-context'
import { SignedWalletRequest, registerUser, getUser, SignedWalletRequestWithLedger, registerUserWithLedger, LeaderboardSettings, updateLeaderboardSettings, getLeaderboardDetails, getSubscriptionFeatures, SubscriptionFeature, LeaderboardDetails } from '../Api'
import { useCardanoWalletProvider } from '../cardano/CardanoWalletProvider'
import { Button } from '../components/Button'
import { signPayload, signPayloadWithLedger } from '../cardano/signingManager'
import { useData } from '../components/useData'
import DinoCoinIconSrc from '../breeding/DinoCoin_78x78.png'
import { ClaimRewardButton, TopUpButton } from './ClaimRewardDialog'
import { User } from 'oidc-client-ts';
import { Switch } from '../components/Switch'
import { EnableFeatureDialog } from './EnableFeatureDialog'
import { ExpeditionsChart } from './ExpeditionsChart'

export const useGame = (token: string | undefined) => {
    const getData = React.useCallback(() => token ? getUser(token) : Promise.resolve(undefined), [token])
    return useData(getData)
}

export const useSubscriptionFeatures = (token: string | undefined) => {
    const getData = React.useCallback(() => token ? getSubscriptionFeatures(token) : Promise.resolve(undefined), [token])
    return useData(getData)
}

export const useVerifyWallet = (walletAddress: string | undefined, accessToken: string | undefined) => {
    const { getCardano } = useCardanoWalletProvider()
    return React.useCallback(async () => {
        if (walletAddress && accessToken) {
            const signedPayload = await signPayload(getCardano(), JSON.stringify({ address: walletAddress }))
            const request: SignedWalletRequest = {
                walletAddress: walletAddress,
                data: signedPayload
            }
            await registerUser(request, accessToken)
        }
    }, [accessToken, walletAddress, getCardano])
}

export const useVerifyWalletForLedger = (walletAddress: string | undefined, authenticatedUser: User | null | undefined) => {
    const { getCardano } = useCardanoWalletProvider()
    return React.useCallback(async () => {
        if (walletAddress && authenticatedUser) {
            const txId = await signPayloadWithLedger(getCardano(), walletAddress, authenticatedUser.profile.sub!)
            const request: SignedWalletRequestWithLedger = {
                txId: txId
            }
            await registerUserWithLedger(request, authenticatedUser.access_token)
        }
    }, [authenticatedUser, walletAddress, getCardano])
}

export const Game = () => {
    const auth = useAuth()
    const { walletAddress } = useCardanoWalletProvider()

    const login = React.useCallback(() => {
        auth.signinPopup()
    }, [auth.signinPopup])

    const signUp = useVerifyWallet(walletAddress, auth.user?.access_token)

    const { data: user, inProgress: userLoading, refresh } = useGame(auth.user?.access_token)

    const dinoCoinsBalance = React.useMemo(() => {
        const intl = Intl.NumberFormat()
        return user ? intl.format(user.balance / 1000000) : 0
    }, [user])

    const [details, setDetails] = React.useState<LeaderboardDetails>()
    const [settings, setSettings] = React.useState<LeaderboardSettings>({
        showName: false
    })
    const saveSettings = React.useCallback((setting: keyof LeaderboardSettings) => {
        return (isSelected: boolean) => {
            const newSettings = {
                ...settings,
                [setting]: isSelected
            }
            setSettings(newSettings);
            return updateLeaderboardSettings(newSettings, auth.user?.access_token!)
        }
    }, [settings, auth.user])

    const { data: features, refresh: refreshFeatures } = useSubscriptionFeatures(auth.user?.access_token)

    const loadLeaderboardDetails = React.useCallback(() => {
        if (auth.user?.access_token) {
            return getLeaderboardDetails(auth.user?.access_token).then(({ totalPosition, dailyPosition, ...settings }) => {
                setSettings(settings)
                setDetails({ totalPosition, dailyPosition })
            })
        }
    }, [auth.user])

    const onFeatureEnabled = React.useCallback(async () => {
        await refresh()
        await loadLeaderboardDetails()
        await refreshFeatures()
    }, [refresh, refreshFeatures, loadLeaderboardDetails])

    useEffect(() => {
        loadLeaderboardDetails()
    }, [loadLeaderboardDetails])

    if (auth.isAuthenticated && user) {
        return (
            <>
                <div className="panel">
                    <div>
                        <div>Welcome {auth.user?.profile.name}</div>
                    </div>
                    {user.stakeAddress ? (
                        <>
                            <div className="panel-section">
                                <div>Wallet address: {user.stakeAddress}</div>
                            </div>
                            <div className="panel-section">
                                <h3 style={{ fontSize: 24 }}>
                                    <span style={{ fontWeight: 400, marginRight: 10 }}>Total amount:</span><span>{dinoCoinsBalance}</span>
                                    <img src={DinoCoinIconSrc} style={{ height: 32, verticalAlign: 'middle', marginLeft: 5 }} alt="DINO" title='DINO' />
                                </h3>
                                {walletAddress && <TopUpButton onComplete={refresh} />}
                                {walletAddress && <ClaimRewardButton walletAddress={walletAddress} accountIds={[user.accountId]} onComplete={refresh} max={user.balance ?? 0} />}
                            </div>
                            
                        </>
                    ) : (
                        <div>
                            <Button onPress={signUp}>Assign wallet to your game account</Button><br />
                        </div>
                    )}

                </div>
                <div className="panel">
                    <div className="panel-section">
                        {features?.userAnalyticsEnabled ? (
                            <>
                                <h3>Performance</h3>
                                <ExpeditionsChart />
                            </>
                        ): (
                            <>
                                <p><strong>Unlock the Premium Experience:</strong> Get access to your expeditions analytics. Enable this premium feature now and see earning performance!</p>
                                <div><EnableFeatureDialog feature={SubscriptionFeature.UserAnalytics} onComplete={onFeatureEnabled} /></div>
                            </>
                        )}
                    </div>
                </div>
                <div className="panel">
                    <div className="panel-section">
                        {features?.userRatingEnabled ? (
                            <>
                                <div>Daily ranking position: {details?.dailyPosition}</div>
                                <div>All time ranking position: {details?.totalPosition}</div>
                            </>
                        ) : (
                            <>
                                    <p><strong>Unlock the Premium Experience:</strong> Get access to your daily and overall rating positions. Enable this premium feature now and see where you stand!</p>
                                    <div><EnableFeatureDialog feature={SubscriptionFeature.UserRating} onComplete={onFeatureEnabled} /></div>
                            </>
                        )}
                    </div>
                    <div className="panel-section">
                        <Switch isSelected={settings.showName} onChange={saveSettings('showName')}>Show my name in leaderboard</Switch>
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <div>
                <Button onPress={login}>Login</Button>
            </div>
        )
    }
}