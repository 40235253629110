import React from 'react'
import DiscordUrl from './DiscordIco.svg'
import TwitterUrl from './TwitterIco.svg'
import FacebookUrl from './FBIco.svg'
import InstagramUrl from './InstagramIco.svg'
import TicTokUrl from './TiktokIco.svg'
import MailUrl from './MailIco.svg'
import './index.scss'

export const SocialLinks = () => (
    <ul className="social-links" >
        <li><a href="https://discord.gg/Mgvphj5JpW" target="_blank"><img src={DiscordUrl} alt="Discord" /></a></li>
        <li><a href="https://twitter.com/cryptodino_io" target="_blank"><img src={TwitterUrl} alt="Twitter" /></a></li>
        <li><a href="https://www.facebook.com/cryptodino" target="_blank"><img src={FacebookUrl} alt="Facebook" /></a></li>
        <li><a href="https://www.instagram.com/cryptodinoio" target="_blank"><img src={InstagramUrl} alt="Instagram" /></a></li>
        <li><a href="https://www.tiktok.com/@cryptodinoio"><img src={TicTokUrl} alt="TicTok" /></a></li>
        <li><a href="mailto:contact@cryptodino.io"><img src={MailUrl} alt="Mail" /></a></li>
    </ ul>
)