import React from 'react'
import { PageTemplate } from '../components/PageTemplate'
import { CollectionKeys } from '../collections'
import ReactMarkdown from 'react-markdown'
import './Story.scss'
import { Link } from 'react-router-dom'

export const StoryPage = ({ title, storyUrl, collection, next }: { title: string, storyUrl: string, collection: CollectionKeys, next?: string }) => {
    const [story, setStory] = React.useState<string>('')
    React.useEffect(() => { fetch(storyUrl).then(response => response.text()).then(setStory) }, [storyUrl])

    return (
        <PageTemplate title={title} collection={collection}>
            <div className="story">
                <ReactMarkdown children={story} />
                {next && <Link to={next}>Continue</Link>}
            </div>
        </PageTemplate>
    )
}