import React from 'react'
import * as Api from '../Api'

const FeatureContext = React.createContext<FeatureContextProps | undefined>(undefined)

export type FeatureContextProps = {
    features: {
        [collectionId: string]: Api.CollectionFeatures
    },
    getFeatures: (collectionId: string) => Promise<Api.CollectionFeatures>
}

export const FeatureProvider = ({ children }: any) => {
    const [features, setFeatures] = React.useState<{ [collectionId: string]: Api.CollectionFeatures }>({})
    const getFeatures = React.useCallback(async (collectionId) => {
        if (features[collectionId]) {
            return features[collectionId]
        } else {
            return Api.getFeatures(collectionId).then((collectionFeatures) => {
                setFeatures({
                    ...features,
                    [collectionId]: collectionFeatures,
                });
                return collectionFeatures;
            })
        }
    }, [features])
    const value = {
        features,
        getFeatures,
    }

    return <FeatureContext.Provider value={value}>{children}</FeatureContext.Provider>
}

export const useFeature = (collectionId: string) => {
    const context = React.useContext(FeatureContext)
    if (!context) {
        throw Error('Cannot call `useFeature` outside of FeatureContext')
    }

    React.useEffect(() => {
        context.getFeatures(collectionId)
    }, [collectionId, context])
    context.getFeatures(collectionId)
    const features = context.features[collectionId]
    return React.useMemo(() => features || {}, [features])
}