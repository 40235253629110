import React from 'react'
import './Accounting.scss'
import * as Api from '../Api'
import { AccountingDetails } from "./AccountingDetails"
import { PageTemplate } from '../components/PageTemplate'

const useProjectAccountingDetails = () => {
    const [accounting, setAccounting] = React.useState<Api.AccountData[] | null>()
    const [loading, setLoading] = React.useState(false)
    const load = React.useCallback(() => {
        setLoading(true)
        Api.getProjectAccounting().then((response) => {
            setAccounting(response)
            setLoading(false)
        });
    }, [])
    React.useEffect(load, [load])

    return { data: accounting, loading }
}

const useDinoCirculatingSupply = () => {
    const [supply, setSupply] = React.useState<Api.DinoSupply | null>()
    const [loading, setLoading] = React.useState(false)
    const load = React.useCallback(() => {
        setLoading(true)
        Api.getDinoCirculating().then((response) => {
            setSupply(response)
            setLoading(false)
        });
    }, [])
    React.useEffect(load, [load])

    return { data: supply, loading }
}

export const Accounting = () => {
    const { data: accounting, loading } = useProjectAccountingDetails()
    const { data: supply, loading: loadingSupply } = useDinoCirculatingSupply()
    return (
        <PageTemplate title="Accounting">
            {!loading && accounting ? (<div className="project-accounts-list">
                    {accounting.map(account => <AccountingDetails account={account} key={account.name} />)}
                </div>) : <></>}<br />
            {!loadingSupply && supply ? (<div className="dino-circulating-supply">
                DINO Supply: <p>{supply}</p>
            </div>) : <></>}
        </PageTemplate>
    )
}