import { ApiErrorCode, assetExtendedToCardanoAsset, Cardano, CardanoApi, CardanoAsset, CardanoWallet, getAssets, getCoinsBalance, isApiError, SignedData, TxSignErrorCode } from '../nami/index'
import { fromHex, toAssetName, toByteArray, toHex } from './plutus-helpers'
import {
    Transaction,
    TransactionUnspentOutput,
    TransactionWitnessSet,
    Address
} from '@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib'
import { AssetExtended, BrowserWallet } from '@meshsdk/core'

export type CardanoApiAdapter = {
    getWalletAddress: () => Promise<Address>,
    getWalletAddresses: () => Promise<string[]>,
    getUtxos: () => Promise<TransactionUnspentOutput[]>,
    getAssets: (policyIds: string[]) => Promise<CardanoAsset[]>,
    getCoinBalanace: (policyIds: string) => Promise<string>,
    signTx: (tx: Transaction, b: boolean) => Promise<TransactionWitnessSet>,
    submitTx: (tx: Transaction) => Promise<string>,
    signData: (address: string, payload: string) => Promise<SignedData>,
}

export type CardanoAdapter = {
    getWallets: () => CardanoWallet[],
}

const isCardanoWallet = (value: any): value is CardanoWallet => {
    return (typeof value === 'object' && typeof value.enable === 'function' && value.name)
}

const supportedWallets = ['nami', 'eternl', 'nufi']

export const cardanoAdapter = (cardano: Cardano): CardanoAdapter => {
    return {
        getWallets: () => Object.entries(cardano).filter(([walletName, wallet]) => isCardanoWallet(wallet) && supportedWallets.find(w => w === walletName) !== undefined).map(([key, wallet]) => wallet)
    }
}

export const cardanoApiAdapter = (api: BrowserWallet): CardanoApiAdapter => {
    return {
        getWalletAddress: () => api.getUsedAddresses().then(addresses => {
            const first = addresses[0]
            return Address.from_bech32(first)
        }),
        getWalletAddresses: () => api.getUsedAddresses(),
        getUtxos: () => api.getUsedUTxOs().then(utxos => {
            // printRawUtxo(utxos)
            const res = utxos.map(utxo => TransactionUnspentOutput.from_bytes(utxo.to_bytes()))
            return res
        }),
        getAssets: (policyIds: string[]) => 
        {
            const promises: Promise<AssetExtended[]>[] = []
            policyIds.filter((x, i, a) => a.indexOf(x) == i).forEach(policy => {
                promises.push(api.getPolicyIdAssets(policy))
            })

            return Promise.all(promises).then(x => x.flatMap(v => v).map(asset => assetExtendedToCardanoAsset(asset)))
        },
        getCoinBalanace: (policyId: string) => {
            return api.getPolicyIdAssets(policyId).then(assets => {
                return assets.map(item => { 
                    return item.quantity
                }).toString()
            })
        },
        signTx: (tx: Transaction, b: boolean) => api.signTx(toHex(tx.to_bytes()), b).then(result => TransactionWitnessSet.from_bytes(fromHex(result))),
        submitTx: (tx: Transaction) => {
            const cbor = toHex(tx.to_bytes())
            console.log(cbor)
            return api.submitTx(cbor)
        },
        signData: (address: string, payload: string) => api.signData(address, toHex(toByteArray(payload)))
    }
}

const printRawUtxo = (val: string[]) => {
    for (const rawUtxo of val) {
        const utxo = TransactionUnspentOutput.from_bytes(Buffer.from(rawUtxo, "hex"));
        const input = utxo.input();
        const txid = toHex(input.transaction_id().to_bytes());
        const txindx = input.index();
        const output = utxo.output();
        const amount = output.amount().coin().to_str(); // ADA amount in lovelace
        const multiasset = output.amount().multiasset();
        let multiAssetStr = "";

        if (multiasset) {
            const keys = multiasset.keys() // policy Ids of thee multiasset
            const N = keys.len();
            // console.log(`${N} Multiassets in the UTXO`)


            for (let i = 0; i < N; i++){
                const policyId = keys.get(i);
                const policyIdHex = toHex(policyId.to_bytes());
                // console.log(`policyId: ${policyIdHex}`)
                const assets = multiasset.get(policyId)
                const assetNames = assets!.keys();
                const K = assetNames.len()
                // console.log(`${K} Assets in the Multiasset`)

                for (let j = 0; j < K; j++) {
                    const assetName = assetNames.get(j);
                    const assetNameString = toAssetName(assetName.name());
                    const assetNameHex = toHex(assetName.name())
                    const multiassetAmt = multiasset.get_asset(policyId, assetName)
                    multiAssetStr += `+ ${multiassetAmt.to_str()} + ${policyIdHex}.${assetNameHex} (${assetNameString})`
                    // console.log(assetNameString)
                    // console.log(`Asset Name: ${assetNameHex}`)
                }
            }
        }


        const obj = {
            txid: txid,
            txindx: txindx,
            amount: amount,
            str: `${txid} #${txindx} = ${amount}`,
            multiAssetStr: multiAssetStr,
            TransactionUnspentOutput: utxo
        }
        console.log(`txid: ${obj.txid} txindex: ${obj.txindx} amount: ${obj.amount} str: ${obj.str} multiAssetStr: ${obj.multiAssetStr}`)
    }
}

export const handleSignError = (err: any) => {
    if (isApiError(err) && (err.code === ApiErrorCode.Refused || err.code === TxSignErrorCode.UserDeclined)) {
        return new CancelError('Operation canceled')
    }

    return err;
}

export class CancelError extends Error {
    constructor(...params: any) {
        super(...params)
    }
}