import React from 'react'
import { useData } from '../components/useData'
import * as Api from '../Api'
import { PageTemplate } from '../components/PageTemplate'
import { Button } from '../components/Button'
import { createBreedingInputs, createWalletAsset } from './Breeding'
import { useCardanoWalletProvider } from '../cardano/CardanoWalletProvider'
import * as breedingManager from './breedingManager'
import { useParams } from 'react-router-dom'

const useBreedings = (all: string) => {
        const callback = React.useCallback(() => all == "all" ? Api.getBreedingsAll() : Api.getBreedings(), [])
        return useData(callback)
}

export const Admin = () => {
    const { all } = useParams<{ all: string }>()
    const { data: breedings } = useBreedings(all)
    const { getCardano } = useCardanoWalletProvider()

    const cancel = React.useCallback(async (breeding: Api.BreedingItem) => {
        const asset1name = breeding.parent1AssetName
        const asset2name = breeding.parent2AssetName
        const deliveryAddress = breeding.deliveryAddress;
        const dinoCoinPr = breeding.dinoCoins
        const lovelacePrice = breeding.lovelace - breedingManager.getPlatformFees()
        const breedingInputs = createBreedingInputs(createWalletAsset(asset1name), createWalletAsset(asset2name), dinoCoinPr, lovelacePrice)
        const txHash = await breedingManager.cancelAdmin(getCardano(), breedingInputs, deliveryAddress)
        console.log(`cancelAdmin breeding tx hash: ${txHash}`)

    }, [getCardano])


    const claim = React.useCallback(async (breeding: Api.BreedingItem) => {
        const asset1name = breeding.parent1AssetName
        const asset2name = breeding.parent2AssetName
        const dinoCoinPr = breeding.dinoCoins
        const lovelacePrice = breeding.lovelace - breedingManager.getPlatformFees()
        const deliveryAddress = breeding.deliveryAddress;
        const breedingInputs = createBreedingInputs(createWalletAsset(asset1name), createWalletAsset(asset2name), dinoCoinPr, lovelacePrice)
        const txHash = await breedingManager.claimAdmin(getCardano(), breedingInputs, deliveryAddress)
        console.log(`claimAdmin breeding tx hash: ${txHash}`)

    }, [getCardano])

    return (
        <PageTemplate title="Breeding admin" collection="cryptodinos">
            {breedings && (
                <>
                <table>
                    <tr>
                        <th>Parent 1</th>
                        <th>Parent 2</th>
                        <th>DinoCoins</th>
                        <th>Lovelace</th>
                        <th>Delivery Address</th>
                        <th>State</th>
                        <th>StartTime</th>
                        <th>State Message</th>
                    </tr>
                    {breedings.map((breeding) => (
                        <tr>
                            <td>{breeding.parent1AssetName}</td>
                            <td>{breeding.parent2AssetName}</td>
                            <td>{breeding.dinoCoins}</td>
                            <td>{breeding.lovelace}</td>
                            <td>{breeding.deliveryAddress}</td>
                            <td>{breeding.state}</td>
                            <td>{breeding.startTime}</td>
                            <td>{breeding.stateMessage}</td>
                            <td>
                                <Button onPress={() => claim(breeding)}>Claim</Button>
                                {all == "all" ? (<Button onPress={() => cancel(breeding)}>Cancel</Button>) : (<></>)}
                            </td>
                        </tr>
                    ))}
                    </table>
                    <div>
                        Total: {breedings.length}
                    </div>
                </>
            )}
        </PageTemplate>
    )
}