import * as ConfigProvider from '../configProvider';
import { toHex, toByteArray } from './plutus-helpers'

export type CardanoProtocolParameters = {
    min_fee_a: number,
    min_fee_b: number,
    min_utxo: number,
    pool_deposit: number,
    key_deposit: number,
    max_tx_size: number,
    max_val_size: number,
    price_mem: number,
    price_step: number,
    coins_per_utxo_word: string
}

export interface Amount {
    unit: string;
    quantity: string;
}

export interface TransactionDetails {
    tx_hash: string;
    tx_index: number;
    output_index: number;
    amount: Amount[];
    block: string;
    data_hash: string;
}

export const getProtocolProtocolParams = async (): Promise<CardanoProtocolParameters> => {
    const protocolParamsResult = await fetch(`${ConfigProvider.getBlockfrostBaseUrl()}/epochs/latest/parameters`)
    return await protocolParamsResult.json();
}

export const getAssetUtxos = async (assetName: string, policyId: string, contractAddress: string) => {
    const asset = `${policyId}${toHex(toByteArray(assetName))}`;

    const utxos = await fetch(`${ConfigProvider.getBlockfrostBaseUrl()}/addresses/${contractAddress}/utxos/${asset}`)
        .then<TransactionDetails[]>(result => result.json())

    return utxos;
}

export const getUtxoMetadata = async(txHash: string) => {
    const metadata = await fetch(`${ConfigProvider.getBlockfrostBaseUrl()}/txs/${txHash}/metadata`)
        .then<any[]>(result => result.json())

    if (metadata.length > 0) {
        var result = metadata[0].json_metadata;
        if (result) {
            return result
        }
    }
}

export const getLatestBlockSlot = async() => {
    const protocolParamsResult = await fetch(`${ConfigProvider.getBlockfrostBaseUrl()}/blocks/latest`)
    const result = await protocolParamsResult.json()
    return result.slot
}

export const getLatestPosixTime = async() => {
    const protocolParamsResult = await fetch(`${ConfigProvider.getBlockfrostBaseUrl()}/blocks/latest`)
    const result = await protocolParamsResult.json()
    return result.time * 1000
}