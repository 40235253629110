import React from 'react'
import { useOverlayTriggerState } from '@react-stately/overlays'
import { Asset, AssetItem, Breeding } from '../Api'
import { assetLinkName, decomposeAssetName, IpfsImage } from '../explore/GalleryTile'
import * as breedingManager from './breedingManager'
import './Breeding.scss'
import * as Api from '../Api'
import { AssetDialog, SelectedAsset } from '../explore/AssetDialog'
import { PageTemplate } from '../components/PageTemplate'
import { useHistory, useParams } from 'react-router-dom'
import { CollectionKeys, collections, getKeyByCollectionId } from '../collections'
import { useAsset } from '../explore/GalleryAsset'
import { useNotifications } from '../components/NotificationProvider'
import { ReactComponent as Heart } from './heart_icon.svg'
import { ReactComponent as SimulateIcon } from './simulate_icon.svg'
import { ReactComponent as BreedIcon } from './breed_icon.svg'
import { ReactComponent as FootprintIcon } from './footprint_icon.svg'
import DinoCoinSrc from './DinoCoin_78x78.png'
import DinoBlankSrc from './DinoBlankToken.png'
import EggSrc from './breedingEgg.png'
import { BreedingInputParams } from './BreedingInputParams'
import { useData } from '../components/useData'
import addMilliseconds from 'date-fns/addMilliseconds'
import differenceInDays from 'date-fns/differenceInDays'
import addDays from 'date-fns/addDays'
import { Button } from '../components/Button'
import { useCardanoWalletProvider, useWalletAsset, WalletAsset } from '../cardano/CardanoWalletProvider'
import { Skeleton } from '../components/Skeleton'
import { CancelError, cardanoApiAdapter } from '../cardano/cardano-adapter'
import { appInsights } from '../AppInsights'
import differenceInSeconds from 'date-fns/fp/differenceInSeconds'
import { getBreedingTime, isBreedingEnabled, getBreedingDinoCoinPolicyId, getBreedingDinoCoinName, getDinoCoinPolicyIdUseV2 } from '../configProvider'
import { CardanoApi } from '../nami'
import { BrowserWallet } from '@meshsdk/core'

const AssetName = ({ asset, collection, onClick }: { asset: Asset | AssetItem, collection: CollectionKeys, onClick?: React.MouseEventHandler<HTMLSpanElement> }) => {
    return (
        <span className={`asset-name ${onClick ? 'clickable' : ''}`} onClick={onClick}>
            <span className={`${collections[collection].assetName.toLowerCase()}-icon`}></span> {assetLinkName(asset.name)}
        </span>)
}

export const BreedingCounter = ({ asset, collection }: { asset: Asset | AssetItem, collection: CollectionKeys }) => {
    const { data: counter } = useCounter(asset.assetId)
    const maxCount = collection === 'cryptodinos' ? '6' : '\u221E'
    return (
        <div className="breeding-counter">
            <div>Breed count</div>
            <div className="value">{(counter || 0)}/{maxCount}</div>
        </div>
    )
}

const useCounter = (assetId: string | undefined) => {
    const callback = React.useCallback(() => assetId ? Api.getCounter(assetId).then(x => x.counter) : Promise.resolve(undefined), [assetId])
    return useData(callback)
}

const useBreeding = (assetId: string | undefined) => {
    const { walletAddress } = useCardanoWalletProvider()
    const callback = React.useCallback(() => (assetId && walletAddress) ? Api.getBreeding(walletAddress, assetId) : Promise.resolve(undefined), [assetId, walletAddress])
    return useData(callback)
}

const ProgressBar = ({ value }: { value: number }) => {
    const max = -219.99078369140625;
    const offset = ((100 - value) / 100) * max
    const rotation = 0;
    const gradientTransform = `rotate(${rotation})`
    return (
        <svg className="progress blue noselect" x="0px" y="0px" viewBox="0 0 80 80">
            <defs>
                <linearGradient id="progress-fill" gradientTransform={gradientTransform}>
                    <stop offset="0%" stopColor="#411946" />
                    <stop offset="100%" stopColor="#FFA4A4" />
                </linearGradient>
            </defs>
            <path className="fill" strokeDashoffset={offset} d="M5,40a35,35 0 1,0 70,0a35,35 0 1,0 -70,0" strokeLinecap="round" />
            <image href={EggSrc} width="30" height="42" x="25" y="20" preserveAspectRatio="none" />
        </svg>
    )
}

const BreedingProgress = (
    { daysLeft, result }: { daysLeft: number, result: string | undefined }) => {

    const percent = ((7 - daysLeft) / 7) * 100;

    if (result) {
        return (
            <>
                <h2>Result</h2>
                <div>Breeding completed</div>
                <div><img src={result} alt="Breeding result" /></div>
            </>
        )
    } else {
        if (daysLeft < 0) {
            return (
            <>
                <h2>Claim your NFTs</h2>
            </>
            )
        } else {
        return (
            <>
                <h2>{daysLeft} day{daysLeft > 1 ? 's' : ''} left</h2>
                <div>Breeding in progress...</div>
                <div><ProgressBar value={percent} /></div>
            </>
        )
        }
    }
}

export const createBreedingInputs = (primeAsset: WalletAsset, secondAsset: WalletAsset, dinoCoins: number, lovelacePice: number) => {
    //deadline for breeding is set to 5 minutes internally if here it is set to ""
    // we are removing 5ADA because it is going to be added internally for service costs
    console.log(`serviceFee: ${lovelacePice.toString()}`)
    const breedingInputs: BreedingInputParams = {
        deadline: "",
        primeAssetName: primeAsset.name,
        primePolicyId: primeAsset.policyId,
        primeTknGen: "0",
        secondAssetName: secondAsset.name,
        secondPolicyId: secondAsset.policyId,
        secondTknGen: "0",
        serviceFee: lovelacePice.toString(),
        serviceDFee: dinoCoins.toString(),
    }

    return breedingInputs;
}


type BreedingData = {
    context: Breeding | undefined,
    asset1: SelectedAsset | undefined,
    asset2: SelectedAsset | undefined,
}

type BreedingDataResult = {
    inProgress: boolean,
    data: BreedingData,
    setAsset2: (selection: SelectedAsset | undefined) => void
}

const useBreedingData = (number: string) => {
    const { data: firstAsset, inProgress: firstAssetInProgress } = useAsset('cryptodinos', number)
    const { data: breeding, inProgress: breedingInProgress } = useBreeding(firstAsset?.assetId)
    const firstWalletAsset = useWalletAsset(firstAsset?.name)
    const [secondAsset, setSecondAsset] = React.useState<SelectedAsset | undefined>(undefined)
    const [secondAssetInProgress, setSecondAssetInProgress] = React.useState(false)
    const history = useHistory()
    React.useEffect(() => {
        if (breeding && firstAsset) {
            setSecondAssetInProgress(true)
            if (breeding.parent2AssetId === firstAsset.assetId) {
                Api.getAssetById(breeding.parent1AssetId).then(parentAsset1 => {
                    history.replace(`/explore/cryptodinos/${assetLinkName(parentAsset1.name)}/breed`);
                });
            }
            else {
                Api.getAssetById(breeding.parent2AssetId).then(parent2Asset => {
                    setSecondAsset({ data: parent2Asset, collection: getKeyByCollectionId(parent2Asset.collectionId)!, token: undefined })
                    setSecondAssetInProgress(false)
                });
            }
        }
    }, [breeding, history, firstAsset]);

    return React.useMemo<BreedingDataResult>(() => ({
        inProgress: firstAssetInProgress || breedingInProgress || secondAssetInProgress,
        data: {
            context: breeding,
            asset1: firstAsset ? {
                data: firstAsset,
                token: firstWalletAsset,
                collection: 'cryptodinos'
            } : undefined,
            asset2: secondAsset,
        },
        setAsset2: setSecondAsset as any
    }), [firstAssetInProgress, breedingInProgress, secondAssetInProgress, firstAsset, firstWalletAsset, secondAsset, breeding]);
}

export const AssetSlot = ({ data, collection, onClick }: { data: Asset | AssetItem, collection: CollectionKeys, onClick?: React.MouseEventHandler<HTMLSpanElement> }) => (
    <>
        <IpfsImage hash={data.thumbprint.ipfsHash} name={data.name} />
        <BreedingCounter asset={data} collection={collection} />
        <AssetName asset={data} collection={collection} onClick={onClick} />
    </>
)

export const AssetSlotSkeleton = () => (
    <>
        <Skeleton containerClassName="img-skeleton" />
        <div className="breeding-counter">
            <Skeleton width={85} height={15} />
            <Skeleton width={50} height={15} />
        </div>
        <Skeleton containerClassName="asset-name-skeleton" borderRadius={12} width={100} height={25} />
    </>
)

export const createWalletAsset = (name: string): WalletAsset => {
    if (name.startsWith('DinoSavior')) {
        return {
            name,
            policyId: collections.dinosaviors.policyIds[0],
        }
    } else {
        const { generation } = decomposeAssetName(name)
        if (generation === '0') {
            return {
                name,
                policyId: collections.cryptodinos.policyIds[0],
            }
        } else {
            return {
                name,
                policyId: collections.cryptodinos.policyIds[1],
            }
        }
    }
}

export const startTimeToRemainingDays = (startTime: string) => {
    const claimDate = addMilliseconds(new Date(startTime), getBreedingTime())
    if (claimDate < new Date()) {
        return -1
    } else {
        return Math.max(0, differenceInDays(claimDate, new Date()) + 1)
    }
}

const canBreed = (name: string, metadata: Api.Asset, counter: Api.AssetCounter) => {
    if (counter.limit && counter.counter >= counter.limit) {
        return `Counter of '${name}' exceeds limit of breeding`
    }

    if (differenceInSeconds(addDays(new Date(metadata.mintingDate), 28), new Date()) < 0) {
        return `${name} is still in puberty period`
    }

    if (name.startsWith('CryptoDino')) {
        const { generation } = decomposeAssetName(name)
        if (parseInt(generation) >= 10) {
            return `Breeding is limited to 10 generations`
        }
    }

    if (counter.lastBreedingDate) {
        const restTime = name.startsWith(collections.cryptodinos.assetName) ? 14 : 7
        const restedDate = addDays(new Date(counter.lastBreedingDate), restTime)
        const remainingDays = differenceInDays(restedDate, new Date()) + 1
        if (differenceInSeconds(restedDate, new Date()) < 0) {
            return `${name} is not rested before next breeding. Come back in ${remainingDays} day(s)`
        }
    }

    return undefined
}

const hasDinoCoin = (dinoCoins: number, api: BrowserWallet) => {
    return cardanoApiAdapter(api).getCoinBalanace(getBreedingDinoCoinPolicyId()).then((balance) => {
        if (parseInt(balance) < dinoCoins) {
            return `Your ${getBreedingDinoCoinName()} balance is not sufficient`
        }

        return undefined
    })
}

const useBreedingState = ({ asset1, asset2, context }: BreedingData, dinoCoinPrice: number, lovelaceBreedingPrice: number) => {

    const [simulationPreview, setSimulationPreview] = React.useState<string | undefined>()
    const [daysLeft, setDaysLeft] = React.useState<number | undefined>(undefined)
    const [breedingInProgress, setBreedingInProgress] = React.useState(false)
    const { addWarn } = useNotifications()
    const { getCardano } = useCardanoWalletProvider()
    const [message, setMessage] = React.useState<string | undefined>(undefined)
    const simulate = React.useCallback(() => {
        if (asset1 && asset2) {
            setSimulationPreview(undefined)
            setBreedingInProgress(true)
            setDaysLeft(7)
            Api.simulateBreeding(asset1.data.assetId, asset2.data.assetId).then((img) => {
                setSimulationPreview(img)
                setBreedingInProgress(false)
                setDaysLeft(0)
            }).catch(error => {
                console.log(error)
            })
        } else {
            addWarn('Please select CryptoDino or DinoSavior to breed')
        }

    }, [asset1, asset2, addWarn])

    const handleError = React.useCallback((err: any, token1: string, token2: string, dinoCoinPrice: number, lovelaceBreedingPrice: number) => {
        if (err instanceof CancelError) {
            return
        } else if (typeof err === 'string') {
            addWarn(err)
            appInsights.trackTrace({ message: err }, { token1, token2, dinoCoinPrice, lovelaceBreedingPrice })
        } else {
            addWarn(err.info || err.message)
            appInsights.trackException({ exception: err }, { token1, token2, dinoCoinPrice, lovelaceBreedingPrice })
        }
    }, [addWarn])

    const decrementDaysLeft = React.useCallback(() => {
        if (breedingInProgress && daysLeft && daysLeft > 1) {
            setDaysLeft(daysLeft - 1)
        }
    }, [daysLeft, breedingInProgress])
    const decrementDaysLeftRef = React.useRef<() => void | undefined>()
    React.useEffect(() => {
        decrementDaysLeftRef.current = decrementDaysLeft
    }, [decrementDaysLeft])
    React.useEffect(() => {
        if (daysLeft) {
            setTimeout(() => decrementDaysLeftRef.current?.(), 300)
        }
    }, [breedingInProgress, daysLeft])

    React.useEffect(() => {
        if (context) {
            setDaysLeft(startTimeToRemainingDays(context.startTime))
        }
    }, [context]);

    const canClaim = React.useMemo(() => {
        return context && daysLeft !== undefined && daysLeft < 0;
    }, [context, daysLeft])

    const canCancel = React.useMemo(() => {
        return Boolean(context) && daysLeft !== undefined && daysLeft > 0;
    }, [context, daysLeft])

    const startBreeding = React.useCallback(async () => {
        if (asset1?.token && asset2?.token) {
            try {
                const [counter1, counter2, asset1Metadata, asset2Metadata] = await Promise.all([
                    Api.getCounter(asset1.data.assetId),
                    Api.getCounter(asset2.data.assetId),
                    Api.getAssetById(asset1.data.assetId),
                    Api.getAssetById(asset2.data.assetId),
                ])
                const error = (await hasDinoCoin(dinoCoinPrice, getCardano())) || canBreed(asset1.data.name, asset1Metadata, counter1) || canBreed(asset2.data.name, asset2Metadata, counter2)
                if (error) {
                    addWarn(error)
                } else {
                    const breedingInputs = createBreedingInputs(asset1.token, asset2.token, dinoCoinPrice, lovelaceBreedingPrice)
                    const txHash = await breedingManager.lockForBreeding(getCardano(), breedingInputs)
                    console.log(`start breeding tx hash: ${txHash}`)
                    setMessage('Breeding process has been initiated. Soon you will see new egg in your account')
                }
            } catch (err: any) {
                handleError(err, asset1.token.name, asset2.token.name, dinoCoinPrice, lovelaceBreedingPrice)
            }
        }

    }, [asset1, asset2, addWarn, dinoCoinPrice, lovelaceBreedingPrice, getCardano, handleError])

    const cancelBreeding = React.useCallback(async () => {
        if (asset1 && asset2) {
            try {
                const breedingDetails = await Api.getBreedingDetailsForAssets(asset1.data.assetId, asset2.data.assetId);
                const lovelacePrice = breedingDetails.lovelace - breedingManager.getPlatformFees()
                const breedingInputs = createBreedingInputs(createWalletAsset(asset1.data.name), createWalletAsset(asset2.data.name), dinoCoinPrice, lovelacePrice)
                const txHash = await breedingManager.cancel(getCardano(), breedingInputs)
                console.log(`cancel breeding tx hash: ${txHash}`)
                setDaysLeft(undefined)
                setMessage('Breeding process has been stopped')
            } catch (err: any) {
                handleError(err, asset1.data.name, asset2.data.name, dinoCoinPrice, lovelaceBreedingPrice)
            }
        }

    }, [asset1, asset2, dinoCoinPrice, lovelaceBreedingPrice, getCardano, handleError])

    const claimToken = React.useCallback(async () => {
        if (asset1 && asset2) {
            try {
                const breedingDetails = await Api.getBreedingDetailsForAssets(asset1.data.assetId, asset2.data.assetId);
                const breedingInputs = createBreedingInputs(createWalletAsset(asset1.data.name), createWalletAsset(asset2.data.name), dinoCoinPrice, lovelaceBreedingPrice)
                const txHash = await breedingManager.claim(getCardano(), breedingInputs)
                console.log(`claim breeding tx hash: ${txHash}`)
                setDaysLeft(undefined)
                setMessage('Congratulation! Soon you will see your new CryptoDino in your wallet');
            } catch (err: any) {
                handleError(err, asset1.data.name, asset2.data.name, dinoCoinPrice, lovelaceBreedingPrice)
            }
        }

    }, [asset1, asset2, dinoCoinPrice, lovelaceBreedingPrice, getCardano, handleError])
    
    return React.useMemo(() => ({
        daysLeft,
        simulationPreview,
        simulate,
        canClaim,
        canCancel,
        message,
        startBreeding,
        cancelBreeding,
        claimToken
    }), [simulationPreview, simulate, daysLeft, canClaim, canCancel, message, startBreeding, cancelBreeding])
}

export const BreedPage = () => {

    const { number } = useParams<{ number: string }>()
    const generation = React.useMemo(() => {
        return decomposeAssetName(number).generation
    }, [number])

    const { data: { context, asset1, asset2 }, setAsset2, inProgress } = useBreedingData(number)

    const assetDialogState = useOverlayTriggerState({})
    const dinoCoinPrice = useDinoCoinPrice(asset1, asset2)
    const lovelaceBreedingPrice = useLovelaceBreedingPrice()
    const { daysLeft, message, simulationPreview, simulate, canClaim, canCancel, startBreeding, cancelBreeding, claimToken } = useBreedingState({ context, asset1, asset2 }, dinoCoinPrice, lovelaceBreedingPrice.data!)

    const onSecondAssetSelected = React.useCallback((selection: SelectedAsset) => {
        setAsset2(selection)
        assetDialogState.close()
    }, [setAsset2, assetDialogState])

    const canBreed = React.useMemo(() => {
        return Boolean(asset1?.token) && Boolean(asset2?.token) && !Boolean(message)
    }, [asset1, asset2, message])

    return (
        <div className="explore">
            <PageTemplate collection="cryptodinos" title="Breed">
                <article>
                    <div className="breeding-pair">
                        <div className="asset1">
                            <div className="footprint-dominant">
                                <FootprintIcon /> Footprint Dominant Parent
                            </div>
                            {(asset1 && !inProgress) ? <AssetSlot data={asset1.data} collection="cryptodinos" /> : <AssetSlotSkeleton />}
                        </div>
                        <div className="separator"><Heart /></div>
                        <div className="asset2">
                            {inProgress ? <AssetSlotSkeleton /> : asset2
                                ? <AssetSlot data={asset2.data} collection={asset2.collection} onClick={() => assetDialogState.open()} />
                                : (
                                    <>
                                        <img src={DinoBlankSrc} alt="Pick second token to breed" />
                                        <div onClick={() => assetDialogState.open()} className="pick-button">
                                            <span className="empty-icon"></span> Pick
                                        </div>
                                    </>
                                )}
                            {asset1 && assetDialogState.isOpen && <AssetDialog isOpen={true} onClose={assetDialogState.close} onSelect={onSecondAssetSelected} exclude={[asset1.data.name]} generation={generation} />}
                        </div>
                        <div className="path">
                            Required {getBreedingDinoCoinName()}
                            <div className="price">
                                <div><img src={DinoCoinSrc} alt={getBreedingDinoCoinName()} /></div>
                                <div>{trim6digits(dinoCoinPrice)}</div>
                            </div>
                        </div>
                    </div>
                    <div className="breeding-actions">
                        {isBreedingEnabled() && !context && <Button disabled={!canBreed} onPress={startBreeding}><BreedIcon /> Breed</Button>}
                        <button onClick={simulate}><SimulateIcon />Simulate</button>
                    </div>
                    <div style={{ marginTop: 10 }}>
                        Breeding fee: {((lovelaceBreedingPrice.data! + breedingManager.getPlatformFees()) / 1_000_000) } ADA (~3.8 ADA is going to be returned)
                    </div>
                    {/* <Button onPress={cancelAdmin}>CancelAdmin</Button>
                    <Button onPress={claimAdmin}>ClaimAdmin</Button> */}
                    {(daysLeft !== undefined || message || context?.stateMessage) && (<div className="breeding-status">
                        {daysLeft !== undefined && <BreedingProgress daysLeft={daysLeft} result={simulationPreview} />}
                        {message && <div>{message}</div>}
                        {context?.stateMessage && <div>{message}</div>}
                        {canCancel && <Button onPress={cancelBreeding}>Cancel</Button>}
                        {canClaim && <Button onPress={claimToken}>Claim</Button>}
                    </div>)}

                    <div style={{marginTop: 20, fontSize: '0.85rem'}}>
                        Not sure which asset to choose? Check out <a style={{ color: '#FFA4A4' }} href="/roarpaper/breeding" target="_blank">this</a> breeding guide.
                    </div>
                </article>
            </PageTemplate>
        </div>
    )
}

const counterToPrice = (counter: number, collection: CollectionKeys): number => {
    if (collection === 'cryptodinos') {
        if (counter === 0) {
            return denominate(800);
        } else if (counter === 1) {
            return denominate(1500);
        } else {
            return counterToPrice(counter - 1, collection) + counterToPrice(counter - 2, collection);
        }
    } else if (collection === 'dinosaviors') {
        if (counter === 0) {
            return denominate(800);
        } else if (counter === 1) {
            return denominate(1200);
        } else {
            const value = counterToPrice(counter - 1, collection) + counterToPrice(counter - 2, collection) / 2;
            const extra = (value % 100) !== 0 ? 100 - (value % 100) : 0
            return value + extra
        }
    }

    throw Error(`Collection ${collection} is not supported`)
}

const denominate = (value: number) : number => {
    return getDinoCoinPolicyIdUseV2() ? value * 1_000_000 : value
}

const trim6digits = (value: number) : number => {
    return getDinoCoinPolicyIdUseV2() ? value / 1_000_000 : value
}

function useDinoCoinPrice(firstAsset: SelectedAsset | undefined, secondAsset: SelectedAsset | undefined) {
    const counter1 = useCounter(firstAsset?.data.assetId)
    const counter2 = useCounter(secondAsset?.data.assetId)
    if (firstAsset) {
        const { generation } = decomposeAssetName(firstAsset.data.name)
        const price = (firstAsset && counter1.data !== undefined ? counterToPrice(counter1.data, 'cryptodinos') : 0) + (secondAsset && counter2.data !== undefined ? counterToPrice(counter2.data, secondAsset.collection) : 0)
        return Math.round(price * Math.pow(1.2, parseInt(generation)))
    }

    return NaN;
}

function useLovelaceBreedingPrice() {
    const callback = React.useCallback(() => Api.getBreedingLovelacePrice().then(x => x.lovelace), [])
    return useData(callback)
}