import './Footer.scss'
import { SocialLinks } from './social'
import { ReactComponent as CardanoLogo } from './CardanoLogoWithTypo.svg'
import { Link } from 'react-router-dom'

const DinoLabs = () => (
    <div className="copyright">&copy; Copyright {new Date().getFullYear()} DinoLabs Sp. z o.o.</div>
)

export const Footer = () => (
    <div className="footer">
        <CardanoLogo />
        <SocialLinks />
        <DinoLabs />
    </div>
)