import React from 'react'
import './LinkWallet.scss'
import { useCardanoWalletProvider, useWalletAssets } from '../cardano/CardanoWalletProvider'
import { Button } from '../components/Button'
import { signPayload, getSigningAddress } from '../cardano/signingManager'
import * as ConfigProvider from '../configProvider'
import { SignedData } from '../nami'
import { useLocation } from 'react-router-dom'

const useLinkWallet = (setState: React.Dispatch<React.SetStateAction<boolean>>) => {
    const { getCardano } = useCardanoWalletProvider()
    const provider = "Discord"
    const onLinkWallet = React.useCallback(async () => {
        const accessToken = getDiscordAccessToken()!
        const discordUserName = await retrieveDiscordUserName(accessToken)
        const isLinked = await checkIfLinked(discordUserName, provider)
        if (isLinked) {
            console.log('already linked')
            localStorage.setItem('isDiscordLinked', "true")
            setState(isLinked)
        } else {
            const address = await getSigningAddress(getCardano())
            const signedPayload = await signPayload(getCardano(), `{\"address\":\"${address}\"}`)
            const request: AccountLinkingRequest = {
                address: address,
                provider: provider,
                name: discordUserName,
                data: signedPayload
            }
            const linkingResult = await linkWallet(request)
            if (linkingResult) {
                console.log('successfully linked')
                localStorage.setItem('isDiscordLinked', "true")
                setState(true)
            } else {
                console.log('something went wrong')
                setState(false)
            }
        }
    }, [])
    return onLinkWallet
}

const useCallProtectedApi = () => {
    const provider = "Discord"
    const onCallProtectedApi = React.useCallback(async () => {
        const apiAccessToken = await getApiToken()
        await callTestAuthorize(apiAccessToken)
    }, [])
    return onCallProtectedApi
}

const getDiscordUrl = () => {
    const clientId = ConfigProvider.getDiscordApplicationId()
    const redirect_uri = encodeURI(`${window.location.origin}/link-wallet`)
    console.log(`redirect_uri ${redirect_uri}`)
    return `https://discord.com/api/oauth2/authorize?prompt=none&response_type=token&client_id=${clientId}&scope=identify&redirect_uri=${redirect_uri}`
}

const retrieveDiscordUserName = async (accessToken: string) => {
    var site = await fetch("https://discordapp.com/api/users/@me", {
        method: 'GET',
        headers: {'Authorization': `Bearer ${accessToken}`}
    });
    var response = await site.json();
    var userName = `${response.username}#${response.discriminator}`
    return userName
}

const getDiscordAccessToken = () => {
    if (localStorage.getItem("discord_access_token")) {
        return localStorage.getItem("discord_access_token")
    } else {
        var token = window.location.hash.split('&').find(x => x.indexOf("access_token") >= 0)
        var accessToken = token?.split('=')[1]
        return accessToken
    }
}

const checkIfLinked = async (userName: string, provider: string) => {
    var name = userName.replace(/#/g, '%23');
    const apiUrl = ConfigProvider.getApiUrl()
    const response = await fetch(`${apiUrl}/api/account/linking/address/${name}/${provider}`)
    if (response.status == 404) {
        return false
    } else {
        if (response.status == 400 || response.status >= 500) {
            throw Error(response.statusText)
        } else {
            return true
        }
    }
}

const linkWallet = async (accountLinking: AccountLinkingRequest) => {
    const apiUrl = ConfigProvider.getApiUrl()
    const response = await fetch(`${apiUrl}/api/account/linking`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(accountLinking)
    })

    if (response.status == 400) {
        console.log(response.body)
        return false
    } else {
        console.log(`successfully linked ${accountLinking.name} with address ${accountLinking.address}`)
        return true
    }
}

const getApiToken = async() => {
    const accessToken = getDiscordAccessToken()
    console.log(`discord access_token: ${accessToken}`)
    const apiUrl = ConfigProvider.getApiUrl()
    const response = await fetch(`${apiUrl}/api/token`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accessToken: accessToken})
    })

    if (response.status == 401) {
        console.log('need to relogin')
        discordRedirectLogin()
    } else {
        var result = await response.json()
        var token = result.authToken
        return token
    }
}

const callTestAuthorize = async(accessToken: string) => {
    const apiUrl = ConfigProvider.getApiUrl()
    const response = await fetch(`${apiUrl}/api/testauthorize`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${accessToken}`
        }
    })

    if (response.status == 401) {
        console.log('wrong token')
        throw new Error(response.statusText)
    } else {
        var result = await response.json()
        console.log(`response from protected endpoint ${result}`)
    }
}

const discordRedirectLogin = () => {
    const discordUrl = getDiscordUrl()
    console.log('before onLogin')
    window.location.href = discordUrl
}

type AccountLinkingRequest = {
    name: string,
    provider: string,
    address: string,
    data: SignedData
}

export const LinkWallet = () => {
    const [isLinked, setLinked] = React.useState(() => {
        if (localStorage.getItem('isDiscordLinked')) {
            return true
        } else {
            return false
        }
    })
    const location = useLocation()
    const [isLogged] = React.useState(() => {
        if (localStorage.getItem("discord_access_token")) {
            return true
        }
        if (location.hash.indexOf("access_token") >= 0) {
            localStorage.setItem("discord_access_token", getDiscordAccessToken()!)
            return true
        } else {
            return false
        }
    })
    
    const onLogin = React.useCallback(async () => {
        discordRedirectLogin()
    }, [isLogged])

    const onLinkWallet = useLinkWallet(setLinked)
    const onCallProtectedApi = useCallProtectedApi()
    return (
        <div>
            {!isLogged ? <div className="login-actions"><Button onPress={onLogin}>Discord login</Button></div> : <div></div>}
            {!isLinked ? <div className="login-actions"><Button onPress={onLinkWallet}>Link wallet</Button></div> : <div></div>}
            {isLinked ? <div className="login-actions"><Button onPress={onCallProtectedApi}>Call protected api</Button></div> : <div></div>}
        </div>
    )
}