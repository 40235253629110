import React from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import { PageTemplate } from '../components/PageTemplate'
import { ExploreAccount } from '../explore/ExploreAccount'
import { AccountSummary } from './AccountSummary'
import { Staking } from './Staking'
import './Account.scss'
import { Game } from './Game'
import { Scholarship } from './Scholarship'
import { isScholarshipEnabled } from '../configProvider'
import { SubscriptionBanner } from './SubscriptionBanner'

export const Account = () => {
    return (
        <div className="explore account">
            <PageTemplate collection="cryptodinos" title={(<><big>A</big>ccount</>)}>
                <ul className="account-tabs">
                    <li>
                        <NavLink exact={true} to="/account">Summary</NavLink>
                    </li>
                    <li>
                        <NavLink to="/account/assets">Assets</NavLink>
                    </li>
                    <li>
                        <NavLink to="/account/staking">Staking</NavLink>
                    </li>
                    <li>
                        <NavLink to="/account/game">Game</NavLink>
                    </li>
                    <li>
                        <NavLink to="/account/scholarship">Scholarship</NavLink>
                    </li>
                </ul>
                <div style={{ width: 1100, margin: '0 auto' }}>
                    <SubscriptionBanner />
                    <Switch>
                        <Route path="/account" exact={true}>
                            <AccountSummary />
                        </Route>
                        <Route path="/account/assets" exact={true}>
                            <ExploreAccount />
                        </Route>
                        <Route path="/account/staking" exact={true}>
                            <Staking />
                        </Route>
                        <Route path="/account/game" exact={true}>
                            <Game />
                        </Route>
                        <Route path="/account/scholarship" exact={true}>
                            {isScholarshipEnabled() ? <Scholarship /> : <div>Coming soon</div> }
                        </Route>
                    </Switch>
                </div>
            </PageTemplate>
        </div>
    )
}