import React from 'react'
import './Home.scss'
import MainScreenSrc from './Main_Screen.jpg'
import CoinSrc from './coin_0005_0040.png'
import StrategySrc from './panel.png'

const Banner = () => (
    <div className="banner">
        <img src={MainScreenSrc} />
        <div className="banner-content">
            <h1>CNFT Game</h1>
            <h3>CryptoDino - Breeding & P&E Game</h3>
            <div>
                <a href="https://www.youtube.com/watch?v=FRBFhyvR1RE" target="_blank">Watch trailer</a>
                <a href="https://drive.google.com/file/d/1gnCOrsdO-SlPEfHzzRSADtuCQF4m3zuu/view" target="_blank">Game Whitepaper</a>
            </div>
        </div>
    </div>
)

const PlayToEarn = () => (
    <div className="home-section">
        <div><img src={CoinSrc} /></div>
        <div>
            <h1>Play & Earn</h1>
            <p>
                P&E is a game monetization model which, unlike traditional models (pay to play, free to play), allows revenue to be generated not only for creators, but also the players themselves.
            </p>
            <p>
                Expanding your Village and sending DinoSaviors and CryptoDinos on escapades will reward players with the cryptocurrency known as DinoCoins. 
            </p>
        </div>
    </div>
)

const Strategy = () => (
    <div className="home-section">
        <div><img src={StrategySrc} /></div>
        <div>
            <h1>Strategy</h1>
            <p>
                The player's task will be to manage the CryptoDino Village and develop the area, as well as participate in Escapades led by DinoSaviors and CryptoDinos in order to find valuable rewards!
            </p>
            <p>
                The CryptoDino Game will function using the Play & Earn (P&E) model, making these rewards valuable not only to the CryptoDinos - but to their owners too!
            </p>
        </div>
    </div>
)

export const Home = () => (
    <div>
        <Banner />
        <PlayToEarn />
        <Strategy />
    </div>
)
