import React from 'react'

export type Data<T> = {
    data: T | undefined
    inProgress: boolean,
    refresh: () => Promise<void>
}

export function useData<T>(callback: () => Promise<T | undefined>): Data<T> {
    const [inProgress, setInProgress] = React.useState(false)
    const [data, setData] = React.useState<T | undefined>()
    const load = React.useCallback(() => {
        setInProgress(true)
        return callback().then(result => {
            setData(result)
            setInProgress(false)
        })
    }, [callback])
    React.useEffect(() => { load() }, [load])

    return React.useMemo(() => ({
        data,
        inProgress,
        refresh: load
    }), [data, inProgress, load])
}