import React from 'react'
import { useParams } from 'react-router-dom'
import { CollectionKeys, collections } from '../collections'
import { PageTemplate } from '../components/PageTemplate'
import { useFeature } from './FeatureContext'
import { format } from '../components/camelCase'
import { UniquenessStatus } from './GalleryAsset'
import './Rarity.scss'

export const Rarity = () => {

    const { collection } = useParams<{ collection: CollectionKeys }>()
    const features = useFeature(collections[collection].collectionId)

    const featureOrder = React.useCallback(([a], [b]) => {
        const { features } = collections[collection]
        return features.indexOf(a) - features.indexOf(b)
    }, [collection])

    const valueOrder = React.useCallback(([, a], [, b]) => a - b, [])

    return (
        <PageTemplate title={(<><big>R</big>arity</>)}>
            <article className="rarity">
                {Object.entries(features).filter(([feature]) => feature !== 'Parent1' && feature !== 'Parent2').sort(featureOrder).map(([feature, values]) => (
                    <div key={feature}>
                        <h2>{format(feature)}</h2>
                        {Object.entries(values).sort(valueOrder).map(([value, rarity]) => (
                            <div className="feature" key={feature + value}>
                                <div>
                                    <div>{value.toLowerCase()}</div>
                                </div>
                                <UniquenessStatus value={rarity / 10000} />
                            </div>
                        ))}
                    </div>
                ))}
            </article>
        </PageTemplate>
    )
}