import {
    BaseAddress,
    Address,
    TransactionWitnessSet,
    hash_plutus_data,
    TransactionOutput,
    TransactionOutputs,
    Transaction,
    Value,
    PlutusScript,
    TransactionInputs,
    PlutusScripts,
    Ed25519KeyHashes,
    PlutusList,
    Redeemers,
    ExUnits,
    BigNum,
    RedeemerTag,
    ConstrPlutusData,
    PlutusData,
    Int,
    Redeemer,
    AuxiliaryData,
    Ed25519KeyHash,
    GeneralTransactionMetadata,
    encode_json_str_to_metadatum,
    MetadataJsonSchema,
    TransactionBuilder,
    Language,
    TxBuilderConstants,
    hash_script_data,
    TransactionUnspentOutputs,
    TransactionOutputBuilder,
    MultiAsset,
    Assets,
    AssetName,
    ScriptHash,
    TransactionInput,
    ExUnitPrices,
    UnitInterval,
    min_script_fee,
    PlutusWitness,
    TxInputsBuilder
} from '@emurgo/cardano-serialization-lib-browser/cardano_serialization_lib'
import { createTransactionBuilder, fromHex, toHex, toPlutusData, PlutusDataObject, PlutusFieldType, toByteArray, setupCoinSelector, toBigNum, getContractOutput, getCollateralUnspentTransactionOutput, addInputs, logTx, getUnspentTransactionUtxoForNft, logTxDetails, splitIfChangeValueIsTooBig, splitAddress, extractUnitPrices } from '../cardano/plutus-helpers'
import { getProtocolProtocolParams } from '../cardano/blockfrost'
import { CardanoApi } from '../nami'
import { cardanoApiAdapter, handleSignError } from '../cardano/cardano-adapter'
import * as ConfigProvider from '../configProvider'
import { appInsights } from '../AppInsights'
import * as marketplaceLegacy from '../legacy/marketplace/marketplaceLegacy' 
import { retry } from 'ts-retry'
import { BrowserWallet } from '@meshsdk/core'

const nftMoveFixedPrice = "2000000";

export const offer = async (api: BrowserWallet, policyId: string, assetName: string, askingPrice: string): Promise<string> => {
    const cardano = cardanoApiAdapter(api)
    const protocolParameters = await getProtocolProtocolParams()

    const txBuilder = await createTransactionBuilder(protocolParameters)
    const scriptAddress = getContractAddress();

    const selfAddress = await cardano.getWalletAddress()
    const baseAddress = BaseAddress.from_address(selfAddress)!
    const pkh = toHex(baseAddress.payment_cred().to_keyhash()?.to_bytes() as Uint8Array);

    const offerDatum = createSalesOfferDatum(pkh, askingPrice, policyId, assetName);
    const plutusData = toPlutusData(offerDatum);
    const offerDatumHash = hash_plutus_data(plutusData);

    let txOutputBuilder = TransactionOutputBuilder.new();
    txOutputBuilder = txOutputBuilder.with_address(scriptAddress);
    txOutputBuilder = txOutputBuilder.with_data_hash(offerDatumHash)

    const txOutputAmountBuilder = txOutputBuilder.next();

    const lovelaceWithAsset = getContractOutput(nftMoveFixedPrice, policyId, assetName)
    const txOutputAmountBuilderWithCoins = txOutputAmountBuilder.with_value(lovelaceWithAsset)

    const aux_data: AuxiliaryData = await addOfferMetadata(txBuilder, selfAddress, askingPrice, assetName, policyId, "offer")
    
    const txOutput = txOutputAmountBuilderWithCoins.build();
    txBuilder.add_output(txOutput)

    // Find the available UTXOs in the wallet and
    const txUnspentOutputs  = TransactionUnspentOutputs.new();
    const walletOutputs  = await cardano.getUtxos();
    walletOutputs.forEach(utxo => txUnspentOutputs.add(utxo))
    await retry(() => {
        try {
            txBuilder.add_inputs_from(txUnspentOutputs, 2)
        }
        catch (err) {
            console.log(err)
            txBuilder.add_inputs_from(txUnspentOutputs, 3)
        }
    }, { maxTry: 10 })

    txBuilder.add_change_if_needed(selfAddress)

    const tx = txBuilder.build_tx();

    const transactionWitnessSet = TransactionWitnessSet.from_bytes(tx.witness_set().to_bytes());

    let signedtxVkeyWitnesses: TransactionWitnessSet
    try {
        signedtxVkeyWitnesses = await cardano.signTx(tx, true);
    }
    catch (err) {
        throw handleSignError(err)
    }

    transactionWitnessSet.set_vkeys(signedtxVkeyWitnesses.vkeys()!);
    const signedTx = Transaction.new(
        tx.body(),
        transactionWitnessSet,
        aux_data
    );

    try {
        const txHash = await cardano.submitTx(signedTx);
        console.log(`txHash for sales offer: ${txHash}`);
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            sellerAddress: selfAddress.to_bech32(),
            sellerPkh: pkh,
            policyId: policyId,
            assetName: assetName,
            price: askingPrice,
            method: "offer"
        });
        console.log(err)
        throw err;
    }
}

export const cancel = async (api: BrowserWallet, policyId: string, assetName: string): Promise<string> => {
    const cardano = cardanoApiAdapter(api)
    const protocolParameters = await getProtocolProtocolParams()
    
    const exUnitPrices = ExUnitPrices.new(extractUnitPrices(protocolParameters.price_mem), extractUnitPrices(protocolParameters.price_step))
    const txBuilder = await createTransactionBuilder(protocolParameters, exUnitPrices)
    const unspentOutput = await getUnspentTransactionUtxoForNft(getContractAddress(), policyId, assetName);
    const selfAddress = Address.from_bech32(unspentOutput.metadata?.addr.join("") as string)
    const baseAddress = BaseAddress.from_address(selfAddress)!
    const pkh = toHex(baseAddress.payment_cred().to_keyhash()?.to_bytes() as Uint8Array);

    const scripts = getContractScript()
    const contractAssetOutput = await getContractOutput(nftMoveFixedPrice, policyId, assetName)

    let txOutputBuilder = TransactionOutputBuilder.new();
    txOutputBuilder = txOutputBuilder.with_address(selfAddress);
    let txOutputBuilderWithAmount = txOutputBuilder.next();
    txOutputBuilderWithAmount = txOutputBuilderWithAmount.with_value(contractAssetOutput)

    const aux_data: AuxiliaryData = await addOfferMetadata(txBuilder, selfAddress, unspentOutput.metadata?.price as string, assetName, policyId, "cancel")
    
    const txOutput = txOutputBuilderWithAmount.build();
    txBuilder.add_output(txOutput)

    let datum: PlutusData;
    if (unspentOutput.metadata?.era == undefined) {
        const legacyDatumInHex = await marketplaceLegacy.getLegacySalesOfferDatumInHex(policyId, assetName, unspentOutput.metadata?.price as string, unspentOutput.metadata?.addr.join("") as string);
        console.log(`get legacy datum hex ${legacyDatumInHex}`)
        datum = PlutusData.from_hex(legacyDatumInHex)
    } else {
        const salesOfferDatum = createSalesOfferDatum(pkh, unspentOutput.metadata?.price as string, policyId, assetName);
        datum = toPlutusData(salesOfferDatum);
    }
    
    const redeemer = createRedeemer(0, RedeemerType.Close)

    const plutusWitness = PlutusWitness.new(scripts.get(0), datum, redeemer)
    txBuilder.add_plutus_script_input(
        plutusWitness,
        unspentOutput.scriptUtxo.input(),
        contractAssetOutput
    ) 

    txBuilder.add_required_signer(baseAddress.payment_cred().to_keyhash() as Ed25519KeyHash)

    const txUnspentOutputs  = TransactionUnspentOutputs.new();
    const walletOutputs  = await cardano.getUtxos();
    walletOutputs.forEach(utxo => txUnspentOutputs.add(utxo))
    await retry(() => {
        try {
            txBuilder.add_inputs_from(txUnspentOutputs, 2)
        }
        catch (err) {
            console.log(err)
            txBuilder.add_inputs_from(txUnspentOutputs, 3)
        }
    }, { maxTry: 10 })
    
    const collateral = await getCollateralUnspentTransactionOutput(api)
    var txInputBuilder = TxInputsBuilder.new()
    collateral.forEach((utxo) => {
        txInputBuilder.add_input(utxo.output().address(), utxo.input(), utxo.output().amount())
    });
    txBuilder.set_collateral(txInputBuilder)
    
    const costModels = TxBuilderConstants.plutus_vasil_cost_models();
    txBuilder.calc_script_data_hash(costModels)
    
    txBuilder.add_change_if_needed(selfAddress)
    const tx = txBuilder.build_tx();

    const transactionWitnessSet = TransactionWitnessSet.from_bytes(tx.witness_set().to_bytes());
    let signedtxVkeyWitnesses: TransactionWitnessSet
    try {
        signedtxVkeyWitnesses = await cardano.signTx(tx, true);
    }
    catch (err) {
        throw handleSignError(err)
    }

    transactionWitnessSet.set_vkeys(signedtxVkeyWitnesses.vkeys()!);
    const signedTx = Transaction.new(
        tx.body(),
        transactionWitnessSet,
        aux_data
    );

    //logTx(signedTx)

    try
    {
        let txHash = await cardano.submitTx(signedTx);
        console.log(`cancel txhash: ${txHash}`)
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            sellerAddress: selfAddress.to_bech32(),
            sellerPkh: pkh,
            policyId: policyId,
            assetName: assetName,
            price: unspentOutput.metadata?.price as string,
            method: "cancel"
        });
        console.log(err)
        throw err;
    }
}

export const buy = async (api: BrowserWallet, policyId: string, assetName: string): Promise<string> => {
    const cardano = cardanoApiAdapter(api)
    const protocolParameters = await getProtocolProtocolParams()
    
    const exUnitPrices = ExUnitPrices.new(extractUnitPrices(protocolParameters.price_mem), extractUnitPrices(protocolParameters.price_step))
    const txBuilder = await createTransactionBuilder(protocolParameters, exUnitPrices)
    const unspentOutput = await getUnspentTransactionUtxoForNft(getContractAddress(), policyId, assetName);

    const selfAddress = await cardano.getWalletAddress()
    const baseAddress = BaseAddress.from_address(selfAddress)!;
    const pkh = toHex(baseAddress.payment_cred().to_keyhash()?.to_bytes() as Uint8Array);

    const sellerAddress = Address.from_bech32(unspentOutput.metadata?.addr.join("") as string)
    const sellerBaseAddress = BaseAddress.from_address(sellerAddress)!
    const sellerPkh = toHex(sellerBaseAddress.payment_cred().to_keyhash()?.to_bytes() as Uint8Array);

    const scripts = getContractScript()
    const contractAssetOutput = await getContractOutput(nftMoveFixedPrice, policyId, assetName)
    
    const aux_data: AuxiliaryData = await addOfferMetadata(txBuilder, selfAddress, unspentOutput.metadata?.price as string, assetName, policyId, "buy")

    const commissionAndFeeForSeller = getPaymentForSellerAndCommission(unspentOutput.metadata?.price as string)
    const outputs: TransactionOutput[] = [
        TransactionOutput.new(
            selfAddress,
            getContractOutput(nftMoveFixedPrice, policyId, assetName)
        ),
        TransactionOutput.new(
            sellerAddress,
            Value.new(toBigNum(commissionAndFeeForSeller.sellerGets))
        ),
        TransactionOutput.new(
            sellerAddress,
            Value.new(toBigNum(nftMoveFixedPrice))
        ),
        TransactionOutput.new(
            getCommissionAddress(),
            Value.new(toBigNum(commissionAndFeeForSeller.commission))
        ),
    ];

    outputs.forEach(output => txBuilder.add_output(output));

    let datum: PlutusData;
    if (unspentOutput.metadata?.era == undefined) {
        const legacyDatumInHex = await marketplaceLegacy.getLegacySalesOfferDatumInHex(policyId, assetName, unspentOutput.metadata?.price as string, unspentOutput.metadata?.addr.join("") as string);
        console.log(`buy get legacy datum hex ${legacyDatumInHex}`)
        datum = PlutusData.from_hex(legacyDatumInHex)
    } else {
        const salesOfferDatum = createSalesOfferDatum(sellerPkh, unspentOutput.metadata?.price as string, policyId, assetName);
        console.log(`new offer`)
        datum = toPlutusData(salesOfferDatum);
    }
    const redeemer = createRedeemer(0, RedeemerType.Buy)

    const plutusWitness = PlutusWitness.new(scripts.get(0), datum, redeemer)
    txBuilder.add_plutus_script_input(
        plutusWitness,
        unspentOutput.scriptUtxo.input(),
        contractAssetOutput
    ) 

    txBuilder.add_required_signer(baseAddress.payment_cred().to_keyhash() as Ed25519KeyHash)

    const txUnspentOutputs  = TransactionUnspentOutputs.new();
    const walletOutputs  = await cardano.getUtxos();
    walletOutputs.forEach(utxo => txUnspentOutputs.add(utxo))
    await retry(() => {
        try {
            txBuilder.add_inputs_from(txUnspentOutputs, 2)
        }
        catch (err) {
            console.log(err)
            txBuilder.add_inputs_from(txUnspentOutputs, 3)
        }
    }, { maxTry: 10 })
    
    const collateral = await getCollateralUnspentTransactionOutput(api)
    var txInputBuilder = TxInputsBuilder.new()
    collateral.forEach((utxo) => {
        txInputBuilder.add_input(utxo.output().address(), utxo.input(), utxo.output().amount())
    });
    txBuilder.set_collateral(txInputBuilder)
    
    const costModels = TxBuilderConstants.plutus_vasil_cost_models();
    txBuilder.calc_script_data_hash(costModels)
    
    txBuilder.add_change_if_needed(selfAddress)
    const tx = txBuilder.build_tx();

    const transactionWitnessSet = TransactionWitnessSet.from_bytes(tx.witness_set().to_bytes());
    let signedtxVkeyWitnesses: TransactionWitnessSet
    try {
        signedtxVkeyWitnesses = await cardano.signTx(tx, true);
    }
    catch (err) {
        throw handleSignError(err)
    }

    transactionWitnessSet.set_vkeys(signedtxVkeyWitnesses.vkeys()!);
    const signedTx = Transaction.new(
        tx.body(),
        transactionWitnessSet,
        aux_data
    );

    try
    {
        let txHash = await cardano.submitTx(signedTx);
        console.log(`buy txhash: ${txHash}`)
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            sellerAddress: selfAddress.to_bech32(),
            sellerPkh: pkh,
            policyId: policyId,
            assetName: assetName,
            price: unspentOutput.metadata?.price as string,
            method: "buy"
        });
        console.log(err)
        throw err;
    }
}

export const update = async (api: BrowserWallet, policyId: string, assetName: string, newPrice: string): Promise<string> => {
    const cardano = cardanoApiAdapter(api)
    const protocolParameters = await getProtocolProtocolParams()
    
    const exUnitPrices = ExUnitPrices.new(extractUnitPrices(protocolParameters.price_mem), extractUnitPrices(protocolParameters.price_step))
    const txBuilder = await createTransactionBuilder(protocolParameters, exUnitPrices)
    const unspentOutput = await getUnspentTransactionUtxoForNft(getContractAddress(), policyId, assetName);

    const selfAddress = await cardano.getWalletAddress()
    const baseAddress = BaseAddress.from_address(selfAddress)!;
    const pkh = toHex(baseAddress.payment_cred().to_keyhash()?.to_bytes() as Uint8Array);
    const scripts = getContractScript()
    const contractAssetOutput = await getContractOutput(nftMoveFixedPrice, policyId, assetName)
    
    const aux_data: AuxiliaryData = await addOfferMetadata(txBuilder, selfAddress, newPrice, assetName, policyId, "update")

    const contractTransactionOutput = TransactionOutput.new(
        getContractAddress(),
        contractAssetOutput
    )
    const newSalesDatumObject = createSalesOfferDatum(pkh, newPrice, policyId, assetName);
    const newSalesDatum = toPlutusData(newSalesDatumObject)
    const newSalesDatumHash = hash_plutus_data(newSalesDatum);
    contractTransactionOutput.set_data_hash(newSalesDatumHash);
    txBuilder.add_output(contractTransactionOutput)

    const existingSalesOfferDatum = createSalesOfferDatum(pkh, unspentOutput.metadata?.price as string, policyId, assetName);
    const existingDatum = toPlutusData(existingSalesOfferDatum);
    const redeemer = createRedeemer(0, RedeemerType.Close)

    const plutusWitness = PlutusWitness.new(scripts.get(0), existingDatum, redeemer)
    txBuilder.add_plutus_script_input(
        plutusWitness,
        unspentOutput.scriptUtxo.input(),
        contractAssetOutput
    ) 

    txBuilder.add_required_signer(baseAddress.payment_cred().to_keyhash() as Ed25519KeyHash)

    const txUnspentOutputs  = TransactionUnspentOutputs.new();
    const walletOutputs  = await cardano.getUtxos();
    walletOutputs.forEach(utxo => txUnspentOutputs.add(utxo))
    await retry(() => {
        try {
            txBuilder.add_inputs_from(txUnspentOutputs, 2)
        }
        catch (err) {
            console.log(err)
            txBuilder.add_inputs_from(txUnspentOutputs, 3)
        }
    }, { maxTry: 10 })
    
    const collateral = await getCollateralUnspentTransactionOutput(api)
    var txInputBuilder = TxInputsBuilder.new()
    collateral.forEach((utxo) => {
        txInputBuilder.add_input(utxo.output().address(), utxo.input(), utxo.output().amount())
    });
    txBuilder.set_collateral(txInputBuilder)
    
    const costModels = TxBuilderConstants.plutus_vasil_cost_models();
    txBuilder.calc_script_data_hash(costModels)
    
    txBuilder.add_change_if_needed(selfAddress)
    const tx = txBuilder.build_tx();

    const transactionWitnessSet = TransactionWitnessSet.from_bytes(tx.witness_set().to_bytes());
    let signedtxVkeyWitnesses: TransactionWitnessSet
    try {
        signedtxVkeyWitnesses = await cardano.signTx(tx, true);
    }
    catch (err) {
        throw handleSignError(err)
    }

    transactionWitnessSet.set_vkeys(signedtxVkeyWitnesses.vkeys()!);
    const signedTx = Transaction.new(
        tx.body(),
        transactionWitnessSet,
        aux_data
    );

    try
    {
        let txHash = await cardano.submitTx(signedTx);
        console.log(`update txhash: ${txHash}`)
        return txHash
    } catch (err) {
        appInsights.trackException({ exception: err as Error }, {
            sellerAddress: selfAddress.to_bech32(),
            sellerPkh: pkh,
            policyId: policyId,
            assetName: assetName,
            price: unspentOutput.metadata?.price as string,
            method: "update"
        });
        console.log(err)
        throw err;
    }
}

const getContractAddress = () => {
    return Address.from_bech32(ConfigProvider.getMarketPlaceContractAddress())
}

const getContractScript = () => {
    const scripts = PlutusScripts.new()
    scripts.add(PlutusScript.new(fromHex(ConfigProvider.getMarketPlaceContractCbor())))
    return scripts
}

const getCommissionAddress = () => {
    return Address.from_bech32(ConfigProvider.getMarketPlaceCommisionAddress())
}

const enum RedeemerType {
    Buy = 0,
    Close = 1
}

const createRedeemer = (index: number, redeemerType: RedeemerType) => {
    const redeemerData = PlutusData.new_constr_plutus_data(
        ConstrPlutusData.new(
            BigNum.from_str(redeemerType.toString()),
            PlutusList.new()
        )
    );

    const exUnits = redeemerType == RedeemerType.Close ?
        ExUnits.new(BigNum.from_str("2754991"), BigNum.from_str("752356532"))
        :                                                         
        ExUnits.new(BigNum.from_str("7000000"), BigNum.from_str("3000000000"))
    // const exUnits = ExUnits.new(BigNum.from_str("7000000"), BigNum.from_str("3000000000"))
    const r = Redeemer.new(
        RedeemerTag.new_spend(),
        toBigNum(index),
        redeemerData,
        exUnits
    );

    return r;
}

const createSalesOfferDatum = (sellerPublicKeyHash: string, price: string, policyId: string, assetName: string): PlutusDataObject => {
    return {
        constructorIndex: 0,
        fields: [
            {
                index: 0,
                type: PlutusFieldType.Bytes,
                key: "sellerPublicKeyHash",
                value: fromHex(sellerPublicKeyHash)
            },
            {
                index: 1,
                type: PlutusFieldType.Integer,
                key: "price",
                value: price
            },
            {
                index: 2,
                type: PlutusFieldType.Bytes,
                key: "policyId",
                value: fromHex(policyId)
            },
            {
                index: 3,
                type: PlutusFieldType.Bytes,
                key: "assetName",
                value: toByteArray(assetName)
            },
        ]
    }
}

const getPaymentForSellerAndCommission = (price: string) => {
    const priceInt = parseInt(price);

    const amountForSeller = priceInt - Math.max(priceInt * 0.02, 1000000);
    const amountForCommission = Math.max(priceInt * 0.02, 1000000);

    return { sellerGets: amountForSeller, commission: amountForCommission }
}

const addOfferMetadata = async (txBuilder: TransactionBuilder, address: Address, price: string, assetName: string, policyId: string, operation: string) => {
    const aux_data: AuxiliaryData = AuxiliaryData.new();
    const generalTransactionMetadata: GeneralTransactionMetadata = GeneralTransactionMetadata.new();
    const meta: OfferMetadata = {
        addr: splitAddress(address.to_bech32()),
        price: price,
        asset: assetName,
        policy: policyId,
        op: operation,
        era: "vasil"
    };

    const metadata = encode_json_str_to_metadatum(JSON.stringify(meta), MetadataJsonSchema.BasicConversions);
    generalTransactionMetadata.insert(BigNum.from_str("405"), metadata)
    aux_data.set_metadata(generalTransactionMetadata)
    txBuilder.set_auxiliary_data(aux_data)
    return aux_data
}

interface OfferMetadata{
    addr: string[],
    price: string,
    asset: string,
    policy: string,
    op: string,
    era: string
}